export const formSubmitMapper = (values) => ({
  tgl_convert_item_master: values?.tglConvert,
  qty_convert: values?.qtyConvert,
  id_gudang: values?.idGudang,
  id_item_convert: values?.idItemConvert,
  id_satuan: values?.idSatuan,
  harga_satuan: values?.hargaSatuan,
  detail: values?.detail.map((val) => ({
    qty_campuran: val.qtyCampuran,
    id_item_campuran: val.idItemCampuran,
    id_gudang: val.idGudangDetail,
    id_satuan: val.idSatuan,
  })),
});
