import {
  Table,
  Th,
  Td,
  DataStatus,
  TdFixed,
  InputQtyDecimal,
} from "components";
import { DecimalConvert } from "utilities";

const TableDO = ({ formik, dataTable, setDataTable, type }) => {
  const onQtySJChange = (value, index) => {
    const newData = dataTable.map((val, i) => {
      return i === index
        ? {
            ...val,
            qty_surat_jalan: parseFloat(value)
          }
        : { ...val };
    });

    setDataTable(newData);
  };

  const onQtyPakaiChange = (value, index) => {
    const newData = dataTable.map((val, i) => {
      return i === index ? { ...val, qty_pakai: value } : { ...val };
    });

    setDataTable(newData);
  };

  return (
    <>
      <div className="p-1">
        <b>List Item Delivery Order</b>
      </div>
      <Table>
        <thead className="bg-light">
          <Th>No</Th>
          <Th>Kode Barang</Th>
          <Th>Item Barang</Th>
          <Th>Gudang</Th>
          {type !== "detail" && <Th>Stock Gudang</Th>}
          <Th>Qty. DO</Th>
          {type !== "detail" && (
            <>
              <Th>Qty. Menunggu Persetujuan</Th>
              <Th>Qty. Terkirim</Th>
              <Th>Qty. Sisa DO</Th>
            </>
          )}
          <Th>Qty. Surat Jalan</Th>
          <Th>Satuan Jual</Th>
          <Th>Qty. Satuan Pakai</Th>
          <Th>Satuan Pakai</Th>
        </thead>
        <tbody>
          {dataTable.length > 0 ? (
            dataTable.map((val, index) => (
              <tr key={index}>
                <TdFixed>{index + 1}</TdFixed>
                <Td>{val.kode_item}</Td>
                <Td>{val.nama_item}</Td>
                <Td>{val.nama_gudang ?? "-"}</Td>
                {type !== "detail" && (
                  <Td className="text-right">
                    {
                      DecimalConvert(parseFloat(val.qty_stok_gudang ?? 0))
                        .getWithComa
                    }
                  </Td>
                )}
                <Td className="text-right">
                  {DecimalConvert(val.qty_do).getWithComa}
                </Td>
                {type !== "detail" && (
                  <>
                    <Td className="text-right">
                      {DecimalConvert(val.qty_menunggu_persetujuan).getWithComa}
                    </Td>
                    <Td className="text-right">
                      {DecimalConvert(val.qty_terkirim).getWithComa}
                    </Td>
                    <Td className="text-right">
                      {DecimalConvert(val.qty_sisa_do).getWithComa}
                    </Td>
                  </>
                )}
                <Td style={{ width: 125 }} className="text-right">
                  {type === "detail" ? (
                    DecimalConvert(val.qty_surat_jalan).getWithComa
                  ) : (
                    // <input
                    //   className="form-control form-control-sm"
                    //   placeholder="Masukan qty."
                    //   defaultValue={val.qty_surat_jalan}
                    //   onChange={(e) => {
                    //     e.target.value = decimalConvert(e.target.value);
                    //   }}
                    //   // disabled={type === "detail" ? true : false}
                    //   onBlur={(e) => {
                    //     const value = decimalConvert(e.target.value);
                    //     onQtySJChange(value, index);
                    //   }}
                    // />
                    <InputQtyDecimal
                      placeholder="Masukan qty."
                      value={val.qty_surat_jalan}
                      disabled={
                        parseFloat(val.qty_stok_gudang) > 0 && parseFloat(val.qty_sisa_do) > 0
                          ? false
                          : true
                      }
                      onChange={(e) => {
                        onQtySJChange(e, index);
                      }}
                    />
                  )}
                </Td>
                <Td>{val.satuan_surat_jalan}</Td>
                <Td className="text-right" style={{ width: 125 }}>
                  {type === "detail" ? (
                    DecimalConvert(val.qty_pakai).getWithComa
                  ) : (
                    // <input
                    //   className="form-control form-control-sm"
                    //   placeholder="Masukan qty."
                    //   defaultValue={val.qty_pakai}
                    //   disabled={type === "detail" ? true : false}
                    //   onChange={(e) => {
                    //     e.target.value = decimalConvert(e.target.value);
                    //   }}
                    //   onBlur={(e) => {
                    //     const value = decimalConvert(e.target.value);
                    //     onQtyPakaiChange(value, index);
                    //   }}
                    // />
                    <InputQtyDecimal
                      placeholder="Masukan qty."
                      value={val.qty_pakai}
                      disabled={
                        parseFloat(val.qty_stok_gudang) > 0 && parseFloat(val.qty_sisa_do) > 0
                          ? false
                          : true
                      }
                      onChange={(e) => {
                        onQtyPakaiChange(e, index);
                      }}
                    />
                  )}
                </Td>
                <Td>{val.satuan}</Td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={8}>
                <DataStatus text="Tidak ada data" />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
};

export default TableDO;
