import React from "react";

const SelectMonth = ({
  label,
  name,
  defaultValue,
  disabled,
  error,
  errorText,
  onChange,
}) => {
  return (
    <div className="mb-3">
      <label>{label}</label>
      <select
        name={name}
        defaultValue={defaultValue}
        className={`custom-select custom-select-sm ${error && "is-invalid"}`}
        onChange={onChange}
        disabled={disabled}>
        <option value="">Pilih bulan</option>
        <option value="01">Januari</option>
        <option value="02">Februari</option>
        <option value="03">Maret</option>
        <option value="04">April</option>
        <option value="05">Mei</option>
        <option value="06">Juni</option>
        <option value="07">Juli</option>
        <option value="08">Agustus</option>
        <option value="09">September</option>
        <option value="10">Oktober</option>
        <option value="11">November</option>
        <option value="12">Desember</option>
      </select>
      <div className="invalid-feedback">{errorText}</div>
    </div>
  );
};

export default SelectMonth;
