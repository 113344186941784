import Services from "services";

class MonitoringOrderApi {
  getPage(params) {
    return Services.get("/monitoring_order/page", { params });
  }
  getAtribut(params) {
    return Services.get(`/penerimaan_barang/dropdown`, { params });
  }
  getDropdownProyek() {
    return Services.get("/dropdown/proyek");
  }
  getDropdownCustomer() {
    return Services.get("/dropdown/customer");
  }
  getDropdownBarang() {
    return Services.get("/dropdown/barang_jadi");
  }
  getDropdownVendor() {
    return Services.get("/dropdown/vendor");
  }
  getDropdownItemBarang() {
    return Services.get("/dropdown/material");
  }
  getDropdownKaryawan() {
    return Services.get("/dropdown/karyawan");
  }
  export(params) {
    return Services.get("/monitoring_order/export", { params });
  }
}

export default new MonitoringOrderApi();
