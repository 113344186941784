/* eslint-disable react-hooks/exhaustive-deps */
import { SuratJalanApi } from "api"
import Axios from "axios"
import {
  ActionButton,
  Alert,
  AlertAlreadyStockOpname,
  BackButton,
  DataStatus,
} from "components"
import { Formik } from "formik"
import { useEffect, useState } from "react"
import { Card, Modal } from "react-bootstrap"
import { IoAlertCircleOutline } from "react-icons/io5"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { DateConvert } from "utilities"
import * as Yup from "yup"
import { TableDO } from "../Section"
import { FormSection, InfoSection } from "./Section"

const TambahSuratJalan = ({ setNavbarTitle }) => {
  const today = DateConvert(new Date()).default;
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();
  const [dataInfo, setDataInfo] = useState({});
  const [dataTable, setDataTable] = useState([]);
  const [dataArmada, setDataArmada] = useState([]);
  const [dataPetugas, setDataPetugas] = useState([]);
  const [formRendered, setFormRendered] = useState(0);
  const [showModalQty, setShowModalQty] = useState(false);
  const [fetchingStatus, setFetchingStatus] = useState({
    loading: false,
    success: true,
  });
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  const mappingDataInfo = (data) => {
    return {
      id_sales_order: data.id_sales_order ?? "-",
      tgl_sales_order: data.tgl_sales_order ?? null,
      no_sales_order: data.no_sales_order ?? "-",
      no_delivery_order: data.no_delivery_order ?? "-",
      customer: data.nama_customer ?? "-",
      sales: data.nama_sales ?? "-",
      gudang: data.nama_gudang ?? "-",
      tgl_batas_waktu: data.batas_waktu ?? null,
      nama_proyek: data.nama_proyek ?? "-",
    };
  };

  const mappingDataTable = (data) =>
    data.map((val) => {
      const qtyDo = val.qty_do ? parseFloat(val.qty_do) : 0;
      const qtyTerkirim = val.qty_terkirim ? parseFloat(val.qty_terkirim) : 0;
      const qtySisaDo = parseFloat(val.qty_sisa_do ?? 0);

      return {
        id_delivery_order_detail: val.id_delivery_order_detail ?? null,
        id_delivery_order: val.id_delivery_order ?? null,
        kode_item: val.kode_barang ?? "-",
        nama_item: val.nama_barang ?? "-",
        satuan: val.nama_satuan ?? "",
        satuan_surat_jalan: val.satuan_jual ?? "",
        qty_do: qtyDo,
        qty_terkirim: qtyTerkirim,
        qty_sisa_do: qtySisaDo,
        qty_surat_jalan: 0,
        qty_pakai: 0,
        qty_menunggu_persetujuan: parseFloat(val.qty_menunggu_persetujuan ?? 0),
        qty_stok_gudang: parseFloat(val.qty_stok_gudang ?? 0),
        nama_gudang: val.nama_gudang,
      };
    });

  const mappingDataPetugas = (data) =>
    data.map((val) => {
      return {
        label: val.nama_karyawan,
        value: val.id_karyawan,
      };
    });

  const mappingDataArmada = (data) =>
    data.map((val) => {
      return {
        label: `${val.plat_nomor} - ${val.nama_item_aset}`,
        value: val.id_item_aset,
      };
    });

  const getInitialData = (date) => {
    setFetchingStatus({
      loading: true,
      success: false,
    });

    Axios.all([
      SuratJalanApi.getSingleDO({ id_delivery_order: id, tanggal: date }),
      SuratJalanApi.getDropdownPetugas(),
      SuratJalanApi.getDropdownArmada(),
    ])
      .then(
        Axios.spread((dataDO, petugas, armada) => {
          const getData = dataDO.data.data;
          const getPetugas = petugas.data.data;
          const getArmada = armada.data.data;
          const mapDataInfo = mappingDataInfo(getData ?? {});
          const mapDataTable = mappingDataTable(getData.detail ?? []);
          const mapDataPetugas = mappingDataPetugas(getPetugas ?? []);
          const mapDataArmada = mappingDataArmada(getArmada ?? []);

          setDataInfo(mapDataInfo);
          // setDataTable(mapDataTable);
          setDataPetugas(mapDataPetugas);
          setDataArmada([
            {
              value: null,
              label: "Tidak Memerlukan Armada Pengiriman",
            },
            ...mapDataArmada,
          ]);

          setFetchingStatus({
            loading: false,
            success: true,
          });
        })
      )
      .catch(() => {
        setAlertConfig({
          show: false,
          variant: "primary",
          text: "Data gagal dimuat!",
        });
        setFetchingStatus({
          loading: false,
          success: false,
        });
      });
  };

  const getDetail = (date) => {
    setLoadingDetail(true);
    Axios.all([
      SuratJalanApi.getDetailDO({ id_delivery_order: id, tanggal: date }),
    ])
      .then(
        Axios.spread((res) => {
          const getData = res?.data?.data?.detail ?? [];
          const mapDataTable = mappingDataTable(getData);

          setDataTable(mapDataTable);
        })
      )
      .catch(() => {
        setAlertConfig({
          show: false,
          variant: "primary",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setLoadingDetail(false));
  };

  const formInitialValues = {
    tanggal: undefined,
    nomor: "",
    petugas_pengiriman: "",
    armada_pengiriman: "",
    petugas_pengiriman_lain: "",
    armada_pengiriman_lain: "",
    nama_kendaraan: "",
  };

  const formValidationSchema = Yup.object().shape({
    tanggal: Yup.string().required("Pilih tanggal"),
    nomor: Yup.string().required("Pilih tanggal untuk menentukan nomor"),
  });

  const formSubmitHandler = (values, { setSubmitting }) => {
    const dataTableFilter = dataTable.filter(
      (val) => parseFloat(val?.qty_surat_jalan) > 0
    );

    const checkQty = Boolean(
      dataTableFilter.find((val) => {
          if (parseFloat(val.qty_surat_jalan) > parseFloat(val.qty_sisa_do)) {
            return false
          }

          return parseFloat(val.qty_surat_jalan) <= parseFloat(val.qty_stok_gudang) 
        } 
      )
    );

    const tableValues = dataTableFilter.map((val) => {
      return {
        id_delivery_order_detail: val.id_delivery_order_detail,
        id_delivery_order: val.id_delivery_order,
        qty: val.qty_pakai ?? 0,
        qty_surat_jalan: val.qty_surat_jalan ?? 0,
      };
    });
    const finalValues = {
      id_delivery_order: id,
      id_supir: values.petugas_pengiriman,
      id_petugas_gudang: values.petugas_pengiriman,
      id_kendaraan: values.armada_pengiriman,
      petugas_pengirim: values.petugas_pengiriman_lain,
      armada_pengirim: values.armada_pengiriman_lain,
      tgl_surat_jalan: values.tanggal,
      no_surat_jalan: values.nomor,
      detail: tableValues,
    };

    if (checkQty) {
    SuratJalanApi.save(finalValues)
      .then(() => {
        history.push("/inventory/transaksi/surat-jalan/delivery-order", {
          alert: {
            show: true,
            variant: "primary",
            text: "Data berhasil disimpan!",
          },
          do: location?.state?.do,
        });
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal disimpan!",
        });
      })
      .finally(() => setSubmitting(false));
    } else {
      setSubmitting(false);
      setShowModalQty(true);
    }
  };

  useEffect(() => {
    getInitialData();
    getDetail();
    setNavbarTitle("Surat Jalan");
  }, []);

  const ModalQty = () => (
    <Modal show={showModalQty} onHide={() => setShowModalQty(false)}>
      <Modal.Header closeButton>
        <b className="text-danger">Peringatan</b>
      </Modal.Header>
      <Modal.Body className="text-center">
        <p className="mb-3">
          <IoAlertCircleOutline size={75} className="mb-2 text-danger" />
          <br />
          <b>Qty. Surat Jalan Tidak Valid!</b>
          <br />
          <small>
            <b>QTY. SURAT JALAN</b> tidak boleh lebih dari <b>QTY. SISA DO</b>{" "}
            dan tidak boleh bernilai 0!
          </small>
        </p>
      </Modal.Body>
    </Modal>
  );

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={formValidationSchema}
      onSubmit={formSubmitHandler}
    >
      {(formik) => (
        <Card style={{ minHeight: "80vh" }}>
          <Card.Header className="d-flex justify-content-between align-items-center">
            <b>Tambah Data Surat Jalan</b>
            <BackButton
              size="sm"
              onClick={() =>
                history.push(
                  "/inventory/transaksi/surat-jalan/delivery-order",
                  {
                    ...location?.state,
                  }
                )
              }
            />
          </Card.Header>
          <Card.Body className="pb-5">
            <Alert
              show={alertConfig.show}
              variant={alertConfig.variant}
              text={alertConfig.text}
              showCloseButton={true}
              onClose={() =>
                setAlertConfig({
                  ...alertConfig,
                  show: false,
                })
              }
            />
            {fetchingStatus.loading ? (
              <DataStatus loading text="Memuat . . ." />
            ) : fetchingStatus.success ? (
              <form onSubmit={formik.handleSubmit}>
                <InfoSection dataInfo={dataInfo} />
                <hr className="mb-1" />
                <FormSection
                  formik={formik}
                  formRendered={formRendered}
                  fetchingStatus={fetchingStatus}
                  setFormRendered={setFormRendered}
                  dataArmada={dataArmada}
                  dataPetugas={dataPetugas}
                  getDetail={getDetail}
                />
                <hr className="mt-1" />

                {loadingDetail ? (
                  <DataStatus text="Memuat data..." loading />
                ) : (
                  <TableDO
                    formik={formik}
                    dataTable={dataTable}
                    setDataTable={setDataTable}
                  />
                )}
                <hr />
                <AlertAlreadyStockOpname tanggal={formik?.values?.tanggal} />
                <div className="d-flex justify-content-end">
                  <ActionButton
                    type="submit"
                    text="Simpan Surat Jalan"
                    loading={formik.isSubmitting}
                  />
                </div>
              </form>
            ) : (
              <DataStatus text="Data gagal dimuat" />
            )}
          </Card.Body>
          <ModalQty />
        </Card>
      )}
    </Formik>
  );
};

export default TambahSuratJalan;
