import { DataStatus } from "components";
import React, { useState } from "react";
import { Card, Nav, Tab } from "react-bootstrap";
import { useQuery } from "react-query";
import { numberConvert, rupiahConvert } from "utilities2";
import DashboardApi from "../__DashboardApi__";
import { ChartBarHorizontal, ChartBarVertical } from "components2";

const rupiah = rupiahConvert();
const number = numberConvert();

export const TabBarangDiminta = ({
  monthOptions,
  startMonth,
  endMonth,
  year,
}) => {
  const [selectedMonth, setSelectedMonth] = useState({
    label: "",
    value: startMonth,
  });

  const monthsInRange = monthOptions.filter(
    (val) => val.value >= startMonth && val.value <= endMonth
  );

  const getBarangSeringDiminta = useQuery(
    ["dashboard", "barang-diminta", startMonth, endMonth, year],
    () =>
      DashboardApi.getBarangSeringDiminta({
        tahun: year,
        bulan_mulai: startMonth,
        bulan_selesai: endMonth,
      })
  );

  const sortBarangSeringDiminta =
    getBarangSeringDiminta?.data?.data?.[selectedMonth?.value]?.sort((a, b) => {
      const parseA = parseInt(a.qty);
      const parseB = parseInt(b.qty);

      if (parseA < parseB) {
        return 1;
      }

      if (parseA > parseB) {
        return -1;
      }

      return 0;
    }) ?? [];

  return (
    <Card className="border">
      {monthsInRange?.length ? (
        <Tab.Container
          key={monthsInRange.join("-")}
          defaultActiveKey={monthsInRange[0].value}
        >
          <Card.Header className="bg-white">
            <Nav variant="tabs" className="bg-white">
              {monthsInRange?.map((month) => (
                <Nav.Item className="bg-white">
                  <Nav.Link
                    className="py-1 px-2"
                    eventKey={month.value}
                    onClick={() =>
                      setSelectedMonth({
                        label: month.label,
                        value: month.value,
                      })
                    }
                  >
                    {month.label}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Card.Header>

          <Card.Body>
            <Tab.Content>
              <div className="text-center">
                <b>10 Barang Yang Paling Sering Diminta</b>
                <br />
                <b>
                  Bulan {selectedMonth.label} {year}
                </b>
              </div>

              {getBarangSeringDiminta.isFetching ? (
                <div className="d-flex align-items-center justify-content-center my-5">
                  Memuat data . . .
                </div>
              ) : (
                <>
                  <ChartBarHorizontal
                    showLegend={false}
                    height={200}
                    data={{
                      labels:
                        sortBarangSeringDiminta?.map((val) => val?.nama_item) ??
                        [],
                      datasets: [
                        {
                          data:
                            sortBarangSeringDiminta?.map((val) => val?.qty) ??
                            [],
                        },
                      ],
                    }}
                    options={{
                      plugins: {
                        tooltip: {
                          callbacks: {
                            label: (val) =>
                              number.getWithComa(val.raw, {
                                minimumFractionDigits: 0,
                              }),
                          },
                        },
                      },
                      scales: {
                        x: {
                          ticks: {
                            callback: (val) =>
                              number.getWithComa(val, {
                                minimumFractionDigits: 0,
                              }),
                          },
                        },
                      },
                    }}
                  />
                </>
              )}
            </Tab.Content>
          </Card.Body>
        </Tab.Container>
      ) : (
        <DataStatus text="Tidak ada data" />
      )}
    </Card>
  );
};
