// React
import React, { useState } from "react"

// Components
import { Row, Col } from "react-bootstrap"
import { SelectSearch, Input, TextEditor } from "components"

// Draft JS
import { 
  EditorState, ContentState, convertFromHTML, convertToRaw, 
} from "draft-js"
import draftToHtml from "draftjs-to-html"

// Form
import { useFormikContext, Field } from "formik"
import { RegItemBahanApi } from "api"

const TabInfo = ({ dropdown, readOnly, getJenis, loadingJenis, data }) => {
  // Hooks
  const { 
    values, errors, touched, setFieldValue, setValues, 
    handleChange
  } = useFormikContext()

  // States
  const [textEditorState, setTextEditorState] = useState(
    EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML(values.uraian ?? "<p></p>"))
    )
  )
  const [loadingKode, setloadingKode] = useState(false)

  const onUraianChangeHandler = (value) => {
    const convertData = convertToRaw(value.getCurrentContent())

    setTextEditorState(value)
    setFieldValue("uraian", draftToHtml(convertData))
  }

  const getKode = (kode_jenis, kode_kelompok, setFieldValue) => {
    setloadingKode(true)
    if (kode_jenis === data?.kode_jenis && kode_kelompok === data?.kode_kelompok) {
      setFieldValue('kode_item', data.kode_item)
      setloadingKode(false)
    } else {
      RegItemBahanApi.no_baru({ kode_kelompok, kode_jenis }).then(res => {
        setFieldValue('kode_item', res.data.data)
      }).catch(() => {
        setFieldValue('kode_item', "")
      }).finally(() => setloadingKode(false))
    }
  }

  return (
    <Row>
      <Col lg>
        <Row>
          <Col lg={6}>
            <SelectSearch
              label="Kelompok Barang"
              placeholder="Pilih Kelompok Barang"
              onChange={(val) => {
                setValues({
                  ...values,
                  id_kelompok: val.value,
                  kode_kelompok: val.kode_kelompok,
                  id_jenis: "",
                  kode_jenis: "",
                  kode_item: "",
                  nama_varian: val.value === 7 ? values.nama_varian : ""
                })

                getJenis(val.value)
              }}
              option={dropdown.kelompok}
              defaultValue={values.id_kelompok ? dropdown.kelompok.find((item) => item.value === values.id_kelompok) : ""}
              error={Boolean(errors.id_kelompok && touched.id_kelompok)}
              errorText={Boolean(errors.id_kelompok && touched.id_kelompok) && errors.id_kelompok}
              isDisabled={readOnly ? true : false}
            />
          </Col>

          <Col lg={6}>
            <SelectSearch
              label="Jenis Barang"
              placeholder="Pilih Jenis Barang"
              onChange={(val) => {
                setValues({
                  ...values,
                  id_jenis: val.value,
                  kode_jenis: val.kode_jenis,
                  nama_varian: val.value === 7 ? values.nama_varian : ""
                })

                getKode(val.kode_jenis, values.kode_kelompok, setFieldValue)
              }}
              option={dropdown.jenis}
              defaultValue={values.id_jenis ? dropdown.jenis.find((item) => item.value === values.id_jenis) : ""}
              error={Boolean(errors.id_jenis && touched.id_jenis)}
              errorText={Boolean(errors.id_jenis && touched.id_jenis) && errors.id_jenis}
              isDisabled={values.id_kelompok === "" || readOnly ? true : false}
              loading={loadingJenis}
            />
          </Col>

          <Col lg={6}>
            <Input
              label="Kode Barang"
              placeholder="Kode Barang"
              type="text"
              value={loadingKode ? "Memuat data..." : values.kode_item}
              readOnly={true}
            />
          </Col>
        </Row>

        <Input
          label="Nama Barang"
          placeholder="Nama Barang"
          type="text"
          name="nama_item"
          value={values.nama_item}
          onChange={handleChange}
          error={Boolean(errors.nama_item && touched.nama_item)}
          errorText={Boolean(errors.nama_item && touched.nama_item) && errors.nama_item}
          disabled={readOnly}
        />

        <Input
          label="Nama Varian (Khusus Untuk Barang Jadi)"
          placeholder="Nama Varian"
          type="text"
          name="nama_varian"
          value={values.nama_varian}
          onChange={handleChange}
          error={
            Boolean(errors.nama_varian && touched.nama_varian) ||
            (Boolean(errors.nama_item && touched.nama_item) &&
              errors.nama_item === "Nama Barang Jadi & Nama Varian telah digunakan")
          }
          errorText={
            Boolean(errors.nama_varian && touched.nama_varian)
              ? errors.nama_varian
              : Boolean(errors.nama_item && touched.nama_item)
              ? "Nama Barang Jadi & Nama Varian telah digunakan"
              : ""
          }
          readOnly={readOnly || !Boolean(values.id_kelompok === "7")}
        />

        <SelectSearch
          label="Pabrikan"
          placeholder="Pilih Pabrikan"
          onChange={(val) => setFieldValue("id_pabrikan", val.value)}
          option={dropdown.pabrikan}
          defaultValue={values.id_pabrikan ? dropdown.pabrikan.find((item) => item.value === values.id_pabrikan) : ""}
          error={Boolean(errors.id_pabrikan && touched.id_pabrikan)}
          errorText={Boolean(errors.id_pabrikan && touched.id_pabrikan) && errors.id_pabrikan}
          isDisabled={readOnly}
        />

        <Row>
          <Col md="4">
            <SelectSearch
              label="Satuan Pakai"
              placeholder="Pilih Satuan Pakai"
              onChange={(val) => setValues({
                ...values,
                id_satuan: val.value,
                id_satuan_pakai: val.value,
                kode_satuan_pakai: val.kode_satuan
              })}
              option={dropdown.satuan}
              defaultValue={values.id_satuan ? dropdown.satuan.find((item) => item.value === values.id_satuan) : ""}
              error={Boolean(errors.id_satuan && touched.id_satuan)}
              errorText={Boolean(errors.id_satuan && touched.id_satuan) && errors.id_satuan}
              isDisabled={readOnly}
            />
          </Col>

          <Col md="4">
            <SelectSearch
              label="Satuan Beli"
              placeholder="Pilih Satuan Beli"
              onChange={(val) => setValues({
                ...values,
                id_satuan_beli: val.value,
                kode_satuan_beli: val.kode_satuan
              })}
              option={dropdown.satuan ?? []}
              defaultValue={values.id_satuan_beli ? dropdown.satuan.find((item) => item.value === values.id_satuan_beli) : ""}
              error={Boolean(errors.id_satuan_beli && touched.id_satuan_beli)}
              errorText={Boolean(errors.id_satuan_beli && touched.id_satuan_beli) && errors.id_satuan_beli}
              isDisabled={readOnly}
            />
          </Col>

          <Col md="4">
            <SelectSearch
              label="Satuan Jual"
              placeholder="Pilih Satuan Jual"
              onChange={(val) => setFieldValue("id_satuan_jual", val.value)}
              option={dropdown.satuan}
              defaultValue={values.id_satuan_jual ? dropdown.satuan.find((item) => item.value === values.id_satuan_jual) : ""}
              error={Boolean(errors.id_satuan_jual && touched.id_satuan_jual)}
              errorText={Boolean(errors.id_satuan_jual && touched.id_satuan_jual) && errors.id_satuan_jual}
              isDisabled={readOnly}
            />
          </Col>
        </Row>

        <div>
          <Field type="checkbox" name="bisa_diproduksi" /> Barang Bisa Diproduksi
        </div>

        <div>
          <Field type="checkbox" name="bisa_dijual" /> Barang Bisa Dijual
        </div>
      </Col>

      <Col lg>
        <TextEditor
          label="Uraian"
          placeholder="Uraian"
          editorState={textEditorState}
          onEditorStateChange={(val) => onUraianChangeHandler(val)}
          options={["inline", "list", "textAlign"]}
          editorStyle={{
            height: 320,
          }}
          readOnly={readOnly}
        />
      </Col>
    </Row>
  )
}

export default TabInfo
