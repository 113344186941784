import * as Yup from "yup";

export const formValidationSchema = () =>
  Yup.object().shape({
    tglConvert: Yup.string().required("Pilih tanggal").nullable(),
    idItemConvert: Yup.string().required("Pilih item convert").nullable(),
    idGudang: Yup.string().required("Pilih gudang").nullable(),
    qtyConvert: Yup.string().required("Masukan qty").nullable(),
    hargaSatuan: Yup.string().required("Masukan harga satuan").nullable(),
  });
