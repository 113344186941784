import Services from '../../../services'

class KartuStockMaterialApi {
    getDropdownKelompok() {
        return Services.get('dropdown/kelompok_material')
    }

    getDropdownGudang() {
        return Services.get('dropdown/gudang')
    }

    getDropdownJenis(value) {
        return Services.get(`/dropdown/jenis_material?id_kelompok=${value}`)
    }

    getDropdownMaterial(value) {
        return Services.get(`/dropdown/material?id_jenis=${value}`)
    }

    getKartuStockMaterial(params) {
        return Services.get('/kartu_stok', { params })
    }

    export(params) {
        return Services.get('/kartu_stok/export', { params })
    }
}

export default new KartuStockMaterialApi()