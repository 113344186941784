import { createContext } from 'react';
import { TransferBahanWorkOrderApi } from 'api';
import { useQuery } from 'react-query';

export const DropdownContext = createContext();

export const DropdownProvider = ({ children }) => {
  const STALE_DURATION_MINUTES = 4 * 60 * 1000; // 4 MINUTES

  const dropdownBahan = useQuery(
    ['dropdown', 'bahan'],
    () =>
      TransferBahanWorkOrderApi.getDropdownBahan().then((res) => {
        const data = res.data.data.map((val) => ({
          ...val,
          value: val.id_item_buaso,
          label: `${val.kode_item} | ${val.nama_item}`,
        }));
        return data;
      }),
    { staleTime: STALE_DURATION_MINUTES },
  );

  const dropdownAset = useQuery(
    ['dropdown', 'aset'],
    () =>
      TransferBahanWorkOrderApi.getDropdownAset().then((res) => {
        const data = res.data.data.map((val) => ({
          ...val,
          value: val.id_item_aset,
          label: val.nama_item_aset,
        }));
        return data;
      }),
    { staleTime: STALE_DURATION_MINUTES },
  );

  const dropdownGudang = useQuery(
    ['dropdown', 'gudang'],
    () =>
      TransferBahanWorkOrderApi.getDropdownGudang().then((res) => {
        const data = res.data.data.map((val) => ({
          ...val,
          value: val.id_gudang,
          label: val.nama_gudang,
        }));

        return data;
      }),
    { staleTime: STALE_DURATION_MINUTES },
  );

  const dropdownKaryawan = useQuery(
    ['dropdown', 'karyawan'],
    () =>
      TransferBahanWorkOrderApi.getDropdownKaryawan().then((res) => {
        const data = res.data.data.map((val) => ({
          ...val,
          value: val.id_karyawan,
          label: val.nama_karyawan,
        }));

        return data;
      }),
    { staleTime: STALE_DURATION_MINUTES },
  );

  return (
    <DropdownContext.Provider
      value={{
        dropdownBahan,
        dropdownAset,
        dropdownGudang,
        dropdownKaryawan,
      }}
    >
      {children}
    </DropdownContext.Provider>
  );
};

export const withDropdownProvider = (Component) => (props) => {
  return (
    <DropdownProvider>
      <Component {...props} />
    </DropdownProvider>
  );
};
