// import React, { useState, useEffect } from "react";
// import { Formik } from "formik";
// import * as Yup from "yup";
// import Axios from "axios";

// import {
//   CRUDLayout,
//   InputSearch,
//   ExportButton,
//   CreateButton,
//   UpdateButton,
//   DeleteButton,
//   DeleteModal,
//   DataStatus,
//   Alert,
//   Pagination,
//   THead,
//   TBody,
//   ThFixed,
//   TdFixed,
//   Tr,
//   Th,
//   Td,
//   UpdateModal,
//   CreateModal,
//   Input,
//   SelectSearch,
//   ActionButton,
// } from "../../../../components";

// import { MappingKaryawanApi } from "../../../../api";
// import { TableNumber } from "../../../../utilities";
// import { Alert as BSAlert, Spinner } from "react-bootstrap";

// const MappingKaryawan = ({ setNavbarTitle }) => {
//   // indikator pemanggilan data sedang dimuat di server
//   const [isLoading, setIsLoading] = useState(false);

//   // indikator apakah sedang melakukan searching
//   const [isSearching, setIsSearching] = useState(false);

//   // menampung value dari search form
//   const [searchKey, setSearchKey] = useState("");
//   const [filterKeyy, setFilterKeyy] = useState([
//     {
//       value: "",
//       label: "",
//     },
//   ]);
//   let filterKey = [
//     {
//       value: "",
//       label: "",
//     },
//   ];

//   // data jenis anggaran
//   const [data, setData] = useState([]);
//   const [page, setPage] = useState(1);
//   const [totalPage, setTotalPage] = useState(1);
//   const [dataLength, setDataLength] = useState(10);
//   const [filterData, setFilterData] = useState([]);
//   const [dataKaryawan, setDataKaryawan] = useState([]);
//   const [dataUnitProduksi, setDataUnitProduksi] = useState([]);
//   const [isCreateForm, setIsCreateForm] = useState(false);
//   const [updateData, setUpdateData] = useState({});
//   const [isUpdateData, setIsUpdateData] = useState(false);
//   const [isUpdateForm, setIsUpdateForm] = useState(false);
//   const [deleteData, setDeleteData] = useState({});
//   const [isDeleteData, setIsDeleteData] = useState(false);

//   // menampilkan alert
//   const [showAlert, setShowAlert] = useState(false);
//   // configurasi alert
//   const [alertConfig, setAlertConfig] = useState({
//     variant: "primary",
//     text: "",
//     searchKey: "",
//   });

//   // request data dari server
//   const getData = () => {
//     setIsLoading(true);
//     setIsSearching(false);
//

//     // request data ke server
//     Axios.all([
//       MappingKaryawanApi.getPage(page, dataLength),
//       MappingKaryawanApi.getKaryawan(),
//       MappingKaryawanApi.getUnitProduksi(),
//     ])
//       .then(
//         Axios.spread((res, karyawan, unit_produksi) => {
//           setData(res.data.data);
//           setTotalPage(res.data.total_page);
//           setDataKaryawan(karyawan.data.data);
//           setDataUnitProduksi(unit_produksi.data.data);
//           setFilterData([
//             {
//               kode_unit_produksi: "",
//               nama_unit_produksi: "Semua Data",
//             },
//             ...unit_produksi.data.data,
//           ]);
//         })
//       )
//       .catch(() => {
//         setAlertConfig({
//           variant: "danger",
//           text: "Gagal untuk memuat data",
//         });
//         setShowAlert(true);
//       })
//       .finally(() => setIsLoading(false));
//   };

//   // request search data dari server
//   const searchData = (e) => {
//     // mencegah terjadinya page reload
//     e.preventDefault();

//     // set loading
//     setIsLoading(true);

//     MappingKaryawanApi.search(searchKey, filterKeyy[0]["value"])
//       .then((res) => {
//         setData(res.data.data);
//         setTotalPage(res.data.total_page);
//         setAlertConfig({
//           variant: "primary",
//           searchKey: searchKey,
//           filterKey: filterKeyy[0]["label"],
//         });
//       })
//       .catch(() => {
//         setAlertConfig({
//           variant: "danger",
//           text: "Gagal untuk memuat data",
//         });
//       })
//       .finally(() => {
//         setPage(1);
//         setIsSearching(true);
//         setIsLoading(false);
//         setShowAlert(true);
//       });
//   };

//   const handleFilter = (value) => {
//     filterKey = [
//       {
//         value: value.value,
//         label: value.label,
//       },
//     ];

//     setFilterKeyy([
//       {
//         value: value.value,
//         label: value.label,
//       },
//     ]);

//     searchFilterData();
//   };

//   // request search data dari server
//   const searchFilterData = () => {
//     // set loading
//     setIsLoading(true);

//     if (filterKey[0]["value"] == "") {
//       MappingKaryawanApi.search(searchKey, filterKey[0]["value"])
//         .then((res) => {
//           setData(res.data.data);
//           setTotalPage(res.data.total_page);
//           setAlertConfig({
//             variant: "primary",
//             searchKey: searchKey,
//             filterKey: "Semua Data",
//           });
//         })
//         .catch(() => {
//           setAlertConfig({
//             variant: "danger",
//             text: "Gagal untuk memuat data",
//           });
//         })
//         .finally(() => {
//           setPage(1);
//           setIsSearching(true);
//           setIsLoading(false);
//           setShowAlert(true);
//         });
//     } else {
//       MappingKaryawanApi.search(searchKey, filterKey[0]["value"])
//         .then((res) => {
//           setData(res.data.data);
//           setTotalPage(res.data.total_page);
//           setAlertConfig({
//             variant: "primary",
//             searchKey: searchKey,
//             filterKey: filterKey[0]["label"],
//           });
//         })
//         .catch(() => {
//           setAlertConfig({
//             variant: "danger",
//             text: "Gagal untuk memuat data",
//           });
//         })
//         .finally(() => {
//           setPage(1);
//           setIsSearching(true);
//           setIsLoading(false);
//           setShowAlert(true);
//         });
//     }
//   };

//   useEffect(() => {
//     // set judul di Navbar
//     setNavbarTitle("Mapping Karyawan");

//     // jalankan function request data ke server
//     getData();
//     // getFilterData()

//     // menangani pembersihan saat fetching data
//     return () => {
//       setIsLoading(false);
//     };
//   }, [setNavbarTitle, page, dataLength]);

//   // Tabel
//   const Table = () => (
//     <>
//       <CRUDLayout.Table>
//         <THead>
//           <Tr>
//             <ThFixed>No</ThFixed>
//             <ThFixed>Aksi</ThFixed>
//             <Th>No. Karyawan</Th>
//             <Th>Nama Karyawan</Th>
//             <Th>Jabatan</Th>
//             <Th>Unit Organisasi</Th>
//             <Th>Unit Produksi</Th>
//             <Th>Cabang</Th>
//           </Tr>
//         </THead>
//         <TBody>
//           {data.map((val, index) => (
//             <Tr key={index}>
//               <TdFixed>{TableNumber(page, dataLength, index)}</TdFixed>
//               <TdFixed>
//                 <div className="d-flex justify-content-center">
//                   <UpdateButton
//                     onClick={() => {
//                       setUpdateData(val);
//                       setIsUpdateForm(true);
//                     }}
//                   />
//                   <DeleteButton
//                     onClick={() => {
//                       setDeleteData(val);
//                       setIsDeleteData(true);
//                     }}
//                   />
//                 </div>
//               </TdFixed>
//               <Td>{val.no_karyawan}</Td>
//               <Td>{val.nama_karyawan}</Td>
//               <Td>{val.nama_jabatan}</Td>
//               <Td>{val.nama_unit_organisasi}</Td>
//               <Td>{val.nama_unit_produksi}</Td>
//               <Td>{val.cabang}</Td>
//             </Tr>
//           ))}
//         </TBody>
//       </CRUDLayout.Table>
//       {!isSearching && (
//         <Pagination
//           dataLength={dataLength}
//           onDataLengthChange={(e) => setDataLength(e.target.value)}
//           currentPage={page}
//           totalPage={totalPage}
//           onPaginationChange={({ selected }) => setPage(selected + 1)}
//         />
//       )}
//     </>
//   );

//   // modal tambah
//   const TambahModal = () => {
//     // nilai awal form
//     const formInitialValues = {
//       id_karyawan: "",
//       nama_jabatan: "",
//       nama_unit_organisasi: "",
//       kode_unit_produksi: "",
//     };

//     // skema validasi form
//     const formValidationSchema = Yup.object().shape({
//       id_karyawan: Yup.string().required("Pilih Karyawan"),
//       kode_unit_produksi: Yup.string().required("Pilih Unit Produksi"),
//     });

//     // request tambah data ke server
//     const formSubmitHandler = (values) => {
//       MappingKaryawanApi.store(values)
//         .then(() => {
//           // konfigurasi alert
//           setAlertConfig({
//             variant: "primary",
//             text: "Tambah data berhasil!",
//           });
//         })
//         .catch(() => {
//           // konfigurasi alert
//           setAlertConfig({
//             variant: "danger",
//             text: `Tambah data gagal!`,
//           });
//         })
//         .finally(() => {
//           // menutup modal
//           setIsCreateForm(false);
//           // menampilkan alert
//           setShowAlert(true);
//           // request data baru ke server
//           getData();
//         });
//     };

//     return (
//       <CreateModal show={isCreateForm} onHide={() => setIsCreateForm(false)}>
//         <Formik
//           initialValues={formInitialValues}
//           validationSchema={formValidationSchema}
//           onSubmit={formSubmitHandler}
//         >
//           {({
//             values,
//             errors,
//             touched,
//             isSubmitting,
//             handleChange,
//             setFieldValue,
//             handleSubmit,
//           }) => (
//             <form onSubmit={handleSubmit}>
//               <SelectSearch
//                 label="Karyawan"
//                 name="id_karyawan"
//                 defaultValue={{
//                   value: "",
//                   label: "Pilih Karyawan",
//                 }}
//                 option={dataKaryawan.map((val) => {
//                   return {
//                     value: val.id_karyawan,
//                     label: val.nama_karyawan,
//                     nama_jabatan: val.nama_jabatan,
//                     nama_unit_organisasi: val.nama_unit_organisasi,
//                   };
//                 })}
//                 onChange={(val) => {
//                   setFieldValue("id_karyawan", val.value);
//                   setFieldValue("nama_karyawan", val.label);
//                   setFieldValue("nama_jabatan", val.nama_jabatan);
//                   setFieldValue("nama_unit_organisasi", val.nama_unit_organisasi);
//                 }}
//                 error={errors.id_karyawan && touched.id_karyawan && true}
//                 errorText={errors.id_karyawan && touched.id_karyawan && errors.id_karyawan}
//               />
//               <Input
//                 label="Jabatan"
//                 readOnly={true}
//                 onChange={handleChange}
//                 value={values.nama_jabatan}
//               />
//               <Input
//                 label="Unit Organisasi"
//                 readOnly={true}
//                 onChange={handleChange}
//                 value={values.nama_unit_organisasi}
//               />
//               <SelectSearch
//                 label="Unit Produksi"
//                 name="kode_unit_produksi"
//                 defaultValue={{
//                   value: "",
//                   label: "Pilih Unit Produksi",
//                 }}
//                 option={dataUnitProduksi.map((val) => {
//                   return {
//                     value: val.kode_unit_produksi,
//                     label: val.nama_unit_produksi,
//                   };
//                 })}
//                 onChange={(val) => {
//                   setFieldValue("kode_unit_produksi", val.value);
//                   setFieldValue("nama_unit_produksi", val.label);
//                 }}
//                 error={errors.kode_unit_produksi && touched.kode_unit_produksi && true}
//                 errorText={
//                   errors.kode_unit_produksi &&
//                   touched.kode_unit_produksi &&
//                   errors.kode_unit_produksi
//                 }
//               />
//               <div className="d-flex justify-content-end">
//                 <ActionButton
//                   type="submit"
//                   variant="primary"
//                   text="Tambah"
//                   loading={isSubmitting}
//                 />
//               </div>
//             </form>
//           )}
//         </Formik>
//       </CreateModal>
//     );
//   };

//   // modal ubah
//   const UbahModal = () => {
//     // nilai awal form
//     const formInitialValues = {
//       id_karyawan: updateData.id_karyawan,
//       nama_jabatan: updateData.nama_jabatan,
//       nama_unit_organisasi: updateData.nama_unit_organisasi,
//       kode_unit_produksi: updateData.kode_unit_produksi,
//       nama_karyawan: updateData.nama_karyawan,
//       nama_unit_produksi: updateData.nama_unit_produksi,
//     };

//     // skema validasi form
//     const formValidationSchema = Yup.object().shape({
//       id_karyawan: Yup.string().required("Pilih Karyawan"),
//       kode_unit_produksi: Yup.string().required("Pilih Unit Produksi"),
//     });

//     // request ubah data ke server
//     const formSubmitHandler = (values) => {
//       MappingKaryawanApi.update(values)
//         .then(() => {
//           // konfigurasi alert
//           setAlertConfig({
//             variant: "primary",
//             text: "Ubah data berhasil!",
//           });
//         })
//         .catch((err) => {
//           // konfigurasi alert
//           setAlertConfig({
//             variant: "danger",
//             text: `Ubah data gagal!`,
//           });
//         })
//         .finally(() => {
//           // menutup modal
//           setIsUpdateForm(false);
//           // menampilkan alert
//           setShowAlert(true);
//           // request data baru ke server
//           getData();
//         });
//     };

//     return (
//       <UpdateModal show={isUpdateForm} onHide={() => setIsUpdateForm(false)}>
//         <Formik
//           initialValues={formInitialValues}
//           validationSchema={formValidationSchema}
//           onSubmit={formSubmitHandler}
//         >
//           {({
//             values,
//             errors,
//             touched,
//             isSubmitting,
//             handleChange,
//             setFieldValue,
//             handleSubmit,
//           }) => (
//             <form onSubmit={handleSubmit}>
//               <SelectSearch
//                 label="Karyawan"
//                 name="id_karyawan"
//                 defaultValue={{
//                   value: values.id_karyawan ? values.id_karyawan : "",
//                   label: values.nama_karyawan ? values.nama_karyawan : "Pilih Karyawan",
//                 }}
//                 isDisabled={true}
//                 onChange={(val) => {
//                   setFieldValue("id_karyawan", val.value);
//                   setFieldValue("nama_karyawan", val.label);
//                   setFieldValue("nama_jabatan", val.nama_jabatan);
//                   setFieldValue("nama_unit_organisasi", val.nama_unit_organisasi);
//                 }}
//                 error={errors.id_karyawan && touched.id_karyawan && true}
//                 errorText={errors.id_karyawan && touched.id_karyawan && errors.id_karyawan}
//               />
//               <Input
//                 label="Jabatan"
//                 readOnly={true}
//                 value={values.nama_jabatan}
//                 onChange={handleChange}
//               />
//               <Input
//                 label="Unit Organisasi"
//                 readOnly={true}
//                 value={values.nama_unit_organisasi}
//                 onChange={handleChange}
//               />
//               <SelectSearch
//                 label="Unit Produksi"
//                 name="kode_unit_produksi"
//                 defaultValue={{
//                   value: values.kode_unit_produksi ? values.kode_unit_produksi : "",
//                   label: values.nama_unit_produksi
//                     ? values.nama_unit_produksi
//                     : "Pilih Unit Produksi",
//                 }}
//                 option={dataUnitProduksi.map((val) => {
//                   return {
//                     value: val.kode_unit_produksi,
//                     label: val.nama_unit_produksi,
//                   };
//                 })}
//                 onChange={(val) => {
//                   setFieldValue("kode_unit_produksi", val.value);
//                   setFieldValue("nama_unit_produksi", val.label);
//                 }}
//                 error={errors.kode_unit_produksi && touched.kode_unit_produksi && true}
//                 errorText={
//                   errors.kode_unit_produksi &&
//                   touched.kode_unit_produksi &&
//                   errors.kode_unit_produksi
//                 }
//               />
//               <div className="d-flex justify-content-end">
//                 <ActionButton type="submit" variant="success" text="Ubah" loading={isSubmitting} />
//               </div>
//             </form>
//           )}
//         </Formik>
//       </UpdateModal>
//     );
//   };

//   // modal hapus
//   const HapusModal = () => {
//     // set id dari data yang ingin dihapus
//     const values = {
//       id_karyawan: updateData.id_karyawan,
//       kode_unit_produksi: updateData.kode_unit_produksi,
//     };

//     // menangani delete button loading
//     const [btnLoading, setBtnLoading] = useState(false);

//     // menangani pembersihan saat fetching data
//     useEffect(() => {
//       return () => {
//         setBtnLoading(false);
//       };
//     }, []);

//     // request hapus data ke server
//     const deleteDataHandler = () => {
//       // set delete button loading
//       setBtnLoading(true);

//       MappingKaryawanApi.delete(values)
//         .then(() => {
//           // konfigurasi alert
//           setAlertConfig({
//             variant: "primary",
//             text: "Hapus data berhasil!",
//           });
//         })
//         .catch(() => {
//           // konfigurasi alert
//           setAlertConfig({
//             variant: "danger",
//             text: `Hapus data gagal!`,
//           });
//         })
//         .finally(() => {
//           // menutup modal
//           setIsDeleteData(false);
//           // menampilkan alert
//           setShowAlert(true);
//           // request data baru ke server
//           getData();
//         });
//     };

//     return (
//       <DeleteModal
//         show={isDeleteData}
//         onHide={() => setIsDeleteData(false)}
//         loading={btnLoading}
//         onConfirm={deleteDataHandler}
//       />
//     );
//   };

//   return (
//     <CRUDLayout>
//       <CRUDLayout.Head>
//         <CRUDLayout.HeadSearchSection>
//           <InputSearch
//             placeholder="Cari karyawan"
//             value={searchKey}
//             onChange={(e) => setSearchKey(e.target.value)}
//             onSubmit={searchData}
//           />
//         </CRUDLayout.HeadSearchSection>
//         <CRUDLayout.HeadSearchSection>
//           <SelectSearch
//             label=""
//             name="#"
//             // defaultValue={{
//             //   value: "",
//             //   label: "Filter Unit Produksi"
//             // }}
//             option={filterData.map((val) => {
//               return {
//                 value: val.kode_unit_produksi,
//                 label: val.nama_unit_produksi,
//               };
//             })}
//             onChange={handleFilter}
//           />
//         </CRUDLayout.HeadSearchSection>
//         <CRUDLayout.HeadButtonSection>
//           <ExportButton />
//           <CreateButton
//             onClick={() => {
//               setIsCreateForm(true);
//             }}
//           />
//         </CRUDLayout.HeadButtonSection>
//       </CRUDLayout.Head>
//       {/* Alert */}
//       {isSearching ? (
//         <BSAlert
//           dismissible={true}
//           variant={alertConfig.variant}
//           onClose={() => {
//             setShowAlert(false);
//             getData();
//             setIsSearching(false);
//           }}
//         >
//           {isLoading && (
//             <Spinner
//               size="sm"
//               animation="border"
//               variant={alertConfig.variant}
//               className="mr-2 mb-1"
//             />
//           )}
//           <span>
//             {alertConfig.searchKey !== "" ? (
//               <p>Hasil dari pencarian: {alertConfig.searchKey}</p>
//             ) : (
//               ""
//             )}
//             {alertConfig.filterKey !== "" ? <p>Hasil dari filter: {alertConfig.filterKey}</p> : ""}
//           </span>
//         </BSAlert>
//       ) : (
//         <Alert
//           show={showAlert}
//           showCloseButton={true}
//           variant={alertConfig.variant}
//           text={alertConfig.text}
//           onClose={() => setShowAlert(false)}
//         />
//       )}

//       {/* Table */}
//       {
//         // cek apakah data sedang dimuat (loading)
//         isLoading === true ? (
//           <DataStatus loading={true} text="Memuat data..." />
//         ) : // Cek apakah ada data
//         data.length > 0 ? (
//           <Table />
//         ) : (
//           <DataStatus text="Tidak ada data" />
//         )
//       }

//       <TambahModal />
//       <UbahModal />
//       <HapusModal />
//     </CRUDLayout>
//   );
// };

// export default MappingKaryawan;
import React from "react";

const MappingUnitProduksi = () => {
  return <div></div>;
};

export default MappingUnitProduksi;
