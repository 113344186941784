export const formSubmitMapper = (values) => ({
  tgl_stock_fisik_master: values?.tglStock,
  id_stock_fisik_master: values?.idStockMaster,
  no_stock_fisik_master: values?.noStock,
  is_opname: values?.isOpname,
  id_gudang: parseInt(values?.idGudang),
  detail: values?.detail
    ?.filter((val) => parseFloat(val?.qtyStock) > 0)
    .map((val) => ({
      id_item_barang: val.idItem,
      qty_stock_fisik: val.qtyStock,
    })),
});
