import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { FilterModal, DatePicker, SelectSearch } from "components";
import { DateConvert } from "utilities";
import Axios from "axios";
import { StockFisikApi } from "api";

const ModalFilter = ({ show, setShow, data, setData }) => {
  // STATE DATA
  const [dataUnitProduksi, setDataUnitProduksi] = useState([
    { label: "Semua", value: undefined },
  ]);

  const [dataGudang, setDataGudang] = useState([
    { label: "Semua", value: undefined },
  ]);
  const dataStatusOpname = [
    { label: "Semua", value: undefined },
    {
      label: "BELUM DIOPNAME",
      value: false,
    },
    {
      label: "SUDAH DIOPNAME",
      value: true,
    },
  ];
  const [loading, setLoading] = useState(true);
  const [isUnitProduksiChange, setIsUnitProduksiChange] = useState({
    isDisable: true,
    isLoading: false,
  });
  // REQUSET DATA SERVER
  const getDataDropdown = () => {
    Axios.all([StockFisikApi.dropdownUnitProduksi()])
      .then(
        Axios.spread((unit_produksi) => {
          const mapDataPetugas = unit_produksi?.data?.data
            ? unit_produksi.data.data.map((val) => ({
                label: val?.nama_unit_produksi,
                value: val?.id_unit_produksi,
              }))
            : [];

          setDataUnitProduksi([...dataUnitProduksi, ...mapDataPetugas]);
        })
      )
      .finally(() => setLoading(false));
  };

  const getDropdownGudang = (id) => {
    setIsUnitProduksiChange({ isDisable: true, isLoading: true });

    Axios.all([StockFisikApi.dropdownGudang({ id_unit_produksi: id })])
      .then(
        Axios.spread((gudang) => {
          const mapDataGudang = gudang?.data?.data
            ? gudang.data.data.map((val) => ({
                label: val?.nama_gudang,
                value: val?.id_gudang,
              }))
            : [];

          setDataGudang([...dataGudang, ...mapDataGudang]);
        })
      )
      .finally(() => {
        setIsUnitProduksiChange({ isLoading: false, isDisable: false });
      });
  };
  // USE EFFECT ALL DROPDOWN
  useEffect(() => {
    getDataDropdown();
  }, []);
  // FORM VALUES
  const formInitialValues = {
    tgl_stock_fisik_master_mulai: data?.filter?.tgl_stock_fisik_master_mulai,
    tgl_stock_fisik_master_selesai:
      data?.filter?.tgl_stock_fisik_master_selesai,
    id_unit_produksi: data?.filter?.id_unit_produksi,
    id_gudang: data?.filter?.id_gudang,
    is_opname: data?.filter?.is_opname,
  };
  const formSubmitHandler = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    if (checkActive) {
      setData({
        filter: {
          ...values,
          active: true,
        },
        pagination: {
          ...data.pagination,
          page: 1,
        },
      });
    } else {
      setData({
        ...data,
        filter: {
          ...values,
          active: false,
        },
      });
    }

    setShow(false);
  };
  // HANDLE CHANGE
  const onTanggalStockChange = (dates, values, setValues) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      tgl_stock_fisik_master_mulai: startDate,
      tgl_stock_fisik_master_selesai: endDate,
    });
  };

  const onResetButtonClick = (values, setValues) => {
    setValues({
      tgl_stock_fisik_master_mulai: undefined,
      tgl_stock_fisik_master_selesai: undefined,
      id_unit_produksi: undefined,
      id_gudang: undefined,
      is_opname: undefined,
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      onSubmit={formSubmitHandler}>
      {({ values, handleSubmit, setFieldValue, setValues }) => (
        <FilterModal
          show={show}
          setShow={setShow}
          onResetButtonClick={() => onResetButtonClick(values, setValues)}
          onFilterButtonClick={handleSubmit}>
          <DatePicker
            selectsRange
            label="Tgl. Stock Fisik"
            placeholderText="Pilih tanggal stock fisik"
            startDate={
              values.tgl_stock_fisik_master_mulai
                ? new Date(values.tgl_stock_fisik_master_mulai)
                : ""
            }
            endDate={
              values.tgl_stock_fisik_master_selesai
                ? new Date(values.tgl_stock_fisik_master_selesai)
                : ""
            }
            onChange={(dates) => onTanggalStockChange(dates, values, setValues)}
            monthsShown={2}
          />
          <SelectSearch
            key={values.id_unit_produksi}
            label="Unit Produksi"
            placeholder="Pilih unit produksi"
            defaultValue={dataUnitProduksi.find(
              (val) => val.value === values.id_unit_produksi
            )}
            option={dataUnitProduksi}
            onChange={(val) => {
              setFieldValue("id_unit_produksi", val.value);
              getDropdownGudang(val.value);
            }}
            loading={loading}
          />
          <SelectSearch
            key={values.id_gudang}
            label="Gudang"
            placeholder="Pilih gudang"
            isDisabled={isUnitProduksiChange.isDisable}
            defaultValue={dataGudang.find(
              (val) => val.value === values.id_gudang
            )}
            option={dataGudang}
            onChange={(val) => setFieldValue("id_gudang", val.value)}
            loading={isUnitProduksiChange.isLoading}
          />
          <SelectSearch
            key={values.is_opname}
            label="Status Stock Opname"
            placeholder="Pilih stock opname"
            defaultValue={dataStatusOpname.find(
              (val) => val.value === values.is_opname
            )}
            option={dataStatusOpname}
            onChange={(val) => setFieldValue("is_opname", val.value)}
            loading={loading}
          />
        </FilterModal>
      )}
    </Formik>
  );
};
export default ModalFilter;
