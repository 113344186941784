import React, {useState, useEffect} from 'react'
import { Formik } from 'formik'
import {
    FilterModal,
    DatePicker,
    SelectSearch
} from 'components'
import { DateConvert } from 'utilities'
import Axios from "axios"
import { MutasiAntarGudangApi } from "api";

const ModalFilter = ({ show, setShow, data, setData }) => {
// STATE DATA
    const [dataItemBarang, setDataItemBarang] = useState([{label: 'Semua', value: undefined}])
    const [dataGudang, setDataGudang] = useState([{label: 'Semua', value: undefined}])
    const [dataSatuan, setDataSatuan] = useState([{label: 'Semua', value: undefined}])
    const [dataStatusApproval, setDataStatusApproval] = useState([
        {
            label: 'Semua',
            value: undefined
        },
        {
            label: 'APPROVED',
            value: 'APP'
        },
        {
            label: 'VERIFIED',
            value: 'VER'
        },
        {
            label: 'REVISI',
            value: 'REV'
        },
        {
            label: 'REJECT',
            value: 'REJ'
        },
        {
            label: 'PENDING',
            value: 'PEN'
        }
    ])
    const [loading, setLoading] = useState(true)
// REQUSET DATA SERVER 
    const getDataDropdown = () => {
        Axios.all([
        MutasiAntarGudangApi.getDropdownItemBarang({tipe: "bahan"}),
        MutasiAntarGudangApi.getDropdownGudang({tipe: "gudang"}),
        MutasiAntarGudangApi.getDropdownSatuan({tipe: "satuan"}),
        ])
        .then(Axios.spread((item, gudang, satuan) => {
            const mapDataItemBarang = item?.data?.data ? item.data.data.map(val => ({label: val?.nama_item, value: val?.id_item_buaso})) : []
            const mapDataGudang = gudang?.data?.data ? gudang.data.data.map(val => ({label: val?.nama_gudang, value: val?.id_gudang})) : []
            const mapDataSatuan = satuan?.data?.data ? satuan.data.data.map(val => ({label: val?.nama_satuan, value: val?.id_satuan})) : []

            setDataItemBarang([...dataItemBarang, ...mapDataItemBarang])
            setDataGudang([...dataGudang, ...mapDataGudang])
            setDataSatuan([...dataSatuan, ...mapDataSatuan])
        }))
        .finally(() => setLoading(false))
    }
// USE EFFECT ALL DROPDOWN
    useEffect(() => {
        getDataDropdown()
    }, [])
// FORM VALUES
    const formInitialValues = {
        tgl_mutasi_mulai: data?.filter?.tgl_mutasi_mulai,
        tgl_mutasi_selesai: data?.filter?.tgl_mutasi_selesai,
        item_barang: data?.filter?.item_barang,
        gudang_asal: data?.filter?.gudang_asal,
        gudang_tujuan: data?.filter?.gudang_tujuan,
        satuan: data?.filter?.satuan,
        status_approval: data?.filter?.status_approval,
    }
    const formSubmitHandler = (values) => {
        const checkActive = !!Object.values(values).filter(val => val !== undefined).length > 0

        if (checkActive) {
          setData({ 
              filter: {
                  ...values,
                  active: true
              },
              pagination: {
                  ...data.pagination,
                  page: 1
              }
           })
        } else { 
            setData({ 
                ...data,
                filter: {
                    ...values,
                    active: false
                }
             })
        }
    
        setShow(false)
    }
// HANDLE CHANGE 
    const onTanggalMutasiChange = (dates, values, setValues) => {
        const [start, end] = dates
        const startDate = start ? DateConvert(start).default : null
        const endDate = end ? DateConvert(end).default : null

        setValues({
            ...values,
            tgl_mutasi_mulai: startDate,
            tgl_mutasi_selesai: endDate,
        })
    }
    const onResetButtonClick = (values, setValues) => {
        setValues({
            tgl_mutasi_mulai: undefined,
            tgl_mutasi_selesai: undefined,
            item_barang: undefined,
            gudang_asal: undefined,
            gudang_tujuan: undefined,
            satuan: undefined,
            status_approval: undefined,
        })
    }

    return (
        <Formik
            enableReinitialize
            initialValues={formInitialValues}
            onSubmit={formSubmitHandler}>
            
            {({values, handleSubmit, setFieldValue, setValues}) => (
                <FilterModal
                    show={show}
                    setShow={setShow}
                    onResetButtonClick={() => onResetButtonClick(values, setValues)}
                    onFilterButtonClick={handleSubmit}>
                    
                    <DatePicker 
                        selectsRange
                        label="Tgl. Mutasi"
                        placeholderText="Pilih Tanggal Mutasi"
                        startDate={values.tgl_mutasi_mulai ? new Date(values.tgl_mutasi_mulai) : ''}
                        endDate={values.tgl_mutasi_selesai ? new Date(values.tgl_mutasi_selesai) : ''}
                        onChange={dates => onTanggalMutasiChange(dates, values, setValues)}
                        monthsShown={2}
                    />
                    <SelectSearch 
                        key={values.item_barang}
                        label="Item Barang"
                        placeholder="Pilih Item Barang"
                        defaultValue={dataItemBarang.find(val => val.value === values.item_barang)}
                        option={dataItemBarang}
                        onChange={val => setFieldValue('item_barang', val.value)}
                        loading={loading}
                    />
                    <SelectSearch
                        key={values.gudang_asal}
                        label="Gudang Asal"
                        placeholder="Pilih Gudang Asal"
                        defaultValue={dataGudang.find(val => val.value === values.gudang_asal)}
                        option={dataGudang}
                        onChange={val => setFieldValue('gudang_asal', val.value)}
                        loading={loading}
                    />
                    <SelectSearch
                        key={values.gudang_tujuan}
                        label="Gudang Tujuan"
                        placeholder="Pilih Gudang Tujuan"
                        defaultValue={dataGudang.find(val => val.value === values.gudang_tujuan)}
                        option={dataGudang}
                        onChange={val => setFieldValue('gudang_tujuan', val.value)}
                        loading={loading}
                    />
                    <SelectSearch
                        key={values.satuan}
                        label="Satuan"
                        placeholder="Pilih Satuan"
                        defaultValue={dataSatuan.find(val => val.value === values.satuan)}
                        option={dataSatuan}
                        onChange={val => setFieldValue('satuan', val.value)}
                        loading={loading}
                    />
                    <SelectSearch
                        key={values.status_approval}
                        label="Status Approval"
                        placeholder="Pilih Status Approval"
                        defaultValue={dataStatusApproval.find(val => val.value === values.status_approval)}
                        option={dataStatusApproval}
                        onChange={val => setFieldValue('status_approval', val.value)}
                        loading={loading}
                    />
                </FilterModal>
            )}
        </Formik>
    )
}
export default ModalFilter;