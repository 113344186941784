import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { IoEyeOutline } from "react-icons/io5";
import _ from "lodash";
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  UpdateButton,
  ReadButton,
  InputSearch,
  Pagination,
  Table,
  Th,
  Td,
  ThFixed,
  TdFixed,
  CreateButton,
  ApprovalStatusButton,
  FilterButton,
} from "components";
import { DateConvert, DecimalConvert, TableNumber } from "utilities";
import { SuratJalanApi } from "api";
import { ModalFilter } from "./Section";

const ListSuratJalan = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dataSuratJalan, setDataSuratJalan] = useState([]);
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: location?.state?.surat_jalan?.filter?.active,
      tgl_surat_jalan_mulai:
        location?.state?.surat_jalan?.filter?.tgl_surat_jalan_mulai,
      tgl_surat_jalan_selesai:
        location?.state?.surat_jalan?.filter?.tgl_surat_jalan_selesai,
      tgl_delivery_order_mulai:
        location?.state?.surat_jalan?.filter?.tgl_delivery_order_mulai,
      tgl_delivery_order_selesai:
        location?.state?.surat_jalan?.filter?.tgl_delivery_order_selesai,
      tgl_batas_waktu_mulai:
        location?.state?.surat_jalan?.filter?.tgl_batas_waktu_mulai,
      tgl_batas_waktu_selesai:
        location?.state?.surat_jalan?.filter?.tgl_batas_waktu_selesai,
      petugas_pengirim: location?.state?.surat_jalan?.filter?.petugas_pengirim,
      armada_pengirim: location?.state?.surat_jalan?.filter?.armada_pengirim,
      gudang: location?.state?.surat_jalan?.filter?.gudang,
      status_approval: location?.state?.surat_jalan?.filter?.status_approval,
    },
    pagination: {
      page: location?.state?.surat_jalan?.filter?.page ?? "1",
      dataLength: location?.state?.surat_jalan?.filter?.dataLength ?? "10",
      totalPage: location?.state?.surat_jalan?.filter?.totalPage ?? "1",
      dataCount: location?.state?.surat_jalan?.filter?.dataCount ?? "0",
    },
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [searchTime, setSearchTime] = useState(null);

  const getInitialData = () => {
    setIsPageLoading(true);

    SuratJalanApi.get({
      q: searchConfig.key,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      tgl_surat_jalan_mulai: dataFilter?.filter?.tgl_surat_jalan_mulai,
      tgl_surat_jalan_selesai: dataFilter?.filter?.tgl_surat_jalan_selesai,
      tgl_delivery_order_mulai: dataFilter?.filter?.tgl_delivery_order_mulai,
      tgl_delivery_order_selesai:
        dataFilter?.filter?.tgl_delivery_order_selesai,
      tgl_batas_waktu_mulai: dataFilter?.filter?.tgl_batas_waktu_mulai,
      tgl_batas_waktu_selesai: dataFilter?.filter?.tgl_batas_waktu_selesai,
      petugas_pengirim: dataFilter?.filter?.petugas_pengirim,
      armada_pengirim: dataFilter?.filter?.armada_pengirim,
      gudang: dataFilter?.filter?.gudang,
      status_approval: dataFilter?.filter?.status_approval,
    })
      .then((data) => {
        setDataSuratJalan(data?.data?.data ?? []);
        setDataFilter({
          ...dataFilter,
          pagination: {
            ...dataFilter.pagination,
            dataCount: data?.data?.data_count,
            totalPage: data?.data?.total_page,
          },
        });
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const generateStatusApproval = (status) => {
    const newStatus = status?.toUpperCase();
    if (newStatus === "REJ") {
      return "REJECTED";
    }

    if (newStatus === "REV") {
      return "REVISI";
    }

    if (newStatus === "APP") {
      return "APPROVED";
    }

    if (newStatus === "VER") {
      return "VERIFIED";
    }

    return "PENDING";
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const onInputSearch = (text) => {
    const key = text.target.value;
    if (searchTime) clearTimeout(searchTime);
    setSearchTime(
      setTimeout(() => {
        setSearchConfig({
          ...searchConfig,
          key: text.target.value,
        });
        setDataFilter({
          ...dataFilter,
          pagination: {
            ...dataFilter.pagination,
            page: "1",
            dataLength: "10",
            totalPage: "1",
            dataCount: "0",
          },
        });
        setAlertConfig({
          show: key ? true : false,
          variant: "primary",
          text: "Hasil dari pencarian: " + key,
        });
      }, 750)
    );
  };

  useEffect(() => {
    setNavbarTitle("Surat Jalan");
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, [
    searchConfig.key,
    dataFilter?.pagination?.page,
    dataFilter?.pagination?.dataLength,
    dataFilter?.filter?.active,
    dataFilter?.filter?.tgl_surat_jalan_mulai,
    dataFilter?.filter?.tgl_surat_jalan_selesai,
    dataFilter?.filter?.tgl_delivery_order_mulai,
    dataFilter?.filter?.tgl_delivery_order_selesai,
    dataFilter?.filter?.tgl_batas_waktu_mulai,
    dataFilter?.filter?.tgl_batas_waktu_selesai,
    dataFilter?.filter?.petugas_pengirim,
    dataFilter?.filter?.armada_pengirim,
    dataFilter?.filter?.gudang,
    dataFilter?.filter?.status_approval,
  ]);

  const PageContent = () => {
    const checkStatus = (stat) => {
      const data = stat ? stat.toUpperCase() : "";
      const convert = data.substring(0, 3);

      if (convert === "APP")
        return {
          variant: "outline-success",
          label: "APPROVED",
        };
      // return "APPROVED";
      if (convert === "VER")
        return {
          variant: "outline-verified",
          label: "VERIFIED",
        };
      // return "VERIFIED";
      if (convert === "REV")
        return {
          variant: "outline-revised",
          label: "REVISION",
        };
      // return "REVISI";
      if (convert === "REJ")
        return {
          variant: "outline-danger",
          label: "REJECTED",
        };
      // return "REJECT";

      return {
        variant: "outline-secondary",
        label: "PENDING",
      };
      // return "PENDING";
    };
    const ItemBarangCollapse = ({ data }) => {
      const [isReadMoreClick, setisReadMoreClick] = useState(false);

      return (
        <>
          <ul className="pl-3 mb-1" style={{ fontSize: "12px" }}>
            {data.map((brg, index) => {
              if (isReadMoreClick) {
                return (
                  <li key={index} index={index}>
                    {`${brg.nama_barang ?? "-"} - ${
                      DecimalConvert(brg.qty_sj).getWithComa ?? "0"
                    } ${brg.satuan_jual ?? "-"}`}
                  </li>
                );
              } else {
                return (
                  index <= 2 && (
                    <li key={index} index={index}>
                      {`${brg.nama_barang ?? "-"} - ${
                        DecimalConvert(brg.qty_sj).getWithComa ?? "0"
                      } ${brg.satuan_jual ?? "-"}`}
                    </li>
                  )
                );
              }
            })}
          </ul>
          {data.length <= 3 ? (
            ""
          ) : (
            <div
              className="text-primary"
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                // fontWeight: "bold",
              }}
              onClick={() => {
                setisReadMoreClick((prev) => !prev);
              }}
            >
              {isReadMoreClick ? "Sembunyikan" : "Selengkapnya..."}
            </div>
          )}
        </>
      );
    };

    const DataTable = () => (
      <>
        <span style={{ fontSize: "14px" }}>
          <b>List Data Surat Jalan</b>
        </span>
        <Table>
          <thead>
            <tr>
              <ThFixed>No</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <ThFixed>Informasi Surat Jalan</ThFixed>
              <ThFixed>Informasi Delivery Order</ThFixed>
              <Th style={{ minWidth: "200px" }}>Petugas Pengiriman</Th>
              <Th style={{ minWidth: "200px" }}>Armada Pengiriman</Th>
              {/* <Th style={{ minWidth: "200px" }}>Gudang</Th> */}
              <Th style={{ minWidth: "300px" }}>Item Barang</Th>
              <Th width={100}>Tgl. Batas Waktu</Th>
              <ThFixed>Status Approval</ThFixed>
            </tr>
          </thead>
          <tbody>
            {dataSuratJalan.map((val, index) => (
              <tr key={index}>
                <TdFixed>
                  {TableNumber(
                    dataFilter?.pagination?.page,
                    dataFilter?.pagination?.dataLength,
                    index
                  )}
                </TdFixed>
                <Td className="d-flex">
                  <ReadButton
                    onClick={() =>
                      history.push(
                        "/inventory/transaksi/surat-jalan/detail/" +
                          val.id_surat_jalan,
                        { ...location?.state, surat_jalan: dataFilter }
                      )
                    }
                  />
                  {(val.status_approval === "REV" || !val.status_approval) && (
                    <UpdateButton
                      onClick={() =>
                        history.push(
                          "/inventory/transaksi/surat-jalan/ubah/" +
                            val.id_surat_jalan,
                          {
                            ...location?.state,
                            surat_jalan: dataFilter,
                          }
                        )
                      }
                    />
                  )}
                </Td>
                <TdFixed>
                  <div className="text-left">
                    {val.tgl_surat_jalan
                      ? DateConvert(new Date(val.tgl_surat_jalan)).defaultDMY
                      : "-"}
                  </div>
                  <div className="text-left">{val.no_surat_jalan ?? "-"}</div>
                </TdFixed>
                <TdFixed>
                  <div className="text-left">
                    {val.tgl_delivery_order
                      ? DateConvert(new Date(val.tgl_delivery_order)).defaultDMY
                      : "-"}
                  </div>
                  <div className="text-left">
                    {val.no_delivery_order ?? "-"}
                  </div>
                </TdFixed>
                <Td>{val.nama_sopir ?? "-"}</Td>
                <Td>
                  {val.plat_nomor && val.nama_item_aset
                    ? `${val.plat_nomor} - ${val.nama_item_aset}`
                    : "Tidak Memerlukan Armada Pengiriman"}
                </Td>
                {/* <Td>{val.nama_gudang ?? "-"}</Td> */}
                <Td>
                  {val.item_barang ? (
                    val.item_barang.length > 0 ? (
                      <ItemBarangCollapse data={val.item_barang} />
                    ) : (
                      "-"
                    )
                  ) : (
                    "-"
                  )}
                </Td>
                <Td>
                  {val.batas_waktu
                    ? DateConvert(new Date(val.batas_waktu)).defaultDMY
                    : "-"}
                </Td>
                <TdFixed>
                  <ApprovalStatusButton
                    variant={checkStatus(val.status_approval).variant}
                  >
                    {checkStatus(val.status_approval).label}
                  </ApprovalStatusButton>
                </TdFixed>
              </tr>
            ))}
          </tbody>
        </Table>
      </>
    );

    if (!dataSuratJalan || dataSuratJalan.length < 1) {
      return <DataStatus text="Tidak ada data" />;
    }

    return (
      <>
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={dataFilter?.pagination?.dataLength}
            dataNumber={
              dataFilter?.pagination?.page *
                dataFilter?.pagination?.dataLength -
              dataFilter?.pagination?.dataLength +
              1
            }
            dataPage={
              dataFilter?.pagination?.dataCount <
              dataFilter?.pagination?.dataLength
                ? dataFilter?.pagination?.dataCount
                : dataFilter?.pagination?.page *
                  dataFilter?.pagination?.dataLength
            }
            dataCount={dataFilter?.pagination?.dataCount}
            currentPage={dataFilter?.pagination?.page}
            totalPage={dataFilter?.pagination?.totalPage}
            onPaginationChange={({ selected }) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: selected + 1,
                },
              })
            }
            onDataLengthChange={(e) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: 1,
                  dataLength: e.target.value,
                },
              })
            }
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* Head Section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch onChange={onInputSearch} />
            <FilterButton
              active={dataFilter?.filter?.active}
              onClick={() => setShowModalFilter(true)}
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <CreateButton
            onClick={() =>
              history.push("/inventory/transaksi/surat-jalan/delivery-order", {
                ...location?.state,
                surat_jalan: dataFilter,
              })
            }
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* Content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <PageContent />
      )}
      {showModalFilter && (
        <ModalFilter
          show={showModalFilter}
          setShow={setShowModalFilter}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}
    </CRUDLayout>
  );
};

export default ListSuratJalan;
