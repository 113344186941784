import Services from "services";

class LaporanSuratJalanApi {
  getPage(params) {
    return Services.get("/laporan_surat_jalan/page", { params });
  }
  single(params) {
    return Services.get("/laporan_surat_jalan/single", { params });
  }
  getDropdownKendaraan() {
    return Services.get("/dropdown/armada");
  }
  getDropdownSupir() {
    return Services.get("/dropdown/supir");
  }

  export(params) {
    return Services.get("/laporan_surat_jalan/export", { params });
  }
}

export default new LaporanSuratJalanApi();
