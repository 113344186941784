const ListData = ({ label, children }) => (
	<div className="mb-2">
		<div>
			<small>{label}</small>
		</div>
		<div>
			<b>{children}</b>
		</div>
	</div>
)

export default ListData