import { useState, useEffect, useRef } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { Card, Row, Col } from "react-bootstrap";
import Axios from "axios";
import { Alert, BackButton, DataStatus, ActionButton } from "components";
import { SuratJalanApi } from "api";
import { InfoSection, TableSection } from "./Section";
import { TableDO } from "../Section";
import { Formik } from "formik";
import * as Yup from "yup";
import PrintSuratJalan from "./PrintSuratJalan";

const DetailSuratJalan = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();
  const refPrint = useRef();
  const reactToPrint = useReactToPrint({ content: () => refPrint.current });
  const [dataInfo, setDataInfo] = useState({});
  const [dataCetakSuratJalan, setDataCetakSuratJalan] = useState({});
  const [dataTable, setDataTable] = useState([]);
  const [dataStakeholder, setDataStakeholder] = useState([]);
  const [fetchingStatus, setFetchingStatus] = useState({
    loading: false,
    success: true,
  });

  const [isLoadingCetak, setIsLoadingCetak] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  const mappingDataInfo = (data) => {
    return {
      id_sales_order: data.id_sales_order ?? "-",
      id_petugas_pengiriman: data.id_supir ?? null,
      id_armada_pengiriman: data.id_kendaraan ?? null,
      tgl_sales_order: data.tgl_sales_order ?? null,
      tgl_surat_jalan: data.tgl_surat_jalan ?? null,
      no_sales_order: data.no_sales_order ?? "-",
      no_surat_jalan: data.no_surat_jalan ?? "-",
      no_delivery_order: data.no_delivery_order ?? "-",
      customer: data.nama_customer ?? "-",
      sales: data.nama_sales ?? "-",
      gudang: data.nama_gudang ?? "-",
      tgl_batas_waktu: data.batas_waktu ?? null,
      armada_pengiriman:
        `${data.plat_nomor ?? ""} - ${data.nama_item_aset}` ?? "-",
      petugas_pengiriman: data.nama_sopir ?? "-",
      nama_proyek: data.nama_proyek ?? "-",
    };
  };

  const mappingDataTable = (data) =>
    data.map((val) => {
      const qtySisaDo = parseFloat(val.qty_do ?? 0 - val.qty_terkirim ?? 0);

      return {
        id_surat_jalan_detail: val.id_surat_jalan_detail ?? null,
        id_delivery_order_detail: val.id_delivery_order_detail ?? null,
        id_delivery_order: val.id_delivery_order ?? null,
        kode_item: val.kode_barang ?? "-",
        nama_item: val.nama_barang ?? "-",
        satuan: val.nama_satuan ?? "",
        satuan_surat_jalan: val.satuan_jual ?? "",
        qty_do: parseFloat(val.qty_do ?? 0),
        qty_terkirim: parseFloat(val.qty_terkirim ?? 0),
        qty_sisa_do: parseFloat(val.qty_sisa_do ?? 0),
        qty_surat_jalan: parseFloat(val.qty_sj ?? 0),
        qty_pakai: parseFloat(val.qty_satuan_pakai ?? 0),
        qty_stok_gudang: parseFloat(val.qty_stok_gudang ?? 0),
        qty_menunggu_persetujuan: parseFloat(val.qty_menunggu_persetujuan ?? 0),
        nama_gudang: val.nama_gudang,
      };
    });

  const getCetakData = () => {
    setIsLoadingCetak(true);

    Axios.all([SuratJalanApi.cetak({ id_surat_jalan: id })])
      .then(
        Axios.spread((dataCetak) => {
          setDataCetakSuratJalan(dataCetak.data.data);
        })
      )
      .catch(() => {
        setAlertConfig({
          show: false,
          variant: "primary",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsLoadingCetak(false));
  };

  const getInitialData = () => {
    setFetchingStatus({
      loading: true,
      success: false,
    });

    Axios.all([SuratJalanApi.getSingle({ id_surat_jalan: id })])
      .then(
        Axios.spread((dataDO) => {
          const getData = dataDO.data.data;
          const getDataStakeholder = dataDO.data.data.stakeholder;
          const mapDataInfo = mappingDataInfo(getData ?? {});
          const mapDataTable = mappingDataTable(getData.detail ?? []);

          setDataInfo(mapDataInfo);
          setDataTable(mapDataTable);
          setDataStakeholder(getDataStakeholder ?? []);

          setFetchingStatus({
            loading: false,
            success: true,
          });
        })
      )
      .catch(() => {
        setAlertConfig({
          show: false,
          variant: "primary",
          text: "Data gagal dimuat!",
        });
        setFetchingStatus({
          loading: false,
          success: false,
        });
      });
  };

  const formInitialValues = {
    tanggal: dataInfo.tgl_surat_jalan,
    nomor: dataInfo.no_surat_jalan,
    petugas_pengiriman: dataInfo.id_petugas_pengiriman,
    armada_pengiriman: dataInfo.id_armada_pengiriman,
  };

  const formValidationSchema = Yup.object().shape({
    tanggal: Yup.string().required("Pilih tanggal"),
    nomor: Yup.string().required("Pilih tanggal untuk menentukan nomor"),
    petugas_pengiriman: Yup.string().required("Pilih petugas pengiriman"),
    armada_pengiriman: Yup.string().required("Pilih armada pengiriman"),
  });

  const formSubmitHandler = (values, { setSubmitting }) => {
    const tableValues = dataTable.map((val) => {
      return {
        id_delivery_order_detail: val.id_delivery_order_detail,
        id_delivery_order: val.id_delivery_order,
        qty: val.qty_pakai ?? 0,
        qty_surat_jalan: val.qty_surat_jalan ?? 0,
      };
    });
    const finalValues = {
      id_surat_jalan: id,
      id_supir: values.petugas_pengiriman,
      id_petugas_gudang: values.petugas_pengiriman,
      id_kendaraan: values.armada_pengiriman,
      tgl_surat_jalan: values.tanggal,
      detail: tableValues,
    };

    SuratJalanApi.update(finalValues)
      .then(() => {
        setAlertConfig({
          show: true,
          variant: "primary",
          text: "Data berhasil diubah!",
        });
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal diubah!",
        });
      })
      .finally(() => setSubmitting(false));
  };

  useEffect(() => {
    getInitialData();
    getCetakData();
    setNavbarTitle("Surat Jalan");
  }, []);

  const CatatanApprovalCard = () => {
    const InfoItem = ({ title1, value1, title2, value2 }) => (
      <div className="mb-2">
        <small>{title1}</small>
        <br />
        <b>{value1}</b>
        <div className="mt-1" />
        <small>{title2}</small>
        <br />
        <b>{value2}</b>
      </div>
    );

    return (
      <Card className="mt-4 mb-5">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Catatan Approval Surat Jalan</b>
        </Card.Header>
        <Card.Body>
          {dataStakeholder ? (
            <Row>
              {dataStakeholder.map((val, index) => (
                <Col sm>
                  <InfoItem
                    title1={
                      val.status_approval === "VER" ||
                      val.status_approval === "REV"
                        ? `Pemeriksa ${
                            val.approval_level !== "0" ? val.approval_level : ""
                          }`
                        : val.status_approval === "APP"
                        ? "Pengesah"
                        : val.status_approval === "PEN"
                        ? "Pembuat"
                        : "Di Tolak Oleh"
                    }
                    value1={val.nama_karyawan ?? "-"}
                    title2="Catatan"
                    value2={val.catatan ?? "-"}
                  />
                </Col>
              ))}
            </Row>
          ) : (
            <div className="d-flex justify-content-center">
              <DataStatus text="Tidak ada catatan" />
            </div>
          )}
        </Card.Body>
      </Card>
    );
  };

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Detail Data Surat Jalan</b>
          <BackButton
            size="sm"
            onClick={() =>
              history.push("/inventory/transaksi/surat-jalan", {
                ...location?.state,
              })
            }
          />
        </Card.Header>
        <Card.Body className="pb-5">
          <Alert
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            showCloseButton={true}
            onClose={() =>
              setAlertConfig({
                ...alertConfig,
                show: false,
              })
            }
          />
          {fetchingStatus.loading ? (
            <DataStatus loading text="Memuat . . ." />
          ) : fetchingStatus.success ? (
            <>
              <Formik
                initialValues={formInitialValues}
                validationSchema={formValidationSchema}
                onSubmit={formSubmitHandler}
              >
                {(formik) => (
                  <form onSubmit={formik.handleSubmit}>
                    <InfoSection dataInfo={dataInfo} />
                    <hr className="mt-1" />
                    <TableDO
                      formik={formik}
                      dataTable={dataTable}
                      setDataTable={setDataTable}
                      type="detail"
                    />
                    <hr />
                    <div className="d-flex justify-content-end align-items-end">
                      <ActionButton
                        size="sm"
                        text="Cetak Surat Jalan"
                        onClick={() => reactToPrint()}
                        loading={isLoadingCetak}
                      />
                    </div>

                    {/* PDF Surat Jalan*/}
                    <div style={{ display: "none" }}>
                      <PrintSuratJalan
                        ref={refPrint}
                        formik={formik}
                        data={dataCetakSuratJalan}
                        dataInfo={dataInfo}
                        dataTable={dataTable}
                      />
                    </div>
                  </form>
                )}
              </Formik>
            </>
          ) : (
            <DataStatus text="Data gagal dimuat" />
          )}
        </Card.Body>
      </Card>
      {!fetchingStatus.loading && fetchingStatus.success && (
        <CatatanApprovalCard />
      )}
    </>
  );
};

export default DetailSuratJalan;
