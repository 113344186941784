// React
import React, { useState, useEffect } from "react";

// Form
import { Formik } from "formik";

// Components
import { DatePicker, FilterModal, SelectSearch } from "components";
import { DateConvert } from "utilities";

// API
import Axios from "axios";
import { DeliveryOrderApi } from "api";

// View Functions
import { mappingData } from "../../PenerimaanBarang/components";

const ModalFilter = ({ show, setShow, data, setData, isDO }) => {
  // Variables
  const option = { label: "Semua Data", value: undefined };

  // States
  const [dropdownFilter, setDropdownFilter] = useState({
    customer: [option],
    sales: [option],
    gudang: [option],
    status_approval: [option],
  });
  const [loading, setLoading] = useState(true);

  // REQUSET DATA SERVER
  const getDataDropdown = () => {
    Axios.all([
      DeliveryOrderApi.dropdown("customer"),
      DeliveryOrderApi.dropdown("sales"),
      DeliveryOrderApi.dropdown("gudang"),
    ])
      .then(
        Axios.spread((customer, sales, gudang) => {
          setDropdownFilter({
            customer: [option, ...mappingData(customer, "customer")],
            sales: [option, ...mappingData(sales, "karyawan")],
            gudang: [option, ...mappingData(gudang, "gudang")],
            status_approval: [
              option,
              {
                value: "APP",
                label: "APPROVED",
              },
              {
                value: "VER",
                label: "VERIFIED",
              },
              {
                value: "REV",
                label: "REVISI",
              },
              {
                value: "REJ",
                label: "REJECT",
              },
              {
                value: "PEN",
                label: "PENDING",
              },
            ],
          });
        })
      )
      .finally(() => {
        setLoading(false);
      });
  };

  // USE EFFECT ALL DROPDOWN
  useEffect(() => {
    getDataDropdown();

    // eslint-disable-next-line
  }, []);

  // FORM VALUES
  const formInitialValues = {
    tgl_delivery_order_mulai: data.tgl_delivery_order_mulai,
    tgl_delivery_order_selesai: data.tgl_delivery_order_selesai,
    tgl_sales_order_mulai: data.tgl_sales_order_mulai,
    tgl_sales_order_selesai: data.tgl_sales_order_selesai,
    tgl_batas_waktu_mulai: data.tgl_batas_waktu_mulai,
    tgl_batas_waktu_selesai: data.tgl_batas_waktu_selesai,
    customer: data.customer,
    sales: data.sales,
    gudang: data.gudang,
    status_approval: data.status_approval,
  };
  const formSubmitHandler = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    setData({
      ...data,
      ...values,
      active: checkActive,
      page: 1,
    });

    setShow(false);
  };

  // HANDLE CHANGE
  const onResetButtonClick = (setValues) => {
    setLoading(true);

    const myPromise = new Promise((resolve) => {
      setValues({
        tgl_delivery_order_mulai: undefined,
        tgl_delivery_order_selesai: undefined,
        tgl_sales_order_mulai: undefined,
        tgl_sales_order_selesai: undefined,
        tgl_batas_waktu_mulai: undefined,
        tgl_batas_waktu_selesai: undefined,
        customer: undefined,
        sales: undefined,
        gudang: undefined,
        status_approval: undefined,
      });

      setTimeout(() => resolve("foo"), 300);
    });

    myPromise.then(() => setLoading(false));
  };

  const onTanggalChange = (
    dates,
    values,
    setValues,
    value_awal,
    value_akhir
  ) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      [value_awal]: startDate,
      [value_akhir]: endDate,
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      onSubmit={formSubmitHandler}
    >
      {({ values, handleSubmit, setFieldValue, setValues }) => (
        <FilterModal
          show={show}
          setShow={setShow}
          onResetButtonClick={() => onResetButtonClick(setValues)}
          onFilterButtonClick={handleSubmit}
        >
          {isDO && (
            <DatePicker
              selectsRange
              label="Tgl. Delivery Order"
              placeholderText="Pilih Tanggal Delivery Order"
              startDate={
                values.tgl_delivery_order_mulai
                  ? new Date(values.tgl_delivery_order_mulai)
                  : ""
              }
              endDate={
                values.tgl_delivery_order_selesai
                  ? new Date(values.tgl_delivery_order_selesai)
                  : ""
              }
              onChange={(dates) =>
                onTanggalChange(
                  dates,
                  values,
                  setValues,
                  "tgl_delivery_order_mulai",
                  "tgl_delivery_order_selesai"
                )
              }
              monthsShown={2}
            />
          )}

          <DatePicker
            selectsRange
            label="Tgl. Sales Order"
            placeholderText="Pilih Tanggal Sales Order"
            startDate={
              values.tgl_sales_order_mulai
                ? new Date(values.tgl_sales_order_mulai)
                : ""
            }
            endDate={
              values.tgl_sales_order_selesai
                ? new Date(values.tgl_sales_order_selesai)
                : ""
            }
            onChange={(dates) =>
              onTanggalChange(
                dates,
                values,
                setValues,
                "tgl_sales_order_mulai",
                "tgl_sales_order_selesai"
              )
            }
            monthsShown={2}
          />
          {/* {isDO && (
            <DatePicker
              selectsRange
              label="Tgl. Batas Waktu"
              placeholderText="Pilih Tanggal Batas Waktu"
              startDate={
                values.tgl_batas_waktu_mulai
                  ? new Date(values.tgl_batas_waktu_mulai)
                  : ""
              }
              endDate={
                values.tgl_batas_waktu_selesai
                  ? new Date(values.tgl_batas_waktu_selesai)
                  : ""
              }
              onChange={(dates) =>
                onTanggalChange(
                  dates,
                  values,
                  setValues,
                  "tgl_batas_waktu_mulai",
                  "tgl_batas_waktu_selesai"
                )
              }
              monthsShown={2}
            />
          )} */}
          <SelectSearch
            key="1"
            label="Customer"
            placeholder="Pilih Customer"
            defaultValue={dropdownFilter.customer.find(
              (val) => val.value === values.customer
            )}
            option={dropdownFilter.customer}
            onChange={(val) => setFieldValue("customer", val.value)}
            loading={loading}
          />

          <SelectSearch
            key="2"
            label="Sales"
            placeholder="Pilih Sales"
            defaultValue={dropdownFilter.sales.find(
              (val) => val.value === values.sales
            )}
            option={dropdownFilter.sales}
            onChange={(val) => setFieldValue("sales", val.value)}
            loading={loading}
          />

          {/* <SelectSearch 
						key="4"
						label="Gudang"
						placeholder="Pilih Gudang"
						defaultValue={dropdownFilter.gudang.find(val => val.value === values.gudang)}
						option={dropdownFilter.gudang}
						onChange={val => setFieldValue('gudang', val.value)}
						loading={loading}
					/> */}

          {isDO && (
            <SelectSearch
              key="3"
              label="Status Approval"
              placeholder="Pilih Status Approval"
              defaultValue={dropdownFilter.status_approval.find(
                (val) => val.value === values.status_approval
              )}
              option={dropdownFilter.status_approval}
              onChange={(val) => setFieldValue("status_approval", val.value)}
              loading={loading}
            />
          )}
        </FilterModal>
      )}
    </Formik>
  );
};
export default ModalFilter;
