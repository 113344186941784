// React
import { useEffect, useState } from "react";

// API
import { TransferBahanJobMixApi } from "api";

// Components
import {
  ActionButton,
  Alert,
  BackButton,
  DataStatus,
  ReadButton,
  Table,
  TBody,
  Td,
  TdFixed,
  Th,
  THead,
  ThFixed,
  Tr,
} from "components";
import { Card, Row, Col } from "react-bootstrap";
import { DateConvert, DecimalConvert } from "utilities";

// Router
import { useHistory, useLocation, useParams } from "react-router-dom";

// View Components
import { List, TransferModal } from "./components";
import { Divider } from "./components/modals/DetailProduksi/components";
import { IoAddOutline, IoCheckmarkOutline } from "react-icons/io5";
import axios from "axios";

const TambahTransferBahanJobMix = ({ setNavbarTitle }) => {
  // Variables
  const title = "Transfer Bahan Produksi";

  // Hooks
  const location = useLocation();
  const { id } = useParams();
  const history = useHistory();

  // States
  const [page, setPage] = useState({
    loading: true,
    status: false,
  });
  const [data, setData] = useState({});
  const [dataHistory, setDataHistory] = useState({});
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState({
    data: {},
    isCreate: false,
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  useEffect(() => {
    setNavbarTitle(title);
    getData();

    // eslint-disable-next-line
  }, []);

  const getData = () => {
    setPage({
      loading: true,
      status: false,
    });

    axios
      .all([
        TransferBahanJobMixApi.produksi_single(id),
        TransferBahanJobMixApi.histori(id),
      ])
      .then(
        axios.spread((res, histori) => {
          setData(res.data.data);
          setDataHistory(histori.data.data);

          setPage({
            loading: false,
            status: true,
          });
        })
      )
      .catch(() => {
        setPage({
          loading: false,
          status: false,
        });
      });
  };

  const toggleModal = () => setModal(!modal);
  const toggleSuccess = () => {
    setAlertConfig({
      show: true,
      text: "Tambah data berhasil",
      variant: "primary",
    });
    getData();
  };

  const getLastQtyTransfer = data?.permintaan
    ?.filter(
      (val) =>
        parseFloat(val.qty_transfer ?? 0) <
        parseFloat(val.qty_permintaan_produksi ?? 0)
    )
    .reduce((acc, curr) => {
      const totalTransfer = acc + parseFloat(curr?.qty_transfer ?? 0);
      const totalPermintaan =
        acc + parseFloat(curr?.qty_permintaan_produksi ?? 0);

      return {
        qty_transfer: totalTransfer,
        qty_permintaan_produksi: totalPermintaan,
      };
    }, 0);

  return (
    <>
      <div className="text-right">
        <BackButton
          onClick={() =>
            history.push(
              "/inventory/transaksi/transfer-bahan-job-mix/list-permintaan-bahan",
              location.state
            )
          }
        />
      </div>

      <b>Tambah Data {title}</b>

      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => {
          setAlertConfig({
            ...alertConfig,
            show: false,
          });
        }}
      />

      {page.loading || !page.status ? (
        <DataStatus
          loading={page.loading}
          text={
            page.loading
              ? "Memuat data..."
              : "Data gagal dimuat, tidak dapat menampilkan data"
          }
        />
      ) : (
        <>
          <Divider>
            <Card>
              <Card.Body>
                <Row>
                  <Col md>
                    <List label="Tgl. Produksi">
                      {data.tgl_jobmix
                        ? DateConvert(new Date(data.tgl_jobmix)).detail
                        : "-"}
                    </List>
                    <List label="No. Produksi">{data.no_jobmix ?? "-"}</List>
                    <List label="Proyek">{data.nama_proyek ?? "-"}</List>
                  </Col>

                  <Col md>
                    <List label="Kode Barang">{data.kode_item ?? "-"}</List>
                    <List label="Nama Barang">
                      {data.nama_item ?? "-"} [{data?.nama_varian ?? ""}]
                    </List>
                    <List label="Qty Produksi">
                      {DecimalConvert(data.qty_jobmix ?? 0).getWithComa}{" "}
                      {data.kode_satuan ?? ""}
                    </List>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Divider>

          <Divider>
            <Card>
              <Card.Body>
                <small>List Data Permintaan Produksi</small>
                <Table>
                  <THead>
                    <Tr>
                      <ThFixed>No</ThFixed>
                      <ThFixed>Informasi Permintaan Bahan Produksi</ThFixed>
                      <Th>Item Bahan</Th>
                      <ThFixed>Satuan</ThFixed>
                      <ThFixed>Qty. Permintaan Produksi</ThFixed>
                      <ThFixed>Total Qty. Telah Ditransfer</ThFixed>
                      <ThFixed>Aksi</ThFixed>
                    </Tr>
                  </THead>

                  <TBody>
                    {data?.permintaan && data.permintaan.length > 0 ? (
                      data.permintaan.map((val, index) => {
                        // Variables
                        const qty_permintaan_produksi = parseFloat(
                          val.qty_permintaan_produksi ?? 0
                        );
                        const qty_transfer = parseFloat(val.qty_transfer ?? 0);
                        const notAvailable = Boolean(
                          qty_transfer >= qty_permintaan_produksi
                        );

                        return (
                          <Tr key={index}>
                            <TdFixed>{index + 1}</TdFixed>
                            <Td>
                              <div>
                                {val.tgl_permintaan_produksi_bahan
                                  ? DateConvert(
                                      new Date(
                                        val.tgl_permintaan_produksi_bahan
                                      )
                                    ).defaultDMY
                                  : "-"}
                              </div>
                              <div>
                                {val.no_permintaan_produksi_bahan ?? "-"}
                              </div>
                            </Td>
                            <Td>{val.nama_item ?? "-"}</Td>
                            <Td>{val.kode_satuan ?? "-"}</Td>
                            <Td className="text-right">
                              {
                                DecimalConvert(qty_permintaan_produksi)
                                  .getWithComa
                              }
                            </Td>
                            <Td className="text-right">
                              {DecimalConvert(qty_transfer).getWithComa}
                            </Td>
                            <TdFixed>
                              <ActionButton
                                text={
                                  notAvailable ? (
                                    <IoCheckmarkOutline size={12} />
                                  ) : (
                                    <IoAddOutline size={12} />
                                  )
                                }
                                variant={notAvailable ? "success" : "primary"}
                                className="my-1"
                                size="sm"
                                onClick={() => {
                                  if (!notAvailable) {
                                    setModalData({
                                      data: val,
                                      isCreate: true,
                                    });
                                    toggleModal();
                                  } else {
                                    return null;
                                  }
                                }}
                              />
                            </TdFixed>
                          </Tr>
                        );
                      })
                    ) : (
                      <Tr>
                        <Td colSpan={8}>
                          <DataStatus text="Tidak ada data" />
                        </Td>
                      </Tr>
                    )}
                  </TBody>
                </Table>

                <small>History {title}</small>
                <Table>
                  <THead>
                    <Tr>
                      <ThFixed>No</ThFixed>
                      <ThFixed>Informasi Transfer Bahan Produksi</ThFixed>
                      <ThFixed>Informasi Permintaan Bahan Produksi</ThFixed>
                      <Th>Item Bahan</Th>
                      <Th>Gudang Asal</Th>
                      <Th>Gudang Tujuan</Th>
                      <ThFixed>Qty. Transfer</ThFixed>
                      <Th>Diserahkan Oleh</Th>
                      <ThFixed>Aksi</ThFixed>
                    </Tr>
                  </THead>

                  <TBody>
                    {dataHistory && dataHistory.length > 0 ? (
                      dataHistory.map((val, index) => {
                        return (
                          <Tr key={index}>
                            <TdFixed>{index + 1}</TdFixed>
                            <Td>
                              <div>
                                {val.tgl_transfer_produksi_bahan
                                  ? DateConvert(
                                      new Date(val.tgl_transfer_produksi_bahan)
                                    ).defaultDMY
                                  : "-"}
                              </div>
                              <div>{val.no_transfer_produksi_bahan ?? "-"}</div>
                            </Td>
                            <Td>
                              <div>
                                {val.tgl_permintaan_produksi_bahan
                                  ? DateConvert(
                                      new Date(
                                        val.tgl_permintaan_produksi_bahan
                                      )
                                    ).defaultDMY
                                  : "-"}
                              </div>
                              <div>
                                {val.no_permintaan_produksi_bahan ?? "-"}
                              </div>
                            </Td>
                            <Td>{val.nama_item ?? "-"}</Td>
                            <Td>{val.nama_gudang_asal ?? "-"}</Td>
                            <Td>{val.nama_gudang_tujuan ?? "-"}</Td>
                            <Td className="text-right">
                              {
                                DecimalConvert(val.qty_transfer ?? 0)
                                  .getWithComa
                              }
                            </Td>
                            <Td>{val.nama_karyawan ?? "-"}</Td>
                            <TdFixed>
                              <ReadButton
                                onClick={() => {
                                  setModalData({
                                    data: val,
                                    isCreate: false,
                                  });
                                  toggleModal();
                                }}
                              />
                            </TdFixed>
                          </Tr>
                        );
                      })
                    ) : (
                      <Tr>
                        <Td colSpan={10}>
                          <DataStatus text="Tidak ada data" />
                        </Td>
                      </Tr>
                    )}
                  </TBody>
                </Table>
              </Card.Body>
            </Card>
          </Divider>
        </>
      )}

      {modal && (
        <TransferModal
          data={modalData.data}
          toggle={toggleModal}
          title={title}
          isCreate={modalData.isCreate}
          toggleSuccess={toggleSuccess}
          getLastQtyTransfer={getLastQtyTransfer}
        />
      )}
    </>
  );
};

export default TambahTransferBahanJobMix;
