import Services from "services"

class TransferBahanJobMixApi {
    page(params) {
        return Services.get("/transfer_produksi_bahan/page", { params })
    }

    single(params) {
        return Services.get(`/transfer_produksi_bahan/single`, { params })
    }

    produksi_page(params) {
        return Services.get("/transfer_produksi_bahan/produksi_page", { params })
    }

    produksi_single(value) {
        return Services.get(`/transfer_produksi_bahan/produksi_single?id_jobmix=${value}`)
    }

    jobmix_single(value) {
        return Services.get(`/transfer_produksi_bahan/jobmix_single?id_jobmix=${value}`)
    }

    histori(value) {
        return Services.get(`/transfer_produksi_bahan/histori?id_jobmix=${value}`)
    }

    getSinglePermintaan(params) {
        return Services.get("/transfer_produksi_bahan/permintaan_single", { params })
    }

    no_baru(value) {
        return Services.get(`/transfer_produksi_bahan/no_baru?tanggal=${value}`)
    }

    getDropdownGudang(params) {
        return Services.get("/dropdown/gudang", { params })
    }

    getDropdownBahan() {
        return Services.get("/dropdown/bahan")
    }

    getDropdownProduksi() {
        return Services.get("/dropdown/item_produksi")
    }

    getDropdownKaryawan() {
        return Services.get("/dropdown/karyawan")
    }

    save(data) {
        return Services.post("/transfer_produksi_bahan", data)
    }
}

export default new TransferBahanJobMixApi();
