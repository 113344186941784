import Axios from "axios"
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from "draft-js"
import draftToHtml from "draftjs-to-html"
import { useFormik } from "formik"
import { useEffect, useState } from "react"
import { Button, Card, Col, Modal, Row } from "react-bootstrap"
import { useHistory, useLocation } from "react-router-dom"
import * as Yup from "yup"
import { PurchaseRequestApi } from "../../../../api"
import {
  ActionButton,
  BackButton,
  CRUDLayout,
  DataStatus,
  DeleteButton,
  Input,
  InputQtyDecimal,
  Select,
  SelectSearch,
  TBody,
  Td,
  TdFixed,
  TextEditor,
  Th,
  THead,
  ThFixed,
  Tr
} from "../../../../components"

const TambahPurchaseRequest = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const [createModal, setCreateModal] = useState(false);

  // data
  const [dataNo, setDataNo] = useState("");
  const [list, setList] = useState([]);
  const [dataKelompok, setDataKelompok] = useState([]);
  const [dataProyek, setDataProyek] = useState([]);

  // STATE TEXT EDITOR
  const [textEditorState, setTextEditorState] = useState(() => {
    return EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML("<p></p>"))
    );
  });
  const updateEditorState = (value, setFieldValue, editor) => {
    editor === "catatan_purchase_request" && setTextEditorState(value);
    const convertData = convertToRaw(value.getCurrentContent());
    setFieldValue(editor, draftToHtml(convertData));
  };

  const [jenis, setJenis] = useState([]);
  const [item, setItem] = useState([]);
  const [isLoading, setIsLoading] = useState({
    jenis: false,
    item: false,
  });

  // menampilkan alert
  const [showAlert, setShowAlert] = useState(false);
  // configurasi alert
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
    status: false,
  });

  // request data dari server
  const getData = () => {
    setIsLoading(true);
    Axios.all([
      PurchaseRequestApi.getKelompok(),
      PurchaseRequestApi.getProyek(),
    ])
      .then(
        Axios.spread((kelompok, proyek) => {
          setDataKelompok(kelompok.data.data);
          setDataProyek(proyek.data.data);
        })
      )
      .catch(() => {
        setAlertConfig({
          variant: "danger",
          text: "Data Kelompok gagal dimuat",
        });
        setShowAlert(true);
      })
      .finally(() => setIsLoading(false));
  };

  const getJenis = (id) => {
    setIsLoading({
      jenis: true,
      item: true,
    });

    PurchaseRequestApi.getJenis(id)
      .then((res) => setJenis(res.data.data))
      .catch((err) => {
        setAlertConfig({
          variant: "danger",
          text: "Data Jenis Tidak Ada",
        });
        setShowAlert(true);
      })
      .finally(
        () =>
          setIsLoading({
            jenis: false,
            item: false,
          }),
        setShowAlert(false)
      );
  };

  const getItem = (id) => {
    setIsLoading({
      item: true,
    });
    PurchaseRequestApi.getItem(id)
      .then((res) => setItem(res.data.data))
      .catch((err) => {
        setAlertConfig({
          variant: "danger",
          text: "Data Item Tidak Ada",
        });
        setShowAlert(true);
        setItem([]);
      })
      .finally(
        () =>
          setIsLoading({
            item: false,
          }),
        setShowAlert(false)
      );
  };

  const getNomor = (id) => {
    const date = Date.parse(id);

    if (isNaN(date) == false) {
      var dataDate = new Date(date)
        .toISOString()
        .split("T")[0]
        .split("-")
        .join("/");
      PurchaseRequestApi.getNoSurat(dataDate)
        .then((res) => {
          setDataNo(res.data.data);
        })
        .catch((err) => alert(err));
    }
  };

  const getList = (id, kode, nama, kelompok, jenis, satuan, qty) => {
    if (id != null && qty != null) {
      setList([
        ...list,
        {
          id_item_buaso: id,
          kode_item: kode,
          nama_item: nama,
          nama_kelompok: kelompok,
          nama_jenis: jenis,
          satuan_beli: satuan,
          qty: qty,
        },
      ]);
    } else {
      alert("Item dan Qty tidak boleh kosong");
    }
  };

  const deleteList = (index) => {
    const array = [...list];
    array.splice(index, 1);
    setList(array);
  };

  useEffect(() => {
    // set Judul di Navbar
    setNavbarTitle("Purchase Request");

    // jalankan function request data ke server
    getData();

    return () => {
      setIsLoading(false);
    };
  }, [setNavbarTitle]);

  // KIRIM DATA BARU KE SERVER
  const decimalConvert = (value) => {
    const convertToNumber = parseFloat(value);
    const checkConvert =
      convertToNumber === NaN ? 0 : parseFloat(String(convertToNumber));

    return new Intl.NumberFormat("id-ID").format(checkConvert);
  };

  const PageContent = () => {
    return (
      <>
        <CRUDLayout>
          <CRUDLayout.Head>
            <CRUDLayout.HeadButtonSection>
              <ActionButton
                className="mt-3"
                text="TAMBAH BARANG"
                onClick={() => setCreateModal(true)}
              />
            </CRUDLayout.HeadButtonSection>
          </CRUDLayout.Head>
        </CRUDLayout>
        <span style={{ fontSize: "14px" }}>
          <b>List Item Barang Purchase Request</b>
        </span>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <ThFixed>Kode Barang</ThFixed>
              <Th style={{ minWidth: "300px" }}>Item Barang</Th>
              <Th style={{ minWidth: "200px" }}>Kelompok Barang</Th>
              <Th style={{ minWidth: "200px" }}>Jenis Barang</Th>
              <Th>Satuan Beli</Th>
              <Th>Qty. Beli</Th>
              <ThFixed>Aksi</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {list.length > 0 ? (
              list.map((val, index) => (
                <Tr key={index}>
                  <TdFixed>{index + 1}</TdFixed>
                  <TdFixed>{val.kode_item}</TdFixed>
                  <Td>{val.nama_item}</Td>
                  <Td>{val.nama_kelompok ?? "-"}</Td>
                  <Td>{val.nama_jenis ?? "-"}</Td>
                  <Td>{val.satuan_beli}</Td>
                  <Td>
                    <div className="text-right">
                      {decimalConvert(parseFloat(val.qty))}
                    </div>
                  </Td>
                  <TdFixed>
                    <DeleteButton
                      onClick={() => {
                        deleteList(index);
                      }}
                    />
                  </TdFixed>
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan={8}>
                  <div className="text-center p-3">
                    <b>Tidak Ada Data</b>
                  </div>
                </Td>
              </Tr>
            )}
          </TBody>
        </CRUDLayout.Table>
      </>
    );
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    setFieldValue,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      no_purchase_request: "",
      tgl_purchase_request: "",
      keperluan: "",
      id_proyek: "",
      catatan_purchase_request:
        "<p>Dengan hormat,<br />Bersama ini kami sampaikan penawaran harga sebagai berikut :</p>",
      tgl_pemakaian: "",
    },
    validationSchema: Yup.object().shape({
      tgl_purchase_request: Yup.date().required(
        "Masukkan Tanggal Purchase Request"
      ),
      tgl_pemakaian: Yup.date().required("Masukan Tanggal Pemakaian"),
      keperluan: Yup.string().required("Pilih Keperluan"),
      id_proyek: Yup.string().required("Pilih Proyek"),
      catatan_purchase_request: Yup.string(),
    }),
    onSubmit: (values) => {
      if (list.length > 0) {
        const finalValues = {
          ...values,
          detail: list,
        };

        PurchaseRequestApi.create(finalValues)
          .then(() => {
            history.push("/inventory/transaksi/purchase-request", {
              variant: "primary",
              text: "Purchase Request berhasil ditambah",
              status: true,
            });
          })
          .catch((err) => {
            setAlertConfig({
              variant: "danger",
              text: `Tambah Purchase Request gagal! (${err.response.data.message})`,
            });
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        setShowAlert(true);
        setAlertConfig({
          variant: "danger",
          text: "List Item Purchase Request wajib diisi minimal 1",
        });
      }
    },
  });

  return (
    <>
      <CRUDLayout>
        <CRUDLayout.Head>
          <CRUDLayout.HeadButtonSection>
            <BackButton
              onClick={() => {
                history.push("/inventory/transaksi/purchase-request", {
                  ...location?.state,
                });
              }}
            />
          </CRUDLayout.HeadButtonSection>
        </CRUDLayout.Head>
      </CRUDLayout>
      <div>
        <span style={{ fontSize: "14px" }}>
          <b>Tambah Data Purchase Request</b>
        </span>
      </div>
      <Card>
        <Card.Body>
          <Row>
            <Col md={6}>
              <div className="d-flex">
                <div className="mr-3" style={{ width: "155px" }}>
                  <Input
                    label="Tanggal Purchase Request"
                    type="date"
                    name="tgl_purchase_request"
                    onChange={(e) => {
                      const value = e.target.value;
                      setFieldValue("tgl_purchase_request", value);
                      setFieldValue("no_purchase_request", getNomor(value));
                    }}
                    onBlur={() => {
                      setFieldValue("no_purchase_request", dataNo);
                    }}
                    error={
                      errors.tgl_purchase_request &&
                      touched.tgl_purchase_request &&
                      true
                    }
                    errorText={errors.tgl_purchase_request}
                  />
                </div>
                <div style={{ width: "300px" }}>
                  <Input
                    label="No. Purchase Request"
                    name="no_purchase_request"
                    value={values.no_purchase_request}
                    readOnly={true}
                  />
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="d-flex">
                <div className="mr-3" style={{ width: "155px" }}>
                  <Input
                    label="Tanggal Pemakaian"
                    type="date"
                    name="tgl_pemakaian"
                    onChange={handleChange}
                    error={
                      errors.tgl_pemakaian && touched.tgl_pemakaian && true
                    }
                    errorText={errors.tgl_pemakaian}
                  />
                </div>
                <div style={{ width: "300px" }}>
                  <Select
                    label="Keperluan"
                    name="keperluan"
                    onChange={(e) => {
                      const value = e.target.value;
                      setFieldValue("keperluan", value);
                    }}
                    error={errors.keperluan && touched.keperluan && true}
                    errorText={errors.keperluan}
                  >
                    <option value="" hidden>Pilih Keperluan</option>
                    <option value="stok">Stok</option>
                    <option value="produksi">Produksi</option>
                    <option value="biaya angkut">Biaya Angkut</option>
                    <option value="pengadaan barang aset">Pengadaan Barang Aset</option>
                    <option value="pengadaan barang non aset">Pengadaan Barang Non Aset</option>
                    <option value="perbaikan dan pemeliharaan alat & mesin">Perbaikan dan Pemeliharaan Alat & Mesin</option>
                    <option value="perawatan gedung dan kantor">Perawatan Gendung dan Kantor</option>
                  </Select>
                </div>
                <div style={{ width: "300px" }} className="ml-3">
                  <SelectSearch
                    label="Proyek"
                    name="id_proyek"
                    placeholder="Pilih Proyek"
                    onChange={(val) => {
                      setFieldValue("id_proyek", val.value);
                    }}
                    option={dataProyek.map((val) => {
                      return {
                        value: val.id_proyek,
                        label: val.nama_proyek,
                      };
                    })}
                    error={Boolean(errors.id_proyek) && touched.id_proyek}
                    errorText={
                      Boolean(errors.id_proyek) &&
                      touched.id_proyek &&
                      errors.id_proyek
                    }
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Modal
            show={createModal}
            onHide={() => setCreateModal(false)}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <small>Tambah Barang</small>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <SelectSearch
                label="Kelompok Barang"
                name="id_kelompok"
                placeholder="Pilih Kelompok Barang"
                onChange={(val) => {
                  setFieldValue("id_kelompok", val.value);
                  setFieldValue("id_jenis", "");
                  setFieldValue("id_item_buaso", "");
                  setFieldValue("nama_kelompok", val.label);
                  getJenis(val.value);
                }}
                option={dataKelompok.map((val) => {
                  return {
                    value: val.id_kelompok,
                    label: val.nama_kelompok,
                  };
                })}
              />
              <SelectSearch
                label="Jenis Barang"
                name="id_jenis"
                placeholder="Pilih Jenis Barang"
                onChange={(val) => {
                  setFieldValue("id_jenis", val.value);
                  setFieldValue("id_item_buaso", "");
                  setFieldValue("nama_jenis", val.label);
                  getItem(val.value);
                }}
                option={jenis.map((val) => {
                  return {
                    value: val.id_jenis,
                    label: val.nama_jenis,
                  };
                })}
                loading={isLoading.jenis}
                isDisabled={values?.id_kelompok ? false : true}
              />
              <SelectSearch
                label="Item Barang"
                name="id_item"
                placeholder="Pilih Item Barang"
                onChange={(val) => {
                  setFieldValue("id_item", val.value);
                  setFieldValue("nama_satuan", val.satuan_beli);
                  setFieldValue("kode_item", val.kode_item);
                  setFieldValue("nama_item", val.label);
                }}
                option={item.map((val) => {
                  return {
                    value: val.id_item_buaso,
                    label: `${val.kode_item} | ${val.nama_item}`,
                    satuan_beli: val.satuan_beli,
                    kode_item: val.kode_item,
                  };
                })}
                loading={isLoading.item}
                isDisabled={values?.id_jenis ? false : true}
              />
              <Row>
                <Col md="6">
                  {/* <Input
                    label="Qty. Beli"
                    type="text"
                    name="qty"
                    value={values?.qty}
                    onChange={(e) => {
                      const value = e.target.value;
                      const convert = value;
                      setFieldValue("qty", convert);
                    }}
                  /> */}
                  <InputQtyDecimal
                    label="Qty. Beli"
                    value={parseFloat(values?.qty)}
                    onChange={(e) => setFieldValue("qty", e)}
                  />
                </Col>
                <Col md="6">
                  <Input
                    label="Satuan"
                    name="nama_satuan"
                    value={values?.nama_satuan}
                    readOnly={true}
                  />
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setCreateModal(false)}>
                Batal
              </Button>
              <Button
                variant="primary"
                onClick={(e) => {
                  e.preventDefault();
                  getList(
                    values.id_item,
                    values.kode_item,
                    values.nama_item,
                    values.nama_kelompok,
                    values.nama_jenis,
                    values.nama_satuan,
                    values.qty
                  );
                  setCreateModal(false);
                }}
              >
                Tambah
              </Button>
            </Modal.Footer>
          </Modal>
        </Card.Body>
      </Card>
      {isLoading === true ? (
        <DataStatus loading={true} text="Memuat data..." />
      ) : (
        <PageContent />
      )}
      <TextEditor
        label={
          <span style={{ fontSize: "14px" }}>
            <b>Catatan Purchase Request</b>
          </span>
        }
        placeholder="Tuliskan Catatan Purchase Request"
        editorState={textEditorState}
        onEditorStateChange={(val) => {
          updateEditorState(val, setFieldValue, "catatan_purchase_request");
        }}
        error={
          errors.catatan_purchase_request &&
          touched.catatan_purchase_request &&
          true
        }
        errorText={
          errors.catatan_purchase_request &&
          touched.catatan_purchase_request &&
          errors.catatan_purchase_request
        }
      />
      <div className="text-right">
        <ActionButton
          className="mt-3"
          text="SIMPAN"
          loading={isSubmitting}
          onClick={handleSubmit}
        />
      </div>
    </>
  );
};

export default TambahPurchaseRequest;

