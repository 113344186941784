// Components
import { DateConvert, DecimalConvert } from "utilities";

// Form
import { useFormikContext } from "formik";

// View Components
import List from "../List";

const InfoSection = () => {
  // Variables
  const { values } = useFormikContext();

  return (
    <table>
      <tbody>
        <List label="Tgl. Permintaan Produksi">
          {values.tgl_permintaan_produksi_bahan
            ? DateConvert(new Date(values.tgl_permintaan_produksi_bahan)).detail
            : "-"}
        </List>
        <List label="No. Permintaan Produksi">
          {values.no_permintaan_produksi_bahan ?? "-"}
        </List>
        <List label="Proyek">{values.nama_proyek ?? "-"}</List>
        <List label="Item Bahan">
          {values.nama_item ?? "-"} [{values.nama_varian ?? ""}]
        </List>
        <List label="Satuan">{values.kode_satuan ?? "-"}</List>
        <List label="Qty. Permintaan Produksi">
          {DecimalConvert(values.qty_permintaan_produksi ?? 0).getWithComa}
        </List>
        <List label="Total Qty. Telah Ditransfer">
          {DecimalConvert(values.qty_telah_ditransfer ?? 0).getWithComa}
        </List>
      </tbody>
    </table>
  );
};

export default InfoSection;
