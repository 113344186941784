import Services from "../../../services";

class PengembalianBarangApi {
  getPage(page, dataLength, key) {
    return Services.get(`/pengembalian_barang/page/?page=${page}&per_page=${dataLength}&q=${key}`);
  }
  search(key) {
    return Services.get(`/pengembalian_barang/page/?q=${key}`);
  }
  getFaktur(page, dataLength, key) {
    return Services.get(
      `/pengembalian_barang/faktur_penjualan_batal/page/?page=${page}&per_page=${dataLength}&q=${key}`
    );
  }
  searchFaktur(key) {
    return Services.get(`/pengembalian_barang/faktur_penjualan_batal/page/?q=${key}`);
  }
  getForm(id) {
    return Services.get(`/pengembalian_barang/faktur_detail?id_delivery_order=${id}`);
  }
  getFormEdit(id) {
    return Services.get(`/pengembalian_barang/single?id_pengembalian_barang=${id}`);
  }
  getKode(value) {
    return Services.get(`/pengembalian_barang/no_baru?tanggal=${value}`);
  }
  create(data) {
    return Services.post("/pengembalian_barang", data);
  }
  update(data) {
    return Services.put("/pengembalian_barang", data);
  }
  delete(id) {
    return Services.post("/pengembalian_barang/delete", id);
  }
}

export default new PengembalianBarangApi();
