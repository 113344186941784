import React, { useState, useEffect } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import Axios from "axios";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Th,
  Tr,
  Td,
  TBody,
  THead,
  Alert,
  ThFixed,
  TdFixed,
  DataStatus,
  CRUDLayout,
  Pagination,
  CreateModal,
  InputSearch,
  ActionButton,
  DatePicker,
  SelectSearch,
  Input,
  InputCurrency,
  FilterButton,
} from "components";
import {
  TableNumber,
  RupiahConvert,
  DateConvert,
  DecimalConvert,
} from "utilities";
import { SetupStokAwalApi } from "api";
import { ModalFilterSetupStokAwal } from "./components";

function SetupStokAwal({ setNavbarTitle }) {
  const [dataStok, setDataStok] = useState([]);
  const [dropdownItemBahan, setDropdownItemBahan] = useState([]);
  const [dropdownGudang, setDropdownGudang] = useState([]);
  const [dropdownLoading, setDropdownLoading] = useState(false);
  const [fetchConfig, setFetchConfig] = useState({
    loading: true,
    success: false,
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  });
  const [modalFilter, setModalFilter] = useState(false);
  const [dataFilter, setDataFilter] = useState({
    active: false,
    id_jenis: undefined,
    id_kelompok: undefined,
    id_barang: undefined,
    id_gudang: undefined,
  });
  const [paginationConfig, setPaginationConfig] = useState({
    currentPage: 1,
    dataLength: 10,
    dataCount: 0,
    totalPage: 1,
  });
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: "create",
    title: "Setup Stok Awal",
    data: {},
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "",
    text: "",
  });

  //menampilkan alert
  const [showAlert, setShowAlert] = useState(false);
  const [searchTime, setSearchTime] = useState(null);

  const toggleModalFilter = () => setModalFilter(!modalFilter);

  const getInitialData = () => {
    setFetchConfig({ ...fetchConfig, loading: true });
    setDropdownLoading(true);

    Axios.all([
      SetupStokAwalApi.getPage({
        page: paginationConfig.currentPage,
        per_page: paginationConfig.dataLength,
        q: searchConfig.key,
        ...dataFilter,
      }),
      SetupStokAwalApi.getDropdownBahan(),
      SetupStokAwalApi.getDropdownGudang(),
    ])
      .then(
        Axios.spread((res, bahan, gudang) => {
          setDataStok(res?.data?.data ?? []);

          setDropdownItemBahan(bahan?.data?.data ?? []);
          setDropdownGudang(gudang?.data?.data ?? []);

          setPaginationConfig({
            ...paginationConfig,
            dataCount: res?.data?.data_count ?? 0,
            totalPage: res?.data?.total_page ?? 1,
          });
          setFetchConfig({
            loading: false,
            success: true,
          });
          setDropdownLoading(false);
        })
      )
      .catch(() => {
        setFetchConfig({
          loading: false,
          success: false,
        });
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
        setDropdownLoading(false);
      });
  };

  //input search
  const onInputSearch = (text) => {
    const key = text.target.value;

    if (searchTime) clearTimeout(searchTime);
    setSearchTime(
      setTimeout(() => {
        setSearchConfig({
          ...searchConfig,
          key: key,
        });
        setAlertConfig({
          variant: "primary",
          text: `hasil pencarian : ${key}`,
        });
        setShowAlert(true);
      }, 750)
    );
  };

  useEffect(() => {
    setNavbarTitle("Setup Stok Awal");
    getInitialData();
  }, [
    setNavbarTitle,
    dataFilter.active,
    dataFilter.id_jenis,
    dataFilter.id_kelompok,
    dataFilter.id_barang,
    dataFilter.id_gudang,
    paginationConfig.currentPage,
    paginationConfig.dataLength,
    searchConfig.key,
  ]);

  const formInitialData = {
    id_barang: "",
    id_gudang: "",
    jumlah: "",
    id_satuan: "",
    tanggal_mutasi: "",
    harga: "",
  };

  const formValidationSchema = Yup.object().shape({
    id_barang: Yup.string().required("Pilih item bahan"),
    id_gudang: Yup.string().required("Masukan nama gudang"),
    jumlah: Yup.string().required("Masukan jumlah"),
    tanggal_mutasi: Yup.string().required("Masukan tanggal"),
    harga: Yup.string().required("Masukan harga satuan"),
  });

  const formSubmitHandler = (values) => {
    SetupStokAwalApi.create(values)
      .then(() => {
        setAlertConfig({
          show: true,
          variant: "primary",
          text: "Tambah data berhasil!",
        });
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Tambah data gagal!",
        });
      })
      .finally(() => {
        setModalConfig({
          show: false,
          type: "create",
        });
        getInitialData();
      });
  };

  const ModalCreate = () => (
    <CreateModal
      show={modalConfig.type === "create" ? modalConfig.show : ""}
      onHide={() => setModalConfig({ show: false })}
      type={() => setModalConfig({ type: "create" })}
      title="Setup Stok Awal"
    >
      <Formik
        initialValues={formInitialData}
        validationSchema={formValidationSchema}
        onSubmit={formSubmitHandler}
      >
        {(formik) => {
          const {
            values,
            errors,
            touched,
            isSubmitting,
            handleChange,
            handleSubmit,
            setFieldValue,
            setValues,
          } = formik;

          return (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <DatePicker
                  label="Tgl. Setup Stok Awal"
                  type="date"
                  name="tanggal_mutasi"
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Masukkan tanggal"
                  autoComplete="off"
                  selected={
                    values.tanggal_mutasi ? new Date(values.tanggal_mutasi) : ""
                  }
                  onChange={(date) => {
                    const value = DateConvert(new Date(date)).default;
                    setFieldValue("tanggal_mutasi", value);
                  }}
                  error={
                    errors.tanggal_mutasi && touched.tanggal_mutasi && true
                  }
                  errorText={
                    errors.tanggal_mutasi &&
                    touched.tanggal_mutasi &&
                    errors.tanggal_mutasi
                  }
                />

                <SelectSearch
                  label="Item Bahan"
                  type="select"
                  placeholder="Pilih salah satu..."
                  name="id_barang"
                  option={dropdownItemBahan.map((val) => {
                    return {
                      value: val.id_item_buaso,
                      label: `${val.kode_item} | ${val.nama_item}`,
                      id_satuan_pakai: val.id_satuan_pakai,
                      satuan_pakai: val.satuan_pakai,
                    };
                  })}
                  onChange={(val) => {
                    setValues({
                      ...values,
                      id_barang: val.value,
                      id_satuan: val.id_satuan_pakai,
                      nama_satuan: val.satuan_pakai,
                    });
                  }}
                  error={errors.id_barang && touched.id_barang && true}
                  errorText={
                    errors.id_barang && touched.id_barang && errors.id_barang
                  }
                />

                <Row>
                  <Col>
                    <Input
                      label="Qty."
                      type="number"
                      name="jumlah"
                      placeholder="Masukkan jumlah"
                      value={values.jumlah}
                      onChange={handleChange}
                      error={errors.jumlah && touched.jumlah && true}
                      errorText={
                        errors.jumlah && touched.jumlah && errors.jumlah
                      }
                    />
                  </Col>

                  <Col>
                    <Input
                      key={values.id_item_buaso}
                      label="Satuan Pakai"
                      name="id_satuan"
                      readOnly={true}
                      loading={dropdownLoading}
                      value={values.nama_satuan}
                      onChange={(val) =>
                        setFieldValue("id_satuan", val.id_satuan)
                      }
                    />
                  </Col>
                </Row>

                <SelectSearch
                  label="Gudang"
                  type="select"
                  placeholder="Gudang Bahan Baku"
                  name="id_gudang"
                  option={dropdownGudang.map((val) =>
                    Object({ label: val.nama_gudang, value: val.id_gudang })
                  )}
                  onChange={(val) =>
                    setValues({ ...values, id_gudang: val.value })
                  }
                  error={errors.id_gudang && touched.id_gudang && true}
                  errorText={
                    errors.id_gudang && touched.id_gudang && errors.id_gudang
                  }
                />

                <InputCurrency
                  label="Harga Kesepakatan"
                  placeholder="Masukan harga kesepakatan"
                  value={parseFloat(values.harga)}
                  onChange={(e) => setFieldValue("harga", e)}
                  error={errors.harga && touched.harga && true}
                  errorText={errors.harga}
                />
              </Modal.Body>

              <Modal.Footer>
                <ActionButton
                  type="submit"
                  variant="primary"
                  text="Simpan"
                  className="mt-2 px-4"
                  loading={isSubmitting}
                />
              </Modal.Footer>
            </form>
          );
        }}
      </Formik>
    </CreateModal>
  );

  const Table = () => {
    const grandTotal = dataStok?.reduce(
      (total, { total_harga }) => total + parseFloat(total_harga ?? 0),
      0
    );
    return (
      <div>
        <div className="mb-1 mt-1">
          <span style={{ fontSize: "14px" }}>
            <b>List Data Setup Stok Awal</b>
          </span>
        </div>
        <CRUDLayout.Table>
          <THead>
            <Tr>
              <ThFixed>No</ThFixed>
              <Th>Tgl. Setup Stok Awal</Th>
              <Th>Item Bahan</Th>
              <Th>Kelompok</Th>
              <Th>Jenis</Th>
              {/* <Th>Qty. Beli</Th>
              <Th>Satuan Beli</Th> */}
              <Th>Qty. Pakai</Th>
              <Th>Satuan Pakai</Th>
              <Th>Gudang</Th>
              <Th>Harga Satuan</Th>
              <Th>Total Harga</Th>
            </Tr>
          </THead>
          <TBody>
            {dataStok.length === 0 ? (
              <Tr>
                <Td colSpan={12}>
                  <DataStatus text="Tidak ada data" />
                </Td>
              </Tr>
            ) : (
              dataStok.map((val, index) => (
                <Tr key={index}>
                  <TdFixed textCenter>
                    {TableNumber(
                      paginationConfig.currentPage,
                      paginationConfig.dataLength,
                      index
                    )}
                  </TdFixed>
                  <Td width="100px">
                    {val.tanggal_mutasi
                      ? DateConvert(new Date(val.tanggal_mutasi)).defaultDMY
                      : ""}
                  </Td>
                  <Td style={{ minWidth: "150px" }}>
                    {val.nama_barang ?? "-"}
                  </Td>
                  <Td width="60px">{val.nama_kelompok ?? "-"}</Td>
                  <Td width="60px">{val.nama_jenis ?? "-"}</Td>
                  {/* <Td width="60px" className="text-right">
                    {val.qty_beli ? parseFloat(val.qty_beli) : ""}
                  </Td>
                  <Td width="60px">{val.satuan_beli ?? "-"}</Td> */}
                  <Td width="60px" className="text-right">
                    {DecimalConvert(parseFloat(val.jumlah ?? 0)).getWithComa}
                  </Td>
                  <Td width="80px">{val.nama_satuan ?? "-"}</Td>
                  <Td width="180px">{val.nama_gudang ?? "-"}</Td>
                  <Td className="text-right">
                    {" "}
                    {
                      RupiahConvert(String(parseFloat(val.harga ?? 0)))
                        .getWithComa
                    }{" "}
                  </Td>
                  <Td className="text-right">
                    {" "}
                    {
                      RupiahConvert(String(parseFloat(val.total_harga ?? 0)))
                        .getWithComa
                    }{" "}
                  </Td>
                </Tr>
              ))
            )}

            {dataStok?.length > 0 && (
              <Tr>
                <Td colSpan={9}>
                  <div
                    className="p-2 font-weight-bold text-right"
                    style={{ fontSize: 14 }}
                  >
                    Grand Total
                  </div>
                </Td>
                <Td>
                  <div className="p-2 font-weight-bold text-right">
                    {" "}
                    {RupiahConvert(String(grandTotal ?? 0)).getWithComa}{" "}
                  </div>
                </Td>
              </Tr>
            )}
          </TBody>
        </CRUDLayout.Table>
        <Pagination
          dataLength={paginationConfig.dataLength}
          dataNumber={
            paginationConfig.currentPage * paginationConfig.dataLength -
            paginationConfig.dataLength +
            1
          }
          dataPage={
            paginationConfig.dataCount < paginationConfig.dataLength
              ? paginationConfig.dataCount
              : paginationConfig.currentPage * paginationConfig.dataLength
          }
          dataCount={paginationConfig.dataCount}
          currentPage={paginationConfig.currentPage}
          totalPage={paginationConfig.totalPage}
          onPaginationChange={({ selected }) =>
            setPaginationConfig({
              ...paginationConfig,
              currentPage: selected + 1,
            })
          }
          onDataLengthChange={(e) =>
            setPaginationConfig({
              ...paginationConfig,
              currentPage: 1,
              dataLength: e.target.value,
            })
          }
        />
      </div>
    );
  };

  //RETURN ERROR
  if (fetchConfig.loading || !fetchConfig.success) {
    return (
      <div>
        <CRUDLayout>
          <CRUDLayout.Head>
            <CRUDLayout.HeadSearchSection>
              <Row>
                <Col md={6} className="pr-0">
                  <InputSearch onChange={onInputSearch} />
                </Col>
                <Col md={4} className="p-0">
                  <FilterButton
                    active={dataFilter.active}
                    onClick={toggleModalFilter}
                  />
                </Col>
              </Row>
            </CRUDLayout.HeadSearchSection>
            <CRUDLayout.HeadButtonSection>
              <ActionButton
                text="Tambah Data"
                onClick={() => setModalConfig({ show: true, type: "create" })}
              />
            </CRUDLayout.HeadButtonSection>
          </CRUDLayout.Head>
        </CRUDLayout>
        <DataStatus
          loading={fetchConfig.loading}
          text={fetchConfig.loading ? "Memuat data. . ." : "Tidak ada data"}
        />
      </div>
    );
  }

  return (
    <div>
      <CRUDLayout>
        <CRUDLayout.Head>
          <CRUDLayout.HeadSearchSection>
            <Row>
              <Col md={6} className="pr-0">
                <InputSearch
                  onChange={onInputSearch}
                  onSubmit={(e) => e.preventDefault()}
                />
              </Col>
              <Col md={4} className="p-0">
                <FilterButton
                  active={dataFilter.active}
                  onClick={toggleModalFilter}
                />
              </Col>
            </Row>
          </CRUDLayout.HeadSearchSection>
          <CRUDLayout.HeadButtonSection>
            <ActionButton
              text="Tambah Data"
              onClick={() => setModalConfig({ show: true, type: "create" })}
            />
          </CRUDLayout.HeadButtonSection>
        </CRUDLayout.Head>
      </CRUDLayout>
      <Alert
        show={alertConfig.show}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setAlertConfig({ show: false })}
      />
      <Table />
      {modalConfig.show && modalConfig.type === "create" && <ModalCreate />}

      {modalFilter && (
        <ModalFilterSetupStokAwal
          toggleModal={toggleModalFilter}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}
    </div>
  );
}

export default SetupStokAwal;
