// React
import React, { useState, useEffect } from "react";

// Component
import {
  CRUDLayout,
  Input,
  InputSearch,
  CreateButton,
  ActionButton,
  DataStatus,
  CreateModal,
  UpdateModal,
  DeleteModal,
  UpdateButton,
  DeleteButton,
  Switch,
  Pagination,
  Select,
  Alert,
  THead,
  Tr,
  ThFixed,
  Th,
  TBody,
  TdFixed,
  Td,
  ListData,
  ReadButton,
  ReadModal,
  TextArea,
} from "../../../components";
import { PageNumber, TableNumber, HeadOfficeStatus } from "../../../utilities";
import { ButtonGroup, Col, Modal, Row } from "react-bootstrap";

// Form
import { Formik } from "formik";
import * as Yup from "yup";

// API
import Axios from "axios";
import { KelompokApi } from "../../../api";

const Kelompok = ({ setNavbarTitle }) => {
  // Title
  const title = "Kelompok Barang";

  // STATE LOADING
  const [isLoading, setIsLoading] = useState(true);

  // STATE SEARCHING
  const [isSearching, setIsSearching] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  // MODALS SHOW STATE
  const [isCreateForm, setIsCreateForm] = useState(false); // MODAL TAMBAH STATE
  const [isUpdateForm, setIsUpdateForm] = useState(false); // MODAL UPDATE STATE
  const [isReadForm, setIsReadForm] = useState(false); // MODAL DETAIL STATE
  const [isDeleteData, setIsDeleteData] = useState(false); // MODAL HAPUS STATE

  // STATE DATA KELOMPOK
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [dataLength, setDataLength] = useState(10);
  const [totalData, setTotalData] = useState("");
  // STATE MENAMPUNG DATA YANG AKAN DI UPDATE
  const [updateData, setUpdateData] = useState([]);
  // STATE MENAMPUNG DATA YANG AKAN DI HAPUS
  const [deleteData, setDeleteData] = useState([]);
  // STATE MENAMPUNG DATA BUASO
  const [dataBuaso, setDataBuaso] = useState([]);

  // ALERT STATE
  const [showAlert, setShowAlert] = useState(false);
  // KONFIGURASI ALERT
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  });

  const [searchTime, setSearchTime] = useState(null)

  const onInputSearch = (text) => {
    const key = text.target.value
    if (searchTime) clearTimeout(searchTime)
    setSearchTime(
      setTimeout(() => {
        setSearchKey(key)
        setAlertConfig({
          variant: "primary",
          text: `hasil pencarian : ${key}`,
        });
        setShowAlert(true);
      }, 750)
    );
  };

  // FUNCTION GET DATA DARI SERVER
  const getData = () => {
    setIsLoading(true);
    setIsSearching(false);
    setShowAlert(false);

    Axios.all([KelompokApi.getPage(page, dataLength, searchKey), KelompokApi.getDataBuaso()])
      .then(
        Axios.spread((res, dataBuaso) => {
          setData(res.data.data);
          setDataBuaso(dataBuaso.data.data);
          setTotalPage(res.data.total_page);
          setTotalData(res.data.data_count);
        })
      )
      .catch((err) => alert(err))
      .finally(() => {
        if (searchKey != "") {
          setAlertConfig({
            variant: "primary",
            text: `Hasil Pencarian : ${searchKey}`,
          });
          setShowAlert(true);
        }
        setIsLoading(false);
      });
  };

  // FUNCTION SWITCH HIDDEN DATA
  const changeDataStatus = (status, id) => {
    setIsLoading(true);
    setShowAlert(false);

    const value = {
      id_kelompok: id,
    };

    const onLoadedSuccess = () => {
      setIsSearching(false);
      setAlertConfig({
        variant: "primary",
        text: "Ubah status data berhasil",
      });
      setShowAlert(true);
    };

    const onLoadedFailed = () => {
      setIsSearching(false);
      setAlertConfig({
        variant: "danger",
        text: "Ubah status data gagal",
      });
      setShowAlert(true);
    };

    status === true
      ? KelompokApi.show(value)
          .then(() => onLoadedSuccess())
          .catch(() => onLoadedFailed())
          .finally(() => getData())
      : KelompokApi.hide(value)
          .then(() => onLoadedSuccess())
          .catch(() => onLoadedFailed())
          .finally(() => getData());
  };

  // ON COMPONENT MOUNT
  useEffect(() => {
    setNavbarTitle(`Master ${title}`); // SET JUDUL NAVBAR
    getData(); // GET DATA DARI SERVER
  }, [setNavbarTitle, page, dataLength, searchKey]);

  // FORMIK VALIDATION SCHEMA DENGAN YUP VALIDATION
  const formValidationSchema = Yup.object().shape({
    id_buaso: Yup.string().required("Pilih BUASO"),
    nama_kelompok: Yup.string().required("Masukkan nama kelompok"),
    // keterangan_kelompok: Yup.string().required("Masukkan keterangan"),
  });

  // MODAL TAMBAH COMPONENT
  const TambahModal = () => {
    // States
    const [kode, setKode] = useState("")
    const [loading, setLoading] = useState(true)

    useEffect(() => {
      KelompokApi.kode_baru().then(res => {
        setKode(res.data.data)
      }).finally(() => setLoading(false))
    }, [])
    

    // FORMIK INITIAL VALUES
    const formInitialValues = {
      id_buaso: "1",
      kode_kelompok: kode,
      nama_kelompok: "",
      keterangan_kelompok: "",
      flag: "BH",
    };

    // KIRIM DATA BARU KE SERVER
    const formSubmitHandler = (values) => {
      KelompokApi.create(values)
        .then((res) => {
          // SUCCESS ALERT
          setAlertConfig({
            variant: "primary",
            text: "Tambah data berhasil!",
          });
        })
        .catch((err) => {
          // const errMsg = Object.values(err.response?.data?.error) ?? []
          // ERROR ALERT
          setAlertConfig({
            variant: "danger",
            text: `Tambah data gagal! (${err.response.data.message})`,
            // text: `Tambah data gagal! <ul> ${errMsg.map(e => `<li>${e}</li>`).join("")} </ul>`,
          });
        })
        .finally(() => {
          // CLOSE MODAL
          setIsCreateForm(false);
          // TAMPILKAN ALERT
          setShowAlert(true);
          // FETCH DATA DARI SERVER
          getData();
        });
    };

    return (
      <CreateModal show={isCreateForm} onHide={() => setIsCreateForm(false)} title={title}>
        {loading ? (
          <DataStatus loading text="Memuat data..." />
        ) : (
          <Formik
            enableReinitialize
            initialValues={formInitialValues}
            validationSchema={formValidationSchema}
            onSubmit={formSubmitHandler}
          >
            {({ values, errors, touched, isSubmitting, handleChange, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Modal.Body>
                  <Select
                    label="BUASO"
                    name="id_buaso"
                    onChange={handleChange}
                    error={errors.id_buaso && touched.id_buaso && true}
                    errorText={errors.id_buaso}
                    defaultValue={values.id_buaso} // untuk menyesuaikan nilai yang telah dipilih
                    disabled={true}
                  >
                    <option value="">Pilih BUASO</option>
                    {dataBuaso.map((e) => (
                      <option
                        key={e.id_buaso}
                        value={e.id_buaso}
                      >{`${e.kode_buaso} - ${e.nama_buaso}`}</option>
                    ))}
                  </Select>

                  <Row>
                    <Col md={4}>
                      <Input
                        label="Kode Kelompok Barang"
                        placeholder="Kode Kelompok Barang"
                        value={values.kode_kelompok}
                        readOnly
                      />
                    </Col>

                    <Col md={8}>
                      <Input
                        label="Nama Kelompok"
                        type="text"
                        name="nama_kelompok"
                        placeholder="Nama Kelompok"
                        value={values.nama_kelompok}
                        onChange={handleChange}
                        error={errors.nama_kelompok && touched.nama_kelompok && true}
                        errorText={errors.nama_kelompok}
                      />
                    </Col>
                  </Row>

                  <TextArea
                    label="Keterangan"
                    name="keterangan_kelompok"
                    placeholder="Keterangan"
                    rows={4}
                    value={values.keterangan_kelompok}
                    onChange={handleChange}
                    error={errors.keterangan_kelompok && touched.keterangan_kelompok && true}
                    errorText={errors.keterangan_kelompok}
                  />
                </Modal.Body>
                <Modal.Footer>
                  <div className="d-flex justify-content-end">
                    <ActionButton
                      type="submit"
                      variant="primary"
                      text="Tambah"
                      className="mt-2 px-4"
                      loading={isSubmitting}
                    />
                  </div>
                </Modal.Footer>
              </form>
            )}
          </Formik>
        )}
      </CreateModal>
    );
  };

  // MODAL UBAH COMPONENT
  const UbahModal = () => {
    const formInitialValues = {
      id_buaso: updateData.id_buaso,
      kode_kelompok: updateData.kode_kelompok,
      nama_kelompok: updateData.nama_kelompok,
      keterangan_kelompok: updateData.keterangan_kelompok,
      flag: "BH",
    };

    // KIRIM UPDATE DATA KE SERVER
    const formSubmitHandler = (values) => {
      const finalValues = {
        id_kelompok: updateData.id_kelompok,
        ...values,
      };
      // SEND UPDATED DATA TO SERVER
      KelompokApi.update(finalValues)
        .then((res) => {
          // SUCCESS ALERT
          setAlertConfig({
            variant: "primary",
            text: "Ubah data berhasil!",
          });
        })
        .catch((err) => {
          // const errMsg = Object.values(err.response?.data?.error) ?? []
          // ERROR ALERT
          setAlertConfig({
            variant: "danger",
            text: `Ubah data gagal! (${err.response.data.message})`,
            // text: `Ubah data gagal! <ul> ${errMsg.map(e => `<li>${e}</li>`).join("")} </ul>`,
          });
        })
        .finally(() => {
          // CLOSE UPDATE MODAL FORM
          setIsUpdateForm(false);
          // SHOW ALERT
          setShowAlert(true);
          // FETCH DATA DARI SERVER
          getData();
        });
    };

    return (
      <UpdateModal show={isUpdateForm} onHide={() => setIsUpdateForm(false)} title={title}>
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({ values, errors, touched, isSubmitting, handleChange, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <Select
                  label="BUASO"
                  name="id_buaso"
                  onChange={handleChange}
                  error={errors.id_buaso && touched.id_buaso && true}
                  errorText={errors.id_buaso}
                  defaultValue={updateData.id_buaso} // untuk menyesuaikan nilai yang telah dipilih
                  disabled={true}
                >
                  <option value="">Pilih BUASO</option>
                  {dataBuaso.map((e) => (
                    <option
                      key={e.id_buaso}
                      value={e.id_buaso}
                    >{`${e.kode_buaso} - ${e.nama_buaso}`}</option>
                  ))}
                </Select>

                <Row>
                  <Col md={4}>
                    <Input
                      label="Kode Kelompok Barang"
                      placeholder="Kode Kelompok Barang"
                      value={values.kode_kelompok}
                      readOnly
                    />
                  </Col>

                  <Col md={8}>
                    <Input
                      label="Nama Kelompok"
                      type="text"
                      name="nama_kelompok"
                      placeholder="Nama Kelompok"
                      value={values.nama_kelompok}
                      onChange={handleChange}
                      error={errors.nama_kelompok && touched.nama_kelompok && true}
                      errorText={errors.nama_kelompok}
                    />
                  </Col>
                </Row>

                <TextArea
                  label="Keterangan"
                  name="keterangan_kelompok"
                  placeholder="Keterangan"
                  rows={4}
                  value={values.keterangan_kelompok}
                  onChange={handleChange}
                  error={errors.keterangan_kelompok && touched.keterangan_kelompok && true}
                  errorText={errors.keterangan_kelompok}
                />
              </Modal.Body>
              <Modal.Footer>
                <div className="d-flex justify-content-end">
                  <ActionButton
                    type="submit"
                    variant="success"
                    text="Ubah"
                    className="mt-2 px-4"
                    loading={isSubmitting}
                  />
                </div>
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </UpdateModal>
    );
  };

  // MODAL DETAIL COMPONENT
  const DetailModal = () => (
    <ReadModal show={isReadForm} onHide={() => setIsReadForm(false)} title={title}>
      <Modal.Body>
        <ListData label="BUASO">{updateData.nama_buaso ?? "-"}</ListData>
        <Row>
          <Col md={4}>
            <ListData label="Kode Kelompok">{updateData.kode_kelompok ?? "-"}</ListData>
          </Col>

          <Col md={8}>
            <ListData label="Nama Kelompok">{updateData.nama_kelompok ?? "-"}</ListData>
          </Col>
        </Row>
        <ListData label="Keterangan">{updateData.keterangan_kelompok ?? "-"}</ListData>
      </Modal.Body>
    </ReadModal>
  );

  // MODAL HAPUS COMPONENT
  const HapusModal = () => {
    // SET DATA ID YANG DIHAPUS
    const deleteValue = { id_kelompok: deleteData.id_kelompok };

    // MENANGANI DELETE BUTTON LOADING
    const [btnLoading, setBtnLoading] = useState(false);

    // DELETE DATA DARI SERVER
    const deleteDataHandler = () => {
      setBtnLoading(true);
      KelompokApi.delete(deleteValue)
        .then((res) => {
          // KONFIGURASI ALERT
          setAlertConfig({
            variant: "primary",
            text: "Hapus data berhasil!",
          });
        })
        .catch((err) => {
          // KONFIGURASI ALERT
          setAlertConfig({
            variant: "danger",
            text: `Hapus data gagal! (${err.response.data.message})`,
          });
        })
        .finally(() => {
          // CLOSE MODAL
          setIsDeleteData(false);
          // TAMPIL ALERT
          setShowAlert(true);
          // FETCH DATA DARI SERVER
          getData();
        });
    };

    return (
      <DeleteModal
        show={isDeleteData}
        onHide={() => setIsDeleteData(false)}
        loading={btnLoading}
        onConfirm={deleteDataHandler}
        title={title}
      >
        <div>Nama Kelompok : {deleteData.nama_kelompok}</div>
      </DeleteModal>
    );
  };

  // TABLE COMPONENT
  const Table = () => {
    return (
      <>
        <b>List Data {title}</b>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <ThFixed>BUASO</ThFixed>
              <Th style={{ minWidth: '150px' }}>Kode Kelompok Barang</Th>
              <Th style={{ minWidth: '300px' }}>Nama Kelompok Barang</Th>
              <Th style={{ minWidth: '300px' }}>Keterangan</Th>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => {
              return (
                <Tr key={val.id_kelompok}>
                  <TdFixed>{PageNumber(page, dataLength, index)}</TdFixed>
                  <TdFixed>
                    <ButtonGroup>
                      <ReadButton
                        noMargin
                        onClick={() => {
                          setUpdateData(val);
                          setIsReadForm(true);
                        }}
                      />
                      {HeadOfficeStatus() && (
                        <>
                          <UpdateButton
                            noMargin
                            onClick={() => {
                              setUpdateData(val);
                              setIsUpdateForm(true);
                            }}
                          />
                          <DeleteButton
                            noMargin
                            onClick={() => {
                              setDeleteData(val);
                              setIsDeleteData(true);
                            }}
                          />

                          <Switch
                            noMargin
                            id={val.id_kelompok}
                            checked={val.is_hidden ? false : true}
                            onChange={() => changeDataStatus(val.is_hidden, val.id_kelompok)}
                          />
                        </>
                      )}
                    </ButtonGroup>
                  </TdFixed>
                  <TdFixed>{val.nama_buaso}</TdFixed>
                  <TdFixed>{val.kode_kelompok}</TdFixed>
                  <Td>{val.nama_kelompok}</Td>
                  <Td>{val.keterangan_kelompok}</Td>
                </Tr>
              );
            })}
          </TBody>
        </CRUDLayout.Table>
        {!isSearching && (
          <Pagination
            dataLength={dataLength}
            dataPage={
              totalData <= 10
                ? data.length
                : data.map((res, index) => {
                    if (index == data.length - 1) {
                      return TableNumber(page, dataLength, index);
                    }
                  })
            }
            dataNumber={data.map((res, index) => {
              if (index == 0) {
                return TableNumber(page, dataLength, index);
              }
            })}
            dataCount={totalData}
            onDataLengthChange={(e) => {
              setDataLength(e.target.value);
              setPage(1);
            }}
            currentPage={page}
            totalPage={totalPage}
            onPaginationChange={({ selected }) => setPage(selected + 1)}
          />
        )}
      </>
    );
  };

  return (
    <>
      <CRUDLayout>
        <CRUDLayout.Head>
          <CRUDLayout.HeadSearchSection>
            <div className="d-flex mb-3">
              <InputSearch onChange={onInputSearch}/>
            </div>
          </CRUDLayout.HeadSearchSection>

          {/* Button Section */}
          <CRUDLayout.HeadButtonSection>
            {/* Button Export */}
            {/* <ExportButton /> */}
            {/* Button Tambah */}
            {HeadOfficeStatus() && <CreateButton onClick={() => setIsCreateForm(true)} />}
          </CRUDLayout.HeadButtonSection>
        </CRUDLayout.Head>

        {/* Alert */}
        <Alert
          show={showAlert}
          showCloseButton={true}
          variant={alertConfig.variant}
          text={alertConfig.text}
          onClose={() => setShowAlert(false)}
        />

        {isLoading ? (
          <DataStatus loading={isLoading} text="Memuat Data" />
        ) : !Array.isArray(data) ? (
          <DataStatus text="Tidak dapat memuat data" />
        ) : data.length > 0 ? (
          <Table />
        ) : (
          <DataStatus text="Tidak ada data" />
        )}

        {/* MODAL */}
        {isCreateForm && <TambahModal />}
        {isUpdateForm && <UbahModal />}
        {isDeleteData && <HapusModal />}
        {isReadForm && <DetailModal />}
      </CRUDLayout>
    </>
  );
};

export default Kelompok;
