export const formInitialValues = (data) => ({
  idItemConvert: data?.id_item_convert ?? null,
  namaItemConvert: data?.nama_item ?? null,
  tglConvert: data?.tgl_convert_item_master ?? null,
  noConvert: data?.no_convert_item_master ?? "",
  qtyConvert: data?.qty_convert ?? "",
  idGudang: data?.id_gudang ?? null,
  namaGudang: data?.nama_gudang ?? null,
  idSatuan: data?.id_satuan ?? null,
  namaSatuan: data?.satuan_pakai ?? null,
  idKelompok: data?.id_kelompok ?? null,
  namaKelompok: data?.nama_kelompok ?? "",
  idJenis: data?.id_jenis ?? null,
  namaJenis: data?.nama_jenis ?? "",
  hargaSatuan: data?.harga_satuan ?? 0,
  detail: data?.detail
    ? data?.detail.map((val) => ({
        idConvertItemDetail: val.id_convert_item_detail ?? null,
        idConvertItemMaster: val.id_convert_item_master ?? null,
        qtyCampuran: val.qty_campuran ?? "",
        idItemCampuran: val.id_item_campuran ?? null,
        namaItem: val.nama_item ?? "",
        kodeItem: val.kode_item ?? "",
        namaSatuan: val.satuan_pakai ?? "",
        idGudangDetail: val.id_gudang ?? null,
        namaGudangDetail: val.nama_gudang ?? "",
      }))
    : [],
});
