// Component
import {
  Table,
  THead,
  Tr,
  Th,
  ThFixed,
  TBody,
  Td,
  DataStatus,
  TdFixed,
  SelectSearch,
  Input,
} from "components";
import { InputQtyDecimal } from "components/Input";
import { DecimalConvert, RupiahConvert } from "utilities";
import KreditLimit from "./KreditLimit";

const ListBarangJadiSection = ({
  dataDetail,
  setDataDetail,
  formik,
  readOnly = false,
  data,
}) => {
  // Variables
  const detail = dataDetail ?? [];
  const diskon = formik.values.diskon
    ? formik.values.diskon === ""
      ? 0
      : parseFloat(formik.values.diskon)
    : 0;
  const ppn = formik.values.ppn
    ? formik.values.ppn === ""
      ? 0
      : parseFloat(formik.values.ppn)
    : 0;
  let total = 0;

  const handleGudang = (key, id_gudang) => {
    setDataDetail(
      dataDetail.map((val, id) =>
        id === key
          ? {
              ...val,
              id_gudang,
            }
          : val
      )
    );
  };

  return (
    <>
      <small>List Barang Jadi RAB</small>
      <br />

      <Table>
        <THead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <ThFixed>Kode Barang Jadi</ThFixed>
            <Th>Nama Barang Jadi</Th>
            <Th>Gudang</Th>
            <ThFixed>Qty. RAB</ThFixed>
            <ThFixed>Satuan</ThFixed>
            <ThFixed>Harga Satuan</ThFixed>
            {/* <ThFixed>
              Unit Cost + Profit ({data.profit ? parseFloat(data.profit).toPrecision() : 0}%)
            </ThFixed> */}
            <ThFixed>Sub Total</ThFixed>
          </Tr>
        </THead>

        <TBody>
          {detail?.length > 0 ? (
            <>
              {detail.map((val, index) => {
                const sub_total =
                  val.qty_item && val.unit_cost
                    ? parseFloat(val.unit_cost) * parseFloat(val.qty_item)
                    : 0;
                total = total + sub_total;

                return (
                  <Tr key="index">
                    <TdFixed>{index + 1}</TdFixed>
                    <TdFixed>{val.kode_item}</TdFixed>
                    <Td>{val.nama_item}</Td>
                    {readOnly ? (
                      <Td>{val?.nama_gudang ?? "-"}</Td>
                    ) : (
                      <Td noPadding>
                        <SelectSearch
                          label=""
                          onChange={(e) => handleGudang(index, e.value)}
                          noMargin
                          placeholder="Pilih Gudang"
                          defaultValue={
                            val.id_gudang
                              ? {
                                  value: val.id_gudang,
                                  label: val.gudang.find(
                                    (e) => val.id_gudang === e.id_gudang
                                  )?.nama_gudang,
                                }
                              : ""
                          }
                          option={val.gudang.map((e) => {
                            return {
                              value: e.id_gudang,
                              label: e.nama_gudang,
                            };
                          })}
                        />
                      </Td>
                    )}
                    <TdFixed className="text-right">
                      {DecimalConvert(val.qty_item).getWithComa}
                    </TdFixed>
                    <TdFixed>{val.kode_satuan_jual ?? val.kode_satuan}</TdFixed>
                    <TdFixed className="text-right">
                      {
                        RupiahConvert(parseFloat(val.unit_cost ?? 0).toString())
                          .getWithComa
                      }
                    </TdFixed>
                    {/* <TdFixed className="text-right">
                      {RupiahConvert(profit.toString()).detail}
                    </TdFixed> */}
                    <TdFixed className="text-right">
                      {
                        RupiahConvert(parseFloat(sub_total).toString())
                          .getWithComa
                      }
                    </TdFixed>
                  </Tr>
                );
              })}
              <Tr>
                <Td colSpan={7} className="text-right">
                  <b>Total</b>
                </Td>
                <TdFixed className="text-right">
                  {RupiahConvert(parseFloat(total).toString()).getWithComa}
                </TdFixed>
              </Tr>
              <Tr>
                <Td colSpan={6} className="text-right">
                  <b>Diskon (%)</b>
                </Td>
                <Td noPadding>
                  {/* <Input
                    noMargin
                    label=""
                    placeholder="Diskon"
                    name="diskon"
                    value={formik.values.diskon}
                    onChange={formik.handleChange}
                    error={
                      formik.errors.diskon && formik.touched.diskon && true
                    }
                    errorText=""
                    readOnly={readOnly}
                  /> */}
                  <InputQtyDecimal
                    value={formik.values.diskon}
                    onChange={(e) => formik.setFieldValue("diskon", e)}
                    disabled={readOnly}
                  />
                </Td>
                <TdFixed className="text-right">
                  {
                    RupiahConvert(parseFloat((total * diskon) / 100).toString())
                      .getWithComa
                  }
                </TdFixed>
              </Tr>
              <Tr>
                <Td colSpan={7} className="text-right">
                  <b>Total Setelah Dikurangi Diskon</b>
                </Td>
                <TdFixed className="text-right">
                  {
                    RupiahConvert(
                      (total - parseFloat((total * diskon) / 100)).toString()
                    ).getWithComa
                  }
                </TdFixed>
              </Tr>
              <Tr>
                <Td colSpan={6} className="text-right">
                  <b>PPn (%)</b>
                </Td>
                <Td noPadding>
                  {/* <Input
                    noMargin
                    label=""
                    placeholder="PPn"
                    name="ppn"
                    value={formik.values.ppn}
                    onChange={formik.handleChange}
                    error={formik.errors.ppn && formik.touched.ppn && true}
                    errorText=""
                    readOnly={readOnly}
                  /> */}
                  <InputQtyDecimal
                    value={formik.values.ppn}
                    onChange={(e) => formik.setFieldValue("ppn", e)}
                    disabled={readOnly}
                  />
                </Td>
                <TdFixed className="text-right">
                  {
                    RupiahConvert(
                      parseFloat(
                        ((total - parseFloat((total * diskon) / 100)) * ppn) /
                          100
                      ).toString()
                    ).getWithComa
                  }
                </TdFixed>
              </Tr>
              <Tr>
                <Td colSpan={7} className="text-right">
                  <b>Total Setelah Ditambah PPn</b>
                </Td>
                <TdFixed className="text-right">
                  {
                    RupiahConvert(
                      parseFloat(
                        total -
                          parseFloat((total * diskon) / 100) +
                          parseFloat(
                            ((total - parseFloat((total * diskon) / 100)) *
                              ppn) /
                              100
                          )
                      ).toString()
                    ).getWithComa
                  }
                </TdFixed>
              </Tr>
            </>
          ) : (
            <Tr>
              <Td colSpan={8}>
                <DataStatus text="Tidak Ada Data" />
              </Td>
            </Tr>
          )}
        </TBody>
      </Table>
      {/* <KreditLimit data={data} diskon={diskon} ppn={ppn} /> */}
    </>
  );
};

export default ListBarangJadiSection;
