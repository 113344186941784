import { Table, Th, Td, DataStatus, TdFixed, ThFixed } from "components";
import { Col, Row } from "react-bootstrap";
import { DecimalConvert } from "utilities";

const TableSection = ({ data }) => {
  const InfoItemHorizontal = ({ label, text, width, style, className }) => (
    <div className={`d-flex align-items-top ${className}`} style={style}>
      <div style={{ width: width ? width : 180, fontSize: 18 }}>{label}</div>
      <div className="pl-3 pr-2" style={{ fontSize: 18 }}>
        :
      </div>
      <div style={{ fontSize: 18 }}>{text}</div>
    </div>
  );
  return (
    <>
      <div className="p-1">
        <b>List Surat Jalan</b>
      </div>
      <Table>
        <thead className="bg-light">
          <ThFixed>
            <div style={{ fontSize: 18 }}>No</div>
          </ThFixed>
          <Th>
            <div style={{ fontSize: 18 }}>Kode Barang</div>
          </Th>
          <Th>
            <div style={{ fontSize: 18 }}>Item Barang</div>
          </Th>
          <Th>
            <div style={{ fontSize: 18 }}>Volume </div>
          </Th>
          <Th>
            <div style={{ fontSize: 18 }}>Kumulatif</div>
          </Th>
          <Th>
            <div style={{ fontSize: 18 }}>Satuan</div>
          </Th>
        </thead>
        <tbody>
          {data?.detail?.length > 0 ? (
            <>
              {data?.detail?.map((val, index) => (
                <tr key={index}>
                  <TdFixed>
                    <div style={{ fontSize: 18 }}>{index + 1}</div>
                  </TdFixed>
                  <Td className="text-right" width={80}>
                    <div style={{ fontSize: 18 }}>{val.kode_barang}</div>
                  </Td>
                  <Td>
                    <div style={{ fontSize: 18 }}>{val.nama_barang}</div>
                  </Td>
                  <Td className="text-right" width={80}>
                    <div style={{ fontSize: 18 }}>
                      {DecimalConvert(parseFloat(val.qty ?? 0)).getWithComa}
                    </div>
                  </Td>
                  <Td className="text-right" width={80}>
                    <div style={{ fontSize: 18 }}>
                      {
                        DecimalConvert(parseFloat(val.qty_kumulatif ?? 0))
                          .getWithComa
                      }
                    </div>
                  </Td>
                  <Td width={200}>
                    <div style={{ fontSize: 18 }}>{val.satuan_jual}</div>
                  </Td>
                </tr>
              ))}
              <tr>
                <td colSpan="6">
                  <Row>
                    <Col>
                      <InfoItemHorizontal
                        label="Armada Pengiriman"
                        style={{ fontSize: 100 }}
                        text={`${data?.plat_nomor ?? "-"} - ${
                          data?.nama_item_aset ?? "-"
                        }`}
                      />
                      <InfoItemHorizontal
                        label="Petugas Pengiriman"
                        style={{ fontSize: 18 }}
                        text={data?.nama_sopir ?? "-"}
                      />
                      <InfoItemHorizontal
                        label="Rit Ke"
                        style={{ fontSize: 18 }}
                        text=""
                      />
                    </Col>
                    <Col>
                      <InfoItemHorizontal
                        label="Jam Berangkat"
                        text=""
                        style={{ fontSize: 18 }}
                      />
                      <div style={{ height: 10 }}></div>
                      <InfoItemHorizontal
                        label="Jam Tiba di Proyek"
                        text=""
                        style={{ fontSize: 18 }}
                      />
                    </Col>
                  </Row>
                </td>
              </tr>
              {/* <tr>
                <td colSpan="6">
                  <Row>
                    <Col>
                      <span style={{ fontSize: 18 }}>Catatan :</span>
                    </Col>
                    <Col>
                      <div style={{ fontSize: 18 }}>
                        PERHATIAN :
                        <br />
                        1. Surat Jalan ini merupakan bukti resmi penerimaan
                        barang
                        <br />
                        2. Surat Jalan ini bukan bukti penjualan.
                      </div>
                    </Col>
                  </Row>
                </td>
              </tr> */}
            </>
          ) : (
            <tr>
              <td colSpan={8}>
                <DataStatus text="Tidak ada data" />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <p>
        BARANG SUDAH DITERIMA DALAM KEADAAN BAIK DAN CUKUP, oleh :
        <br /> (tanda tangan dan cap (stempel) perusahaan)
      </p>
    </>
  );
};

export default TableSection;
