export const formSubmitMapper = (values) => ({
  tgl_stock_opname_master: values?.tglStockOpname,
  id_stock_fisik: values?.idStockFisik,
  id_gudang: values?.idGudang,
  detail: values.detail
    .filter((val) => parseFloat(val?.qtyAdjustment) !== 0)
    .map((val) => ({
      id_item_barang: val?.idItemBarang,
      qty_stock_akhir: val?.qtyPersediaan,
      harga_stock_akhir: val?.hargaStockAkhir,
      qty_stock_fisik: val?.qtyStockFisik,
      harga_stock_fisik: val?.hargaStockFisik,
      qty_adjustment: val?.qtyAdjustment,
      harga_adjustment: val?.hargaAdjustment,
      id_satuan: val?.idSatuan,
      keterangan: val?.keterangan,
    })),
});
