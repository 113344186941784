import { useState, useEffect } from "react";
import { Formik } from "formik";
import Axios from "axios";
import { Alert, BackButton, DataStatus } from "components";
import { ConvertItepApi } from "api";
import { TableSection, FormSection } from "../../Section";
import { formInitialValues, formValidationSchema } from "../../Utils";
import { Modal } from "react-bootstrap";

export const ModalDetail = ({ setModalConfigDetail, modalConfigDetail }) => {
  const [detailData, setDetailData] = useState([]);
  const [fetchingStatus, setFetchingStatus] = useState({
    loading: false,
    success: true,
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  const getInitialValues = () => {
    setFetchingStatus({ loading: true, success: false });

    Axios.all([
      ConvertItepApi.getSingle({
        id_convert_item_master: modalConfigDetail?.id,
      }),
    ])
      .then(
        Axios.spread((single) => {
          setDetailData(single.data.data);
          setFetchingStatus({
            loading: false,
            success: true,
          });
        })
      )
      .catch(() => {
        setAlertConfig({
          show: false,
          variant: "primary",
          text: "Data gagal dimuat!",
        });
        setFetchingStatus({
          loading: false,
          success: false,
        });
      });
  };

  useEffect(() => {
    getInitialValues();
  }, []);

  return (
    <Modal
      show={modalConfigDetail.show}
      size="lg"
      onHide={() => setModalConfigDetail({ show: false, id: "" })}
    >
      <Modal.Header closeButton>Detail Data Convert Item</Modal.Header>
      <Modal.Body>
        <Formik
          enableReinitialize
          initialValues={formInitialValues(detailData)}
          validationSchema={formValidationSchema}
        >
          {(formik) => (
            <>
              <Alert
                show={alertConfig.show}
                variant={alertConfig.variant}
                text={alertConfig.text}
                showCloseButton={true}
                onClose={() =>
                  setAlertConfig({
                    ...alertConfig,
                    show: false,
                  })
                }
              />
              {fetchingStatus.loading ? (
                <DataStatus loading text="Memuat . . ." />
              ) : fetchingStatus.success ? (
                <form onSubmit={formik.handleSubmit}>
                  <FormSection type="read" />
                  <TableSection type="read" />
                  <div className="d-flex justify-content-end">
                    <BackButton
                      size="sm"
                      onClick={() =>
                        setModalConfigDetail({ show: false, id: "" })
                      }
                    />
                  </div>
                </form>
              ) : (
                <DataStatus text="Data gagal dimuat" />
              )}
            </>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};
