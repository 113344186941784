import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Row, Col, Card } from "react-bootstrap";
import {
  BackButton,
  DatePicker,
  InfoItemHorizontal,
  Input,
  SelectSearch,
} from "components";
import { DateConvert } from "utilities";
import { StockFisikApi } from "api";
import { useFormikContext } from "formik";

const DetailSection = ({ value }) => {
  return (
    <>
      <InfoItemHorizontal
        label="Tgl. Stock Fisik"
        text={DateConvert(new Date(value.tglStock))?.detail ?? "-"}
      />
      <InfoItemHorizontal label="No. Stock Fisik" text={value.noStock ?? "-"} />
      <InfoItemHorizontal
        label="Unit Produksi"
        text={value.namaUnitProduksi ?? "-"}
      />
      <InfoItemHorizontal label="Gudang" text={value.namaGudang ?? "-"} />
    </>
  );
};
const FormSection = ({ dataUnitProduksi, fetchingStatus }) => {
  const history = useHistory();
  const location = useLocation();
  const [isNomorLoading, setIsNomorLoading] = useState(false);
  const [dataGudang, setDataGudang] = useState([]);
  const [isUnitProduksiSelected, setIsUnitProduksiSelected] = useState({
    isLoading: false,
    isDisable: true,
  });
  const { values, errors, touched, setValues } = useFormikContext();

  const mappingDataGudang = (data) =>
    data.map((val) => ({
      value: val?.id_gudang,
      label: val?.nama_gudang,
    }));

  const getNomorHandler = (date) => {
    setIsNomorLoading(true);

    StockFisikApi.getNomor({ tanggal: date })
      .then((no) => {
        const noStock = no.data.data;

        setValues({
          ...values,
          noStock: noStock,
          tglStock: date,
        });
      })
      .catch(() => {
        setValues({
          ...values,
          noStock: "",
          tanggal: "",
        });
        window.alert("Nomor gagal dimuat");
      })
      .finally(() => {
        setIsNomorLoading(false);
      });
  };
  const getDataGudangHandler = (value) => {
    setIsUnitProduksiSelected({ isLoading: true, isDisable: true });
    StockFisikApi.dropdownGudang({ id_unit_produksi: value })
      .then((gudang) => {
        const mappingGudang = mappingDataGudang(gudang.data.data);

        setDataGudang(mappingGudang);

        setIsUnitProduksiSelected({
          ...isUnitProduksiSelected,
          isDisable: false,
        });
      })
      .catch(() => {
        window.alert("Data gagal dimuat");
      })
      .finally(() => {
        setIsUnitProduksiSelected({
          isDisable: false,
          isLoading: false,
        });
      });
  };

  useEffect(() => {
    if (location.state.action === "UPDATE" && fetchingStatus.success) {
      getDataGudangHandler(values.idUnitProduksi);
    }
    return () => {};
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center p-1">
        <b>
          {location.state.action === "READ"
            ? "Detail Data Stock Fisik"
            : location.state.action === "UPDATE"
            ? "Ubah Data Stock Fisik"
            : "Tambah Data Stock Fisik"}
        </b>
        <BackButton
          size="sm"
          onClick={() =>
            history.push("/inventory/transaksi/stock-fisik", {
              ...location?.state,
            })
          }
        />
      </div>
      <Card className="mb-4">
        <Card.Body>
          {location.state.action === "READ" ? (
            <DetailSection value={values} />
          ) : (
            <>
              <Row>
                <Col md>
                  <DatePicker
                    label="Tgl. Stock Fisik"
                    placeholderText="Pilih tanggal"
                    selected={values.tglStock ? new Date(values.tglStock) : ""}
                    onChange={(date) => {
                      const newDate = DateConvert(date).default;
                      getNomorHandler(newDate);
                    }}
                    error={Boolean(errors.tglStock) && touched.tglStock}
                    errorText={
                      Boolean(errors.tglStock) &&
                      touched.tglStock &&
                      errors.tglStock
                    }
                  />
                </Col>
                <Col md>
                  <Input
                    readOnly
                    label="No. Stock Fisik"
                    placeholder={
                      isNomorLoading
                        ? "Memuat nomor . . ."
                        : "Pilih tanggal untuk menentukan nomor"
                    }
                    value={
                      isNomorLoading ? "Memuat nomor . . ." : values.noStock
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col md>
                  <SelectSearch
                    label="Unit Produksi"
                    placeholder="Pilih unit produksi"
                    option={dataUnitProduksi}
                    defaultValue={{
                      value: values.idUnitProduksi ?? "",
                      label: values.namaUnitProduksi ?? "Pilih unit produksi",
                    }}
                    onChange={(val) => {
                      setValues({
                        ...values,
                        idUnitProduksi: val.value,
                        namaUnitProduksi: val.label,
                      });
                      getDataGudangHandler(val.value);
                    }}
                  />
                </Col>
                <Col md>
                  <SelectSearch
                    label="Gudang"
                    placeholder={
                      isUnitProduksiSelected.isDisable
                        ? "Pilih unit produksi terlebih dahulu"
                        : "Pilih gudang"
                    }
                    isDisabled={isUnitProduksiSelected.isDisable}
                    loading={isUnitProduksiSelected.isLoading}
                    option={dataGudang}
                    defaultValue={{
                      value: values.idGudang ?? "",
                      label: values.namaGudang ?? "Pilih gudang",
                    }}
                    onChange={(val) => {
                      setValues({
                        ...values,
                        idGudang: val.value,
                        namaGudang: val.label,
                      });
                    }}
                    error={Boolean(errors.idGudang) && touched.idGudang}
                    errorText={
                      Boolean(errors.idGudang) &&
                      touched.idGudang &&
                      errors.idGudang
                    }
                  />
                </Col>
              </Row>
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default FormSection;
