import Services from "services";

class StockFisikApi {
  get(params) {
    return Services.get("/stock_fisik/page", { params });
  }

  getSingle(params) {
    return Services.get("/stock_fisik/single", { params });
  }

  getNomor(params) {
    return Services.get("/stock_fisik/no_baru", { params });
  }

  getBahan() {
    return Services.get("/stock_fisik/bahan");
  }

  dropdownUnitProduksi() {
    return Services.get("/stock_fisik/unit_produksi");
  }

  dropdownGudang(params) {
    return Services.get("/stock_fisik/gudang", { params });
  }

  dropdownKelompok(params) {
    return Services.get("/kelompok/dropdown", { params });
  }

  dropdownJenis(params) {
    return Services.get("/bahan/jenis", { params });
  }

  dropdownItem(params) {
    return Services.get("/stock_fisik/bahan", { params });
  }

  create(value) {
    return Services.post("/stock_fisik", value);
  }

  update(value) {
    return Services.put("/stock_fisik", value);
  }
}

export default new StockFisikApi();
