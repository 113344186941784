import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import Axios from "axios";
import {
  CRUDLayout,
  InputSearch,
  CreateButton,
  UpdateButton,
  ReadButton,
  BackButton,
  Alert,
  ActionButton,
  Pagination,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  DataStatus,
  DeleteButton,
  DeleteModal,
  ApprovalStatusButton,
  FilterButton,
} from "../../../../components";
import { Spinner } from "./Comps";
import { PurchaseRequestApi } from "../../../../api";
import { useHistory } from "react-router-dom";
import { DateConvert, TableNumber } from "../../../../utilities";
import {
  Modal,
  Row,
  Col,
  Card,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import {
  IoReload,
  IoCloseCircleOutline,
  IoCheckmarkCircleOutline,
} from "react-icons/io5";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
// import { PageNumber } from "../../../../utilities";
import { useReactToPrint } from "react-to-print";
import { CetakPurchaseRequest } from "./CetakPurchaseRequest";
import { ModalFilter } from "./Section";
import { saveAs } from "file-saver";

// MODAL TAMBAH COMPONENT
const ReadModal = ({
  readModalShow,
  idPurchase,
  setAlertConfig,
  setReadModalShow,
  setIdPurchase,
  data,
}) => {
  const [isLoadingRead, setIsLoadingRead] = useState(false);
  const [readData, setReadData] = useState({
    detail: [],
  });
  const [isPrint, setIsPrint] = useState(false);
  const [printData, setPrintData] = useState(0);

  const componentRef = useRef();

  const reactToPrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => setIsPrint(false),
  });

  const handleDataPrint = (id) => {
    setPrintData(id);
    setIsPrint(true);
  };

  const handlePrint = () => {
    reactToPrint();
  };

  console.log(isLoadingRead);
  const getReadData = (id) => {
    setIsLoadingRead(true);
    setReadData({
      detail: [],
    });

    PurchaseRequestApi.single(id)
      .then((res) => {
        setReadData(res.data.data);
        setIsLoadingRead(false);
      })
      .catch(() => {
        setAlertConfig({
          variant: "danger",
          text: "Purchase Request gagal dimuat",
        });
        setIsLoadingRead(false);
      });
  };

  useEffect(() => {
    readModalShow && getReadData(idPurchase);
  }, [idPurchase]);

  const FormCard = () => {
    const InfoItem = ({ title1, value1, title2, value2 }) => (
      <div className="mb-2">
        <small>{title1}</small>
        <br />
        <b>{value1}</b>
        <div className="mt-1" />
        <small>{title2}</small>
        <br />
        <b>{value2}</b>
      </div>
    );

    return (
      <Card className="mt-4 mb-5">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Catatan Approval Purchase Request</b>
        </Card.Header>
        <Card.Body>
          <Row>
            {readData.stakeholder === undefined
              ? "Memuat Data"
              : readData.stakeholder.map((val, index) => (
                  <Col sm>
                    <InfoItem
                      title1={
                        val.status_approval === "VER" ||
                        val.status_approval === "REV"
                          ? `Pemeriksa ${
                              val.approval_level !== "0"
                                ? val.approval_level
                                : ""
                            }`
                          : val.status_approval === "APP"
                          ? "Pengesah"
                          : val.status_approval === "PEN"
                          ? "Pembuat"
                          : "Di Tolak Oleh"
                      }
                      value1={val.nama_karyawan ?? "-"}
                      title2="Catatan"
                      value2={val.catatan ?? "-"}
                    />
                  </Col>
                ))}
          </Row>
        </Card.Body>
      </Card>
    );
  };

  const ShowData = ({ title, text }) => (
    <div className="d-flex flex-row">
      <div className="text-secondary mb-2 mr-3" style={{ width: 170 }}>
        {title}
      </div>
      <span>:</span>
      <div
        className="text-dark"
        style={{
          paddingLeft: 5,
        }}
      >
        {text ? text : "-"}
      </div>
    </div>
  );

  return (
    <>
      <Modal
        size="xl"
        show={readModalShow}
        onHide={() => {
          setReadModalShow(false);
        }}
      >
        <Modal.Header closeButton>
          <b>Detail Data Dokumen Purchase Request</b>
        </Modal.Header>
        <Modal.Body>
          {isLoadingRead == true ? (
            <DataStatus loading={true} text="Memuat data..." />
          ) : (
            <>
              <div className="mb-2">
                <Row>
                  <Col lg="6">
                    <ShowData
                      title="Tgl. Purchase Request"
                      text={
                        DateConvert(new Date(readData.tgl_purchase_request))
                          .detail
                      }
                    />
                  </Col>
                  <Col lg="6">
                    <ShowData
                      title="No. Purchase Request"
                      text={readData.no_purchase_request}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="6">
                    <ShowData
                      title="Tgl. Pemakaian"
                      text={
                        DateConvert(new Date(readData.tgl_pemakaian)).detail
                      }
                    />
                  </Col>
                  <Col lg="6">
                    <ShowData title="Keperluan" text={readData.keperluan} />
                  </Col>
                </Row>

                <CRUDLayout.Table>
                  <THead>
                    <Tr className="text-center">
                      <ThFixed>No</ThFixed>
                      <Th>Kode Item</Th>
                      <Th>Nama Item</Th>
                      <Th>Satuan</Th>
                      <Th>Qty</Th>
                    </Tr>
                  </THead>
                  <TBody>
                    {readData.detail.map((val, index) => (
                      <Tr key={index}>
                        <TdFixed>{index + 1}</TdFixed>
                        <TdFixed>{val.kode_item}</TdFixed>
                        <Td>
                          {/* {val.dimensi === "3"
                              ? `${val.nama_item} (${val.pj} X ${val.lb} X ${val.tb})`
                              : val.dimensi === "0"
                                ? val.nama_item
                                : val.dimensi === "1"
                                  ? `${val.nama_item} (${val.pj})`
                                  : val.dimensi === "2"
                                    ? `${val.nama_item} (${val.pj} X ${val.lb})`
                                    : ""} */}
                          {val.nama_item}
                        </Td>
                        <Td>{val.nama_satuan}</Td>
                        <Td>
                          <div className="text-right">{val.qty}</div>
                        </Td>
                      </Tr>
                    ))}
                  </TBody>
                </CRUDLayout.Table>

                <div>
                  <strong>Catatan : </strong>
                  <br></br>
                  {readData.catatan_purchase_request}
                </div>
              </div>
              <FormCard />
            </>
          )}
        </Modal.Body>

        {isPrint === true ? (
          <>
            <div style={{ display: "none" }}>
              <CetakPurchaseRequest
                ref={componentRef}
                idRef={printData}
                // idVendor={printVendorData}
                data={readData}
              />
            </div>
          </>
        ) : (
          ""
        )}

        {!isLoadingRead && data?.status_approval?.toUpperCase() == "APP" ? (
          <Modal.Footer>
            <ActionButton
              type="button"
              variant="primary"
              text="Cetak Purchase Request"
              onClick={() => {
                handlePrint();
                handleDataPrint(idPurchase);
              }}
            />
          </Modal.Footer>
        ) : (
          <></>
        )}
      </Modal>
    </>
  );
};

const ModalStatus = ({
  modalShow,
  idPurchase,
  setAlertConfig,
  setModalShow,
  modalStatus,
}) => {
  const [dataProgress, setDataProgress] = useState([]);

  const getProgress = () => {
    PurchaseRequestApi.getProgress(idPurchase)
      .then((res) => {
        setDataProgress(res.data.data);
      })
      .catch((err) => {
        setAlertConfig(err);
      })
      .finally(() => {});
  };

  useEffect(() => {
    modalShow && getProgress();
    return () => {};
  }, [idPurchase]);

  return (
    <>
      <Modal
        show={modalShow}
        onHide={() => {
          setModalShow(false);
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-info">
            {modalStatus === "PENDING" ? (
              <h6 className="mb-0 text-info">
                <IoReload className="mb-1 mr-2" size={20} />
                Progress (Status ON PROGRESS)
              </h6>
            ) : modalStatus === "DONE" ? (
              <h6 className="mb-0 text-success">
                <IoCheckmarkCircleOutline className="mb-1 mr-2" size={20} />
                Progress (status DONE)
              </h6>
            ) : (
              <h6 className="mb-0 text-danger">
                <IoCloseCircleOutline className="mb-1 mr-2" size={20} />
                Progress (status CANCEL)
              </h6>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CRUDLayout.Table>
            <THead>
              <Tr className="text-center">
                <ThFixed>No</ThFixed>
                <Th>Nama proses</Th>
                <Th>Tanggal proses</Th>
                <Th>No dokumen</Th>
              </Tr>
            </THead>
            <TBody>
              {dataProgress !== undefined
                ? dataProgress.map((val, index) => (
                    <Tr key={index}>
                      <TdFixed>{index + 1}</TdFixed>
                      <Td>{val.nama_proses}</Td>
                      <Td>{DateConvert(new Date(val.tgl_proses)).detail}</Td>
                      <Td>{val.no_dokumen}</Td>
                    </Tr>
                  ))
                : ""}
            </TBody>
          </CRUDLayout.Table>
          <div className="d-flex justify-content-end">
            <BackButton
              onClick={() => {
                setModalShow(false);
              }}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const PurchaseRequest = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  let createAlert = location?.state?.variant;

  // indikator pemanggilan data sedang dimuat di server (loading)
  const [isLoading, setIsLoading] = useState(true);
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [showModalExport, setShowModalExport] = useState({
    show: false,
    type: "",
    status: "",
  });
  const [loadingExport, setLoadingExport] = useState(false);

  // menampung value dari search form
  const [isSearching, setIsSearching] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [searchTime, setSearchTime] = useState(null);

  // data
  const [data, setData] = useState([]);
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: location?.state?.pr?.filter?.active,
      tgl_purchase_request_mulai:
        location?.state?.pr?.filter?.tgl_purchase_request_mulai,
      tgl_purchase_request_selesai:
        location?.state?.pr?.filter?.tgl_purchase_request_selesai,
      tgl_pemakaian_mulai: location?.state?.pr?.filter?.tgl_pemakaian_mulai,
      tgl_pemakaian_selesai: location?.state?.pr?.filter?.tgl_pemakaian_selesai,
      keperluan: location?.state?.pr?.filter?.keperluan,
      status_approval: location?.state?.pr?.filter?.status_approval,
      item_barang: location?.state?.pr?.filter?.item_barang,
    },
    pagination: {
      page: location?.state?.pr?.filter?.page ?? "1",
      dataLength: location?.state?.pr?.filter?.dataLength ?? "10",
      totalPage: location?.state?.pr?.filter?.totalPage ?? "1",
      dataCount: location?.state?.pr?.filter?.dataCount ?? "0",
    },
  });
  // menampilkan alert
  const [showAlert, setShowAlert] = useState(false);
  // configurasi alert
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  });

  const [modalStatus, setModalStatus] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [dataModal, setDataModal] = useState({});
  const [readModalShow, setReadModalShow] = useState(false);
  const [idPurchase, setIdPurchase] = useState("");

  // Print

  const [deleteData, setDeleteData] = useState([]);
  const [isDeleteData, setIsDeleteData] = useState(false);

  // request data dari server
  const getData = () => {
    setIsLoading(true);
    setIsSearching(false);
    // setAlertConfig(false);

    // request data ke server
    Axios.all([
      PurchaseRequestApi.getPage({
        q: searchKey,
        page: dataFilter?.pagination?.page,
        per_page: dataFilter?.pagination?.dataLength,
        tgl_purchase_request_mulai:
          dataFilter?.filter?.tgl_purchase_request_mulai,
        tgl_purchase_request_selesai:
          dataFilter?.filter?.tgl_purchase_request_selesai,
        tgl_pemakaian_mulai: dataFilter?.filter?.tgl_pemakaian_mulai,
        tgl_pemakaian_selesai: dataFilter?.filter?.tgl_pemakaian_selesai,
        keperluan: dataFilter?.filter?.keperluan,
        status_approval: dataFilter?.filter?.status_approval,
        item_barang: dataFilter?.filter?.item_barang,
      }),
    ])
      .then(
        Axios.spread((res) => {
          setData(res?.data?.data ?? []);
          setDataFilter({
            ...dataFilter,
            pagination: {
              ...dataFilter.pagination,
              dataCount: res?.data?.data_count,
              totalPage: res?.data?.total_page,
            },
          });
        })
      )
      .catch(() => {
        setAlertConfig({
          variant: "danger",
          text: "Data gagal dimuat",
        });
        setShowAlert(true);
      })
      .finally(() => {
        if (searchKey !== "") {
          setAlertConfig({
            variant: "primary",
            text: `Hasil Pencarian : ${searchKey}`,
          });
          setShowAlert(true);
        } else {
          setShowAlert(false);
        }
        setIsLoading(false);
      });
  };

  const onInputSearch = (text) => {
    const key = text.target.value;
    if (searchTime) clearTimeout(searchTime);
    setSearchTime(
      setTimeout(() => {
        setSearchKey(key);
        setAlertConfig({
          variant: "primary",
          text: `Hasil Pencarian : ${key}`,
        });
        setShowAlert(true);
      }, 500)
    );
  };

  useEffect(() => {
    // set Judul di Navbar
    setNavbarTitle("Purchase Request");

    if (createAlert !== undefined) {
      setAlertConfig({
        variant: location?.state?.variant,
        text: location?.state?.text,
      });
      setShowAlert(true);
    } else {
      return getData();
    }

    getData();

    return () => {
      history.replace((createAlert = undefined));
      setIsLoading(false);
      // setIsSearching(false);
      // setShowAlert(false);
    };
  }, [
    setNavbarTitle,
    searchKey,
    dataFilter?.pagination?.page,
    dataFilter?.pagination?.dataLength,
    dataFilter?.filter?.active,
    dataFilter?.filter?.tgl_purchase_request_mulai,
    dataFilter?.filter?.tgl_purchase_request_selesai,
    dataFilter?.filter?.tgl_pemakaian_mulai,
    dataFilter?.filter?.tgl_pemakaian_selesai,
    dataFilter?.filter?.keperluan,
    dataFilter?.filter?.status_approval,
    dataFilter?.filter?.item_barang,
  ]);

  // EXPORT DATA
  const exportData = (file) => {
    setLoadingExport(true);
    setShowModalExport({ ...showModalExport, show: true, type: "loading" });

    PurchaseRequestApi.export({
      active: undefined,
      tgl_purchase_request_mulai:
        dataFilter?.filter?.tgl_purchase_request_mulai,
      tgl_purchase_request_selesai:
        dataFilter?.filter?.tgl_purchase_request_selesai,
      tgl_pemakaian_mulai: dataFilter?.filter?.tgl_pemakaian_mulai,
      tgl_pemakaian_selesai: dataFilter?.filter?.tgl_pemakaian_selesai,
      keperluan: dataFilter?.filter?.keperluan,
      status_approval: dataFilter?.filter?.status_approval,
      item_barang: dataFilter?.filter?.item_barang,
      tipe: file,
    })
      .then((res) => {
        const link = res?.data?.data;
        console.log(link);
        const parse =
          "https://" +
          link
            .split("/")
            .filter((_val, idx) => idx !== 0)
            .filter((_val, idx) => idx !== 0)
            .join("/");
        saveAs(parse, "PurchaseRequest");
        setShowModalExport({
          ...showModalExport,
          show: true,
          type: "berhasil",
        });
      })
      .catch(() => {
        setShowModalExport({ ...showModalExport, show: true, type: "gagal" });
      })
      .finally(() => setLoadingExport(false));
  };
  // MODAL EXPORT DATA
  const ModalStatusExport = () => {
    return (
      <Modal
        show={showModalExport.show}
        onHide={() => setShowModalExport({ ...showModalExport, show: false })}
        backdrop={showModalExport.type === "loading" ? "static" : true}
      >
        {showModalExport.type === "loading" ? (
          <Modal.Header>
            <Modal.Title>
              <span style={{ fontSize: "15px" }}>
                <b>Export Data Purchase Request</b>
              </span>
            </Modal.Title>
          </Modal.Header>
        ) : (
          <Modal.Header closeButton>
            <Modal.Title>
              <span style={{ fontSize: "15px" }}>
                <b>Export Data Purchase Request</b>
              </span>
            </Modal.Title>
          </Modal.Header>
        )}
        {showModalExport.type === "loading" ? (
          <Modal.Body>
            <div className="text-center">
              <Spinner />
              <p className="mt-4" style={{ fontSize: "15px" }}>
                <b>Proses Export Sedang Berjalan, Mohon Ditunggu</b>
              </p>
            </div>
          </Modal.Body>
        ) : showModalExport.type === "berhasil" ? (
          <Modal.Body>
            <div className="text-center">
              <AiFillCheckCircle
                style={{ fontSize: "80px", color: "#14C38E" }}
              />
              <p className="mt-4" style={{ fontSize: "15px" }}>
                <b>Export Berhasil</b>
              </p>
            </div>
          </Modal.Body>
        ) : (
          showModalExport.type === "gagal" && (
            <Modal.Body>
              <div className="text-center">
                <AiFillCloseCircle
                  style={{ fontSize: "80px", color: "#EB1D36" }}
                />
                <p className="mt-4" style={{ fontSize: "15px" }}>
                  <b>Export Gagal</b>
                </p>
              </div>
            </Modal.Body>
          )
        )}
      </Modal>
    );
  };

  // MODAL HAPUS COMPONENT
  const HapusModal = () => {
    // SET DATA ID YANG DIHAPUS
    const deleteValue = { id_purchase_request: deleteData.id_purchase_request };

    // MENANGANI DELETE BUTTON LOADING
    const [btnLoading, setBtnLoading] = useState(false);

    // DELETE DATA DARI SERVER
    const deleteDataHandler = () => {
      setBtnLoading(true);

      PurchaseRequestApi.delete(deleteValue)
        .then(() => {
          // KONFIGURASI ALERT
          setAlertConfig({
            variant: "primary",
            text: "Hapus data berhasil!",
          });
        })
        .catch((err) => {
          // KONFIGURASI ALERT
          setAlertConfig({
            variant: "danger",
            text: `Hapus data gagal!(${err.response.data.message})`,
          });
        })
        .finally(() => {
          // CLOSE MODAL
          setIsDeleteData(false);
          // TAMPIL ALERT
          setShowAlert(true);
          // FETCH DATA DARI SERVER
          getData();
        });
    };
    return (
      <DeleteModal
        show={isDeleteData}
        onHide={() => setIsDeleteData(false)}
        loading={btnLoading}
        onConfirm={deleteDataHandler}
        // title={title}
      >
        <div>
          Tgl. Purchase Request :{" "}
          {DateConvert(new Date(deleteData.tgl_purchase_request)).detail}
        </div>
        <div>No. Purchase Request : {deleteData.no_purchase_request}</div>
      </DeleteModal>
    );
  };

  const decimalConvert = (value) => {
    const convertToNumber = parseFloat(value);
    const checkConvert =
      convertToNumber === NaN ? 0 : parseFloat(String(convertToNumber));

    return new Intl.NumberFormat("id-ID").format(checkConvert);
  };

  const Table = () => {
    const checkStatus = (stat) => {
      const data = stat ? stat.toUpperCase() : "";
      const convert = data.substring(0, 3);

      if (convert === "APP")
        return {
          variant: "outline-approved",
          label: "APPROVED",
        };
      // return "APPROVED";
      if (convert === "VER")
        return {
          variant: "outline-verified",
          label: "VERIFIED",
        };
      // return "VERIFIED";
      if (convert === "REV")
        return {
          variant: "outline-revised",
          label: "REVISION",
        };
      // return "REVISI";
      if (convert === "REJ")
        return {
          variant: "outline-danger",
          label: "REJECTED",
        };
      // return "REJECT";

      return {
        variant: "outline-pending",
        label: "PENDING",
      };
      // return "PENDING";
    };
    const ItemBarangCollapse = ({ data }) => {
      const [isReadMoreClick, setisReadMoreClick] = useState(false);

      return (
        <>
          <ul className="pl-3 mb-1" style={{ fontSize: "12px" }}>
            {data.map((brg, index) => {
              if (isReadMoreClick) {
                return (
                  <li key={index} index={index}>
                    {`${brg.nama_item} ${
                      brg.qty ? decimalConvert(parseFloat(brg.qty)) : ""
                    } ${brg.nama_satuan ? brg.nama_satuan : ""}` ?? "-"}
                  </li>
                );
              } else {
                return (
                  index <= 1 && (
                    <li key={index} index={index}>
                      {`${brg.nama_item} ${
                        brg.qty ? decimalConvert(parseFloat(brg.qty)) : ""
                      } ${brg.nama_satuan ? brg.nama_satuan : ""}` ?? "-"}
                    </li>
                  )
                );
              }
            })}
          </ul>
          {data.length > 2 ? (
            <div
              className="text-primary"
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                // fontWeight: "bold",
              }}
              onClick={() => {
                setisReadMoreClick((prev) => !prev);
              }}
            >
              {isReadMoreClick ? "Sembunyikan" : "Selengkapnya..."}
            </div>
          ) : (
            ""
          )}
        </>
      );
    };
    return (
      <>
        <span style={{ fontSize: "14px" }}>
          <b>List Data Purchase Request</b>
        </span>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <ThFixed>Informasi Purchase Request</ThFixed>
              <Th>Item Barang</Th>
              <Th width={100}>Keperluan</Th>
              <ThFixed>Tanggal Pemakaian</ThFixed>
              <ThFixed>Status Approval</ThFixed>
              {/* <Th>Status Progress</Th> */}
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => (
              <Tr key={index}>
                <TdFixed>
                  {TableNumber(
                    dataFilter?.pagination?.page,
                    dataFilter?.pagination?.dataLength,
                    index
                  )}
                </TdFixed>
                <TdFixed>
                  <div className="d-flex">
                    <ReadButton
                      onClick={() => {
                        history.push(
                          `/inventory/transaksi/purchase-request/detail/${val.id_purchase_request}`
                        );
                        // setReadModalShow(true);
                        // setIdPurchase(val.id_purchase_request);
                        // handleDataPrint(val.id_purchase_request);
                      }}
                    />
                    {val.status_approval == "REV" ? (
                      <UpdateButton
                        onClick={() =>
                          history.push(
                            `/inventory/transaksi/purchase-request/${val.id_purchase_request}`,
                            { ...location?.state, pr: dataFilter }
                          )
                        }
                      />
                    ) : (
                      <></>
                    )}
                    {/* <DeleteButton
                      onClick={() => {
                        setDeleteData(val);
                        setIsDeleteData(true);
                      }}
                    /> */}
                  </div>
                </TdFixed>
                <TdFixed>
                  <div className="text-left">
                    {val.tgl_purchase_request
                      ? DateConvert(new Date(val.tgl_purchase_request))
                          .defaultDMY
                      : "-"}
                  </div>
                  <div className="text-left">
                    {val.no_purchase_request ?? "-"}
                  </div>
                </TdFixed>
                <Td>
                  {val.item_barang ? (
                    val.item_barang.length > 0 ? (
                      <ItemBarangCollapse data={val.item_barang} />
                    ) : (
                      "-"
                    )
                  ) : (
                    "-"
                  )}
                </Td>
                <Td>
                  {val.keperluan.charAt(0).toUpperCase() +
                    val.keperluan.slice(1) ?? "-"}
                </Td>
                <TdFixed>
                  {DateConvert(new Date(val.tgl_pemakaian)).defaultDMY}
                </TdFixed>
                <TdFixed>
                  <ApprovalStatusButton
                    variant={checkStatus(val.status_approval).variant}
                  >
                    {checkStatus(val.status_approval).label}
                  </ApprovalStatusButton>
                </TdFixed>
              </Tr>
            ))}
          </TBody>
        </CRUDLayout.Table>
        {!isSearching && (
          <Pagination
            dataLength={dataFilter?.pagination?.dataLength}
            dataNumber={
              dataFilter?.pagination?.page *
                dataFilter?.pagination?.dataLength -
              dataFilter?.pagination?.dataLength +
              1
            }
            dataPage={
              dataFilter?.pagination?.dataCount <
              dataFilter?.pagination?.dataLength
                ? dataFilter?.pagination?.dataCount
                : dataFilter?.pagination?.page *
                  dataFilter?.pagination?.dataLength
            }
            dataCount={dataFilter?.pagination?.dataCount}
            currentPage={dataFilter?.pagination?.page}
            totalPage={dataFilter?.pagination?.totalPage}
            onPaginationChange={({ selected }) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: selected + 1,
                },
              })
            }
            onDataLengthChange={(e) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: 1,
                  dataLength: e.target.value,
                },
              })
            }
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              onChange={onInputSearch}
              onSubmit={(e) => e.preventDefault()}
            />
            <FilterButton
              active={dataFilter?.filter?.active}
              onClick={() => setShowModalFilter(true)}
            />
            <DropdownButton
              className="ml-3"
              variant="warning text-white"
              title={<span className="text-white mr-3">EXPORT</span>}
            >
              <Dropdown.Item onClick={() => exportData("excel")}>
                Excel (.xlsx)
              </Dropdown.Item>
              <Dropdown.Item onClick={() => exportData("pdf")}>
                PDF (.pdf)
              </Dropdown.Item>
            </DropdownButton>
          </div>
        </CRUDLayout.HeadSearchSection>

        <CRUDLayout.HeadButtonSection>
          {/* <ExportButton /> */}
          <CreateButton
            onClick={() => {
              history.push("/inventory/transaksi/purchase-request/tambah", {
                ...location?.state,
                pr: dataFilter,
              });
            }}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>
      {/* ALert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />
      {isLoading === true ? (
        <DataStatus loading={true} text="Memuat data..." />
      ) : data.length > 0 ? (
        <Table />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}
      {readModalShow && (
        <ReadModal
          readModalShow={readModalShow}
          idPurchase={idPurchase}
          setAlertConfig={setAlertConfig}
          setReadModalShow={setReadModalShow}
          setIdPurchase={setIdPurchase}
          data={dataModal}
        />
      )}
      <ModalStatus
        modalShow={modalShow}
        idPurchase={idPurchase}
        setAlertConfig={setAlertConfig}
        setModalShow={setModalShow}
        modalStatus={modalStatus}
      />
      <HapusModal />
      {showModalFilter && (
        <ModalFilter
          show={showModalFilter}
          setShow={setShowModalFilter}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}
      <ModalStatusExport />
    </CRUDLayout>
  );
};

export default PurchaseRequest;

// const reactToPrint = useReactToPrint({
//   content: () => componentRef.current,
//   onAfterPrint: () => setIsPrint(false),
// });

// const handleDataPrint = (id) => {
//   setPrintData(id);
//   setIsPrint(true);
// };

// const handlePrint = () => {
//   reactToPrint();
// };

// // MODAL TAMBAH COMPONENT
// const ReadModal = () => {
//   const [isLoadingRead, setIsLoadingRead] = useState(false);
//   useEffect(() => {
//     setIsLoadingRead(true);

//     getReadData(idPurchase);
//   }, []);

//   const [readData, setReadData] = useState({
//     detail: [],
//   });
//   const getReadData = (id) => {
//     setReadData({
//       detail: [],
//     });

//     PurchaseRequestApi.single(id)
//       .then((res) => {
//         setReadData(res.data.data);
//         setIsLoadingRead(false);
//       })
//       .catch(() => {
//         setAlertConfig({
//           variant: "danger",
//           text: "Purchase Request gagal dimuat",
//         });
//       });
//   };

//   const FormCard = () => {
//     const InfoItem = ({ title1, value1, title2, value2 }) => (
//       <div className="mb-2">
//         <small>{title1}</small>
//         <br />
//         <b>{value1}</b>
//         <div className="mt-1" />
//         <small>{title2}</small>
//         <br />
//         <b>{value2}</b>
//       </div>
//     );

//     return (
//       <Card className="mt-4 mb-5">
//         <Card.Header className="d-flex justify-content-between align-items-center">
//           <b>Catatan Approval Purchase Request</b>
//         </Card.Header>
//         <Card.Body>
//           <Row>
//             {readData.stakeholder === undefined
//               ? "Memuat Data"
//               : readData.stakeholder.map(
//                   (val, index) =>
//                     index !== 0 &&
//                     val.status_approval !== "PEN" && (
//                       <Col sm>
//                         <InfoItem
//                           title1={
//                             val.status_approval === "VER" || val.status_approval === "REV"
//                               ? `Pemeriksa ${
//                                   val.approval_level !== "0" ? val.approval_level : ""
//                                 }`
//                               : val.status_approval === "APP"
//                               ? "Pengesah"
//                               : "Di Tolak Oleh"
//                           }
//                           value1={val.nama_karyawan ?? "-"}
//                           title2="Catatan"
//                           value2={val.catatan ?? "-"}
//                         />
//                       </Col>
//                     )
//                 )}
//           </Row>
//         </Card.Body>
//       </Card>
//     );
//   };

//   const ShowData = ({ title, text }) => (
//     <div className="d-flex flex-row">
//       <div className="text-secondary mb-2 mr-3" style={{ width: 170 }}>
//         {title}
//       </div>
//       <span>:</span>
//       <div
//         className="text-dark"
//         style={{
//           paddingLeft: 5,
//         }}
//       >
//         {text ? text : "-"}
//       </div>
//     </div>
//   );

//   return (
//     <>
//       <Modal
//         size="xl"
//         show={readModalShow}
//         onHide={() => {
//           setReadModalShow(false);
//         }}
//       >
//         <Modal.Header closeButton>
//           <Modal.Title>Detail Data Dokumen Purchase Request</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           {isLoadingRead == true ? (
//             <DataStatus loading={true} text="Memuat data..." />
//           ) : (
//             <>
//               <div className="mb-2">
//                 <Row>
//                   <Col lg="6">
//                     <ShowData
//                       title="Tgl. Purchase Request"
//                       text={DateConvert(new Date(readData.tgl_purchase_request)).detail}
//                     />
//                   </Col>
//                   <Col lg="6">
//                     <ShowData title="No. Purchase Request" text={readData.no_purchase_request} />
//                   </Col>
//                 </Row>

//                 <Row>
//                   <Col lg="6">
//                     <ShowData
//                       title="Tgl. Pemakaian"
//                       text={DateConvert(new Date(readData.tgl_pemakaian)).detail}
//                     />
//                   </Col>
//                   <Col lg="6">
//                     <ShowData title="Keperluan" text={readData.keperluan} />
//                   </Col>
//                 </Row>

//                 <CRUDLayout.Table>
//                   <THead>
//                     <Tr className="text-center">
//                       <ThFixed>No</ThFixed>
//                       <Th>Kode Item</Th>
//                       <Th>Nama Item</Th>
//                       <Th>Satuan</Th>
//                       <Th>Qty</Th>
//                     </Tr>
//                   </THead>
//                   <TBody>
//                     {readData.detail.map((val, index) => (
//                       <Tr key={index}>
//                         <TdFixed>{index + 1}</TdFixed>
//                         <TdFixed>{val.kode_item}</TdFixed>
//                         <Td>
//                           {/* {val.dimensi === "3"
//                             ? `${val.nama_item} (${val.pj} X ${val.lb} X ${val.tb})`
//                             : val.dimensi === "0"
//                               ? val.nama_item
//                               : val.dimensi === "1"
//                                 ? `${val.nama_item} (${val.pj})`
//                                 : val.dimensi === "2"
//                                   ? `${val.nama_item} (${val.pj} X ${val.lb})`
//                                   : ""} */}
//                           {val.nama_item}
//                         </Td>
//                         <Td>{val.nama_satuan}</Td>
//                         <Td>
//                           <div className="text-right">{val.qty}</div>
//                         </Td>
//                       </Tr>
//                     ))}
//                   </TBody>
//                 </CRUDLayout.Table>

//                 <div>
//                   <strong>Catatan : </strong>
//                   <br></br>
//                   {readData.catatan_purchase_request}
//                 </div>
//               </div>
//               <FormCard />
//             </>
//           )}
//         </Modal.Body>

//         {readData.status_approval == "app" ? (
//           <Modal.Footer>
//             <ActionButton
//               type="button"
//               variant="primary"
//               text="Cetak Purchase Request"
//               onClick={handlePrint}
//             />
//           </Modal.Footer>
//         ) : (
//           <></>
//         )}
//       </Modal>
//     </>
//   );
// };

// const ModalStatus = () => {
//   const [dataProgress, setDataProgress] = useState([]);

//   const getProgress = () => {
//     PurchaseRequestApi.getProgress(idPurchase)
//       .then((res) => {
//         setDataProgress(res.data.data);
//       })
//       .catch((err) => {
//         setAlertConfig(err);
//       })
//       .finally(() => {});
//   };

//   useEffect(() => {
//     getProgress();
//     return () => {};
//   }, []);

//   return (
//     <>
//       <Modal
//         show={modalShow}
//         onHide={() => {
//           setModalShow(false);
//         }}
//         size="lg"
//       >
//         <Modal.Header closeButton>
//           <Modal.Title className="text-info">
//             {modalStatus === "PENDING" ? (
//               <h6 className="mb-0 text-info">
//                 <IoReload className="mb-1 mr-2" size={20} />
//                 Progress (Status ON PROGRESS)
//               </h6>
//             ) : modalStatus === "DONE" ? (
//               <h6 className="mb-0 text-success">
//                 <IoCheckmarkCircleOutline className="mb-1 mr-2" size={20} />
//                 Progress (status DONE)
//               </h6>
//             ) : (
//               <h6 className="mb-0 text-danger">
//                 <IoCloseCircleOutline className="mb-1 mr-2" size={20} />
//                 Progress (status CANCEL)
//               </h6>
//             )}
//           </Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <CRUDLayout.Table>
//             <THead>
//               <Tr className="text-center">
//                 <ThFixed>No</ThFixed>
//                 <Th>Nama proses</Th>
//                 <Th>Tanggal proses</Th>
//                 <Th>No dokumen</Th>
//               </Tr>
//             </THead>
//             <TBody>
//               {dataProgress !== undefined
//                 ? dataProgress.map((val, index) => (
//                     <Tr key={index}>
//                       <TdFixed>{index + 1}</TdFixed>
//                       <Td>{val.nama_proses}</Td>
//                       <Td>{DateConvert(new Date(val.tgl_proses)).detail}</Td>
//                       <Td>{val.no_dokumen}</Td>
//                     </Tr>
//                   ))
//                 : ""}
//             </TBody>
//           </CRUDLayout.Table>
//           <div className="d-flex justify-content-end">
//             <BackButton
//               onClick={() => {
//                 setModalShow(false);
//               }}
//             />
//           </div>
//         </Modal.Body>
//       </Modal>
//     </>
//   );
// };
