import { useContext, useEffect, useState } from "react";
import { Form, Modal, Row, Col } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import {
  ActionButton,
  Alert,
  InfoItemVertical,
  InfoItemHorizontal,
  DatePicker,
  Input,
  SelectSearch,
  InputQtyDecimal,
  TextArea,
  AlertAlreadyStockOpname,
} from "components";
import { Formik, useFormikContext } from "formik";
import * as yup from "yup";
import { TransferBahanWorkOrderApi } from "api";
import { DateConvert, DecimalConvert } from "utilities";
import { DropdownContext } from "../context/DropdownContext";

export const ModalFormTransfer = ({
  data,
  toggle,
  isCreate,
  title,
  toggleSuccess,
  getLastQtyTransfer,
}) => {
  const location = useLocation();
  const history = useHistory();
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  return (
    <Modal show={true} onHide={toggle}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h6 className="m-0">
            {isCreate ? "Tambah" : "Detail"} {title}
          </h6>
        </Modal.Title>
      </Modal.Header>

      <Formik
        enableReinitialize
        initialValues={{
          ...data,
          tgl_transfer_work_order_bahan: isCreate
            ? undefined
            : data?.tgl_transfer_work_order_bahan,
          no_transfer_work_order_bahan: isCreate
            ? "Pilih tanggal terlebih dahulu"
            : data?.no_transfer_work_order_bahan,
          qty_transfer_work_order_bahan: isCreate
            ? ""
            : data?.qty_transfer_work_order_bahan,
          keterangan_transfer: isCreate ? "" : data?.keterangan_transfer,
          qty_telah_ditransfer:
            data?.qty_transfer_work_order_bahan ??
            data?.qty_telah_ditransfer_work_order_bahan ??
            0,
          id_gudang_asal: "",
          id_gudang_tujuan: "",
          id_karyawan: "",
          stok_gudang: "",
          nama_proyek: data?.nama_proyek ?? "-",
        }}
        validationSchema={yup.object().shape({
          tgl_permintaan_work_order_bahan: yup
            .date()
            .typeError("Masukkan Tanggal")
            .required("Masukkan Tanggal"),
          qty_transfer_work_order_bahan: yup
            .number()
            .max(
              parseFloat(data?.qty_permintaan_work_order_bahan ?? 0) -
                parseFloat(data?.qty_transfer_work_order_bahan ?? 0),
              `Qty. Transfer wajib diisi maksimal ${
                parseFloat(data?.qty_permintaan_work_order_bahan ?? 0) -
                parseFloat(data?.qty_transfer_work_order_bahan ?? 0)
              }`
            )
            .test({
              name: "cek-gudang",
              message: "Stok gudang tidak mencukupi",
              test: (val, parent) =>
                parent?.parent?.stok_gudang
                  ? parseFloat(parent?.parent?.stok_gudang) >=
                    parseFloat(val ?? 0)
                  : false,
            })
            .required("Qty. Transfer wajib diisi"),
          id_gudang_asal: yup.string().required("Pilih Gudang Asal"),
          id_gudang_tujuan: yup.string().required("Pilih Gudang Tujuan"),
          id_karyawan: yup.string().required("Pilih Karyawan"),
        })}
        onSubmit={(values, { setSubmitting }) => {
          if (isCreate) {
            TransferBahanWorkOrderApi.save(values)
              .then(() => {
                if (getLastQtyTransfer?.length <= 1) {
                  history.replace(
                    "/inventory/transaksi/transfer-bahan-work-order/list-permintaan-work-order",
                    location.state
                  );
                } else {
                  toggleSuccess();
                  toggle();
                }
              })
              .catch(() => {
                setAlertConfig({
                  show: true,
                  text: "Tambah data gagal",
                  variant: "danger",
                });
              })
              .finally(() => setSubmitting(false));
          }
        }}
      >
        {({ values, handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Body>
              <Alert
                show={alertConfig.show}
                variant={alertConfig.variant}
                text={alertConfig.text}
                showCloseButton={true}
                onClose={() => {
                  setAlertConfig({
                    ...alertConfig,
                    show: false,
                  });
                }}
              />

              <InfoItemHorizontal
                label="Tgl. Permintaan Work Order"
                text={
                  values?.tgl_permintaan_work_order_bahan
                    ? DateConvert(
                        new Date(values.tgl_permintaan_work_order_bahan)
                      ).detail
                    : "-"
                }
              />

              <InfoItemHorizontal
                label="No. Permintaan Work Order"
                text={values.no_permintaan_work_order_bahan ?? "-"}
              />

              <InfoItemHorizontal
                label="Item Bahan"
                text={values.nama_item ?? "-"}
              />

              <InfoItemHorizontal
                label="Satuan"
                text={values.nama_satuan ?? "-"}
              />

              <InfoItemHorizontal
                label="Qty. Permintaan Work Order"
                text={
                  DecimalConvert(values.qty_permintaan_work_order_bahan ?? 0)
                    .getWithComa
                }
              />

              <InfoItemHorizontal
                label="Qty. Telah Ditransfer"
                text={
                  DecimalConvert(data?.qty_transfer_work_order_bahan ?? 0)
                    .getWithComa
                }
              />

              <hr />

              <FormSection isCreate={isCreate} />
              <AlertAlreadyStockOpname
                tanggal={values?.tgl_transfer_work_order_bahan}
              />
            </Modal.Body>

            {isCreate && (
              <Modal.Footer>
                <ActionButton
                  type="submit"
                  text="Simpan"
                  loading={isSubmitting}
                />
              </Modal.Footer>
            )}
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export const FormSection = ({ isCreate }) => {
  const TODAY = DateConvert(new Date()).default;

  const [dataGudang, setDataGudang] = useState([]);

  const { values, setFieldValue, errors, touched, handleChange, setValues } =
    useFormikContext();

  const { dropdownKaryawan } = useContext(DropdownContext);

  const getGudang = (tgl) => {
    TransferBahanWorkOrderApi.getDropdownGudang({
      id_item_buaso: values.id_item_bahan,
      tgl: tgl,
    })
      .then((gudang) => {
        setDataGudang(
          gudang?.data?.data?.map((g) => ({
            ...g,
            value: g.id_gudang,
            label: g.nama_gudang,
          }))
        );
      })
      .catch(() => {})
      .finally(() => {});
  };

  const getNomor = (tgl) => {
    TransferBahanWorkOrderApi.no_baru(tgl).then((res) => {
      setFieldValue("no_transfer_work_order_bahan", res.data.data ?? "");
    });
  };

  useEffect(() => getGudang(TODAY), []);

  if (!isCreate) {
    return (
      <>
        <Row>
          <Col md={6} sm={6}>
            <InfoItemVertical
              className="text-capitalize"
              label="Tgl. Transfer Bahan Work Order"
              text={
                values?.tgl_transfer_work_order_bahan
                  ? DateConvert(new Date(values?.tgl_transfer_work_order_bahan))
                      .detail
                  : "-"
              }
            />
          </Col>

          <Col md={6} sm={6}>
            <InfoItemVertical
              className="text-capitalize"
              label="No. Transfer Bahan Work Order"
              text={values?.no_transfer_work_order_bahan ?? "-"}
            />
          </Col>

          <Col md={6}>
            <InfoItemVertical
              label="Gudang Asal"
              text={values.nama_gudang_asal ?? "-"}
            />
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <InfoItemVertical
              label="Gudang Tujuan"
              text={values.nama_gudang_tujuan ?? "-"}
            />
          </Col>

          <Col md={6}>
            <InfoItemVertical
              label="Qty. Transfer"
              text={`${
                DecimalConvert(values.qty_transfer_work_order_bahan ?? 0)
                  .getWithComa
              } ${values.nama_satuan ?? ""}`}
            />
          </Col>

          <Col md={6}>
            <InfoItemVertical
              label="Diserahkan Oleh"
              text={values.nama_karyawan ?? "-"}
            />
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <InfoItemVertical
              label="Keterangan Transfer"
              text={values.keterangan_transfer ?? "-"}
            />
          </Col>
        </Row>
      </>
    );
  }

  return (
    <Row>
      <Col md={6}>
        <DatePicker
          label="Tgl. Transfer Bahan Work Order"
          selected={
            values.tgl_transfer_work_order_bahan
              ? new Date(values.tgl_transfer_work_order_bahan)
              : ""
          }
          placeholderText="Pilih Tgl. Transfer Bahan Work Order "
          onChange={(date) => {
            const tanggal = DateConvert(new Date(date)).default;

            setFieldValue("tgl_transfer_work_order_bahan", tanggal);
            getGudang(tanggal);
            if (isCreate) getNomor(tanggal);
          }}
          error={
            Boolean(errors.tgl_transfer_work_order_bahan) &&
            touched.tgl_transfer_work_order_bahan
          }
          errorText={
            Boolean(errors.tgl_transfer_work_order_bahan) &&
            touched.tgl_transfer_work_order_bahan &&
            errors.tgl_transfer_work_order_bahan
          }
        />
      </Col>

      <Col md={6}>
        <Input
          label="No. Transfer Bahan Work Order"
          placeholder="No. Transfer Bahan Work Order"
          value={values.no_transfer_work_order_bahan}
          readOnly
        />
      </Col>

      <Col md={8}>
        <SelectSearch
          label="Gudang Asal"
          placeholder="Pilih Gudang Asal"
          defaultValue={dataGudang?.find(
            (e) => e.value === values.id_gudang_asal
          )}
          onChange={(val) =>
            setValues({
              ...values,
              id_gudang_asal: val.value,
              stok_gudang: val.jumlah,
              nama_satuan: val.nama_satuan,
            })
          }
          option={dataGudang ?? []}
          error={Boolean(errors.id_gudang_asal) && touched.id_gudang_asal}
          errorText={
            Boolean(errors.id_gudang_asal) &&
            touched.id_gudang_asal &&
            errors.id_gudang_asal
          }
        />
      </Col>

      <Col md={4}>
        <Input
          label="Stok Gudang"
          placeholder="Stok Gudang"
          value={
            values.stok_gudang &&
            `${DecimalConvert(values.stok_gudang ?? 0).getWithComa} ${
              values.nama_satuan
            }`
          }
          readOnly
        />
      </Col>

      <Col md={8}>
        <SelectSearch
          label="Gudang Tujuan"
          placeholder="Pilih Gudang Tujuan"
          defaultValue={dataGudang?.find(
            (e) => e.value === values.id_gudang_tujuan
          )}
          onChange={(val) => setFieldValue("id_gudang_tujuan", val.value)}
          option={dataGudang ?? []}
          error={Boolean(errors.id_gudang_tujuan) && touched.id_gudang_tujuan}
          errorText={
            Boolean(errors.id_gudang_tujuan) &&
            touched.id_gudang_tujuan &&
            errors.id_gudang_tujuan
          }
        />
      </Col>

      <Col md={4}>
        <InputQtyDecimal
          label="Qty. Transfer"
          value={values.qty_transfer_work_order_bahan}
          onChange={(e) => setFieldValue("qty_transfer_work_order_bahan", e)}
          error={
            Boolean(errors.qty_transfer_work_order_bahan) &&
            touched.qty_transfer_work_order_bahan
          }
          errorText={
            Boolean(errors.qty_transfer_work_order_bahan) &&
            touched.qty_transfer_work_order_bahan &&
            errors.qty_transfer_work_order_bahan
          }
        />
      </Col>

      <Col md={12}>
        <SelectSearch
          label="Diserahkan Oleh"
          placeholder="Pilih Karyawan"
          loading={dropdownKaryawan?.isLoading}
          defaultValue={dropdownKaryawan?.data?.find(
            (e) => e.value === values.id_karyawan
          )}
          option={dropdownKaryawan?.data}
          onChange={(val) => setFieldValue("id_karyawan", val.value)}
          error={Boolean(errors.id_karyawan) && touched.id_karyawan}
          errorText={
            Boolean(errors.id_karyawan) &&
            touched.id_karyawan &&
            errors.id_karyawan
          }
        />
      </Col>

      <Col md={12}>
        <TextArea
          label="Keterangan Transfer"
          name="keterangan_transfer"
          rows={4}
          placeholder="Keterangan Transfer"
          value={values.keterangan_transfer}
          onChange={handleChange}
          error={
            Boolean(errors.keterangan_transfer) && touched.keterangan_transfer
          }
          errorText={
            Boolean(errors.keterangan_transfer) &&
            touched.keterangan_transfer &&
            errors.keterangan_transfer
          }
        />
      </Col>
    </Row>
  );
};
