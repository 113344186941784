// React
import React, {useState, useEffect, useRef} from 'react'

// Form
import { Formik } from 'formik'

// Components
import { FilterModal, SelectSearch } from 'components'

// API
import Axios from "axios"
import { RegItemBahanApi } from 'api'

const ModalFilter = ({ show, setShow, data, setData }) => {
	// Variables
	const option = { label: 'Semua Data', value: undefined }

	// Hooks
	const firstRender = useRef(true)

	// States
	const [dropdownFilter, setDropdownFilter] = useState({
		kelompok: [option],
		jenis: [option],
		pabrikan: [option],
		satuan: [option]
	  })
	const [loading, setLoading] = useState(true)
	const [loadingJenis, setLoadingJenis] = useState(false)

	const dataJenis = value => value.map(val => {
		return {
			value: val.id_jenis,
			label: val.nama_jenis
		}
	})

	const getDataJenis = value => {
		setLoadingJenis(true)
	
		if (value) {
			RegItemBahanApi.getJenis(value).then(res => {
				setDropdownFilter({
					...dropdownFilter,
					jenis: [option, ...dataJenis(res.data.data)]
				})
			}).catch(() => {
				setDropdownFilter({
					...dropdownFilter,
					jenis: [option]
				})
			}).finally(() => setLoadingJenis(false))
		} else {
			setTimeout(() => {
				setDropdownFilter({
					...dropdownFilter,
					jenis: [option]
				})

				setLoadingJenis(false)
			}, 1000)
		}
	}

	// REQUSET DATA SERVER
	const getDataDropdown = () => {
		Axios.all([
			RegItemBahanApi.getKelompok(),
			RegItemBahanApi.getPabrikan(),
			RegItemBahanApi.getSatuan(),
			data.id_kelompok && RegItemBahanApi.getJenis(data.id_kelompok),
		])
		.then(Axios.spread((kelompok, pabrikan, satuan, jenis) => {
			setDropdownFilter({
				kelompok: [option, ...kelompok.data.data.map(val => {
					return {
						value: val.id_kelompok,
						label: val.nama_kelompok
					}
				})],
				pabrikan: [option, ...pabrikan.data.data.map(val => {
					return {
						value: val.id_pabrikan,
						label: val.nama_pabrikan
					}
				})],
				satuan: [option, ...satuan.data.data.map(val => {
					return {
						value: val.id_satuan,
						label: val.nama_satuan
					}
				})],
				jenis: data.id_kelompok ? [option, ...dataJenis(jenis.data.data)] : [option],
			})
		}))
		.finally(() => {
			setLoading(false)
			firstRender.current = false
		})
	}

	// USE EFFECT ALL DROPDOWN
    useEffect(() => {
		getDataDropdown()

		// eslint-disable-next-line
    }, [])

	// FORM VALUES
	const formInitialValues = {
		id_kelompok: data.id_kelompok,
		id_jenis: data.id_jenis,
		id_pabrikan: data.id_pabrikan,
		id_satuan_pakai: data.id_satuan_pakai,
		id_satuan_beli: data.id_satuan_beli,
		id_satuan_jual: data.id_satuan_jual
	}
	const formSubmitHandler = (values) => {
		const checkActive = !!Object.values(values).filter(val => val !== undefined).length > 0

		setData({
			...data,
			...values,
			active: checkActive,
			page: 1
		})

		setShow(false)
	}

	// HANDLE CHANGE
	const onResetButtonClick = setValues => {
		setValues({
			id_kelompok: undefined,
			id_jenis: undefined,
			id_pabrikan: undefined,
			id_satuan_pakai: undefined,
			id_satuan_beli: undefined,
			id_satuan_jual: undefined,
		})
	}

	return (
		<Formik
			enableReinitialize
			initialValues={formInitialValues}
			onSubmit={formSubmitHandler}
		>	
			{({values, handleSubmit, setFieldValue, setValues}) => (
				<FilterModal
					show={show}
					setShow={setShow}
					onResetButtonClick={() => onResetButtonClick(setValues)}
					onFilterButtonClick={handleSubmit}
				>
					<SelectSearch 
						key="1"
						label="Kelompok Barang"
						placeholder="Pilih Kelompok Barang"
						defaultValue={dropdownFilter.kelompok.find(val => val.value === values.id_kelompok)}
						option={dropdownFilter.kelompok}
						onChange={val => {
							setValues({
								...values,
								id_kelompok: val.value,
								id_jenis: undefined
							})

							getDataJenis(val.value)
						}}
						loading={loading}
					/>

					<SelectSearch 
						key="2"
						label="Jenis Barang"
						placeholder="Pilih Jenis Barang"
						defaultValue={dropdownFilter.jenis.find(val => val.value === values.id_jenis)}
						option={dropdownFilter.jenis}
						onChange={val => setFieldValue('id_jenis', val.value)}
						loading={loading || loadingJenis}
						isDisabled={!values.id_kelompok}
					/>

					<SelectSearch 
						key="3"
						label="Pabrikan"
						placeholder="Pilih Pabrikan"
						defaultValue={dropdownFilter.pabrikan.find(val => val.value === values.id_pabrikan)}
						option={dropdownFilter.pabrikan}
						onChange={val => setFieldValue('id_pabrikan', val.value)}
						loading={loading}
					/>

					<SelectSearch 
						key="4"
						label="Satuan Pakai"
						placeholder="Pilih Satuan Pakai"
						defaultValue={dropdownFilter.satuan.find(val => val.value === values.id_satuan_pakai)}
						option={dropdownFilter.satuan}
						onChange={val => setFieldValue('id_satuan_pakai', val.value)}
						loading={loading}
					/>

					<SelectSearch 
						key="5"
						label="Satuan Beli"
						placeholder="Pilih Satuan Beli"
						defaultValue={dropdownFilter.satuan.find(val => val.value === values.id_satuan_beli)}
						option={dropdownFilter.satuan}
						onChange={val => setFieldValue('id_satuan_beli', val.value)}
						loading={loading}
					/>

					<SelectSearch 
						key="6"
						label="Satuan Jual"
						placeholder="Pilih Satuan Jual"
						defaultValue={dropdownFilter.satuan.find(val => val.value === values.id_satuan_jual)}
						option={dropdownFilter.satuan}
						onChange={val => setFieldValue('id_satuan_jual', val.value)}
						loading={loading}
					/>
				</FilterModal>
			)}
		</Formik>
	)
}
export default ModalFilter