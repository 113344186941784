import Services from 'services';

class TransferBahanWorkOrderApi {
  page(params) {
    return Services.get('/transfer_work_order_bahan/page', { params });
  }

  preformPage(params) {
    return Services.get('/transfer_work_order_bahan/work_order', { params });
  }

  singlePermintaanPreform(params) {
    return Services.get('/transfer_work_order_bahan/detail_work_order', {
      params,
    });
  }

  singlePermintaan(params) {
    return Services.get('/transfer_work_order_bahan/permintaan', { params });
  }

  no_baru(params) {
    return Services.get('/transfer_work_order_bahan/no_baru', { params });
  }

  getDropdownGudang(params) {
    return Services.get('/dropdown/gudang', { params });
  }

  getDropdownBahan(params) {
    return Services.get('/dropdown/item_bahan', { params });
  }

  getDropdownAset(params) {
    return Services.get('/dropdown/item_aset', { params });
  }

  getDropdownKaryawan(params) {
    return Services.get('/dropdown/karyawan', { params });
  }

  save(data) {
    return Services.post('/transfer_work_order_bahan', data);
  }
}

export default new TransferBahanWorkOrderApi();
