import React, { Component } from "react";
import { THead, TBody, Tr, TdFixed, Th, Table } from "../../../../components";
import { DateConvert } from "../../../../utilities";

export class ShowData extends Component {
  render() {
    return (
      <div className="d-flex flex-row">
        <div className="text-secondary mb-2 mr-3" style={{ width: 200 }}>
          <span style={{ textTransform: "capitalize" }}>{this.props.title}</span>
        </div>
        <span>:</span>
        <div
          className="text-dark"
          style={{
            fontWeight: 200,
            paddingLeft: 40,
          }}
        >
          {this.props.text ? this.props.text : "-"}
        </div>
      </div>
    );
  }
}

export class PrintPengembalianBarang extends Component {
  render() {
    const data = this.props.printData;
    return (
      <div style={{ marginTop: 100 }} className="mx-4 px-2">
        <div className="row">
          <div className="col-6">
            <ShowData
              title="Tgl. pengembalian barang"
              text={DateConvert(new Date(data.tgl_pengembalian_barang)).detail}
            />
          </div>
          <div className="col">
            <ShowData title="No. pengembalian barang" text={data.no_pengembalian_barang} />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <ShowData
              title="Tgl. pembatalan faktur"
              text={DateConvert(new Date(data.tgl_pembatalan_faktur)).detail}
            />
          </div>
          <div className="col">
            <ShowData title="No. pembatalan faktur" text={data.no_pembatalan_faktur} />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <ShowData
              title="Tgl. faktur penjualan"
              text={DateConvert(new Date(data.tgl_faktur)).detail}
            />
          </div>
          <div className="col">
            <ShowData title="No. faktur penjualan" text={data.no_faktur} />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <ShowData
              title="Tgl. Delivery Order"
              text={DateConvert(new Date(data.tgl_delivery_order)).detail}
            />
          </div>
          <div className="col">
            <ShowData title="No. Delivery Order" text={data.no_delivery_order} />
          </div>
        </div>
        <div className="my-3">
          <div className="col pl-0">
            <ShowData title="Customer" text={data.nama_customer} />
          </div>
          <div className="col pl-0">
            <ShowData title="Sales" text={data.nama_sales} />
          </div>
        </div>
        <div className="mt-4">List Barang</div>
        <Table>
          <THead>
            <Th>No.</Th>
            <Th>Kode Barang</Th>
            <Th>Nama Barang</Th>
            <Th>Gudang</Th>
            <Th>Qty SO</Th>
            <Th>Satuan</Th>
          </THead>
          <TBody>
            {data?.detail?.map((val, index) => (
              <Tr key={index}>
                <TdFixed>{index + 1}</TdFixed>
                <TdFixed>{val.kode_item}</TdFixed>
                <TdFixed>{val.nama_item}</TdFixed>
                <TdFixed>{val.nama_gudang}</TdFixed>
                <TdFixed>
                  <div className="text-right">{val.qty}</div>
                </TdFixed>
                <TdFixed>{val.nama_satuan}</TdFixed>
              </Tr>
            ))}
          </TBody>
        </Table>
        <div className="row" style={{ marginTop: 50 }}>
          <div className="col-6">
            <label>Catatan :</label>
            <p>{data.catatan_delivery_order}</p>
          </div>
        </div>
      </div>
    );
  }
}

export default PrintPengembalianBarang;
