// React
import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

// Component
import {
  CRUDLayout,
  InputSearch,
  ReadButton,
  BackButton,
  TdFixed,
  DataStatus,
  Tr,
  ThFixed,
  Pagination,
  Th,
  Alert,
  Td,
  THead,
  TBody,
  ActionButton,
  FilterButton,
} from "../../../../components";
import { PageNumber, DateConvert } from "../../../../utilities";

// API
import { PenerimaanBarangApi } from "../../../../api";

// Icon
import { IoAddOutline } from "react-icons/io5";
import { ModalPurchaseOrder } from "./Comps";
import { ModalFilter } from "./components";

const ListPurchaseOrder = ({ setNavbarTitle }) => {
  // USE HISTORY
  const history = useHistory();
  const title = "List Item Purchase Order";

  const location = useLocation();
  let registerAlertConfig = location?.state?.registerAlertConfig;
  const filter = location.state?.filter ?? {};

  // indikator pemanggilan data sedang dimuat di server (loading)
  const [isLoading, setIsLoading] = useState(false);

  // menampung value dari search form
  const [isSearching, setIsSearching] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  // data jenis
  const [data, setData] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [totalData, setTotalData] = useState(10);
  const [modalFilter, setModalFilter] = useState(false);
  const [dataFilter, setDataFilter] = useState({
    active: filter.po?.active,
    page: filter.po?.page ?? 1,
    per_page: filter.po?.per_page ?? 10,
    q: filter.po?.q ?? "",
    tgl_purchase_order_mulai: filter.po?.tgl_purchase_order_mulai,
    tgl_purchase_order_selesai: filter.po?.tgl_purchase_order_selesai,
    vendor: filter.po?.vendor,
    item_barang: filter.po?.item_barang,
  });
  // menampilkan alert
  const [showAlert, setShowAlert] = useState(false);

  // configurasi alert
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  });

  // request data dari server
  const getData = () => {
    setIsLoading(true);
    setIsSearching(false);

    const filterData = { ...dataFilter };
    delete filterData.active;

    // request data ke server
    PenerimaanBarangApi.pagePO(filterData)
      .then((res) => {
        setData(res.data.data);
        setTotalPage(res.data.total_page);
        setTotalData(res.data.data_count);
      })
      .catch((err) => {
        alert(err.response.data.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const decimalConvert = (value) => {
    const convertToNumber = parseFloat(value);
    const checkConvert =
      convertToNumber === NaN ? 0 : parseFloat(String(convertToNumber));

    return new Intl.NumberFormat("id-ID").format(checkConvert);
  };

  useEffect(() => {
    // set Judul di Navbar
    setNavbarTitle(title);
    // jalankan function request data ke server
    getData();
    // SHOW ALERT, IF REGISTER ALERT IS EXIST
    if (registerAlertConfig !== undefined) {
      setAlertConfig(registerAlertConfig);
      setShowAlert(true);
    }

    return () => {
      setIsLoading(false);
      setIsSearching(false);
    };
  }, [setNavbarTitle, dataFilter]);

  // Tabel
  const Table = () => {
    const [modalConfig, setModalConfig] = useState({
      show: false,
      data: {},
    });

    return (
      <>
        <b>List PO yang Siap Dibuatkan Penerimaan Barang</b>
        <CRUDLayout.Table>
          <THead>
            <Tr>
              <ThFixed>No</ThFixed>
              <ThFixed>informasi Purchase Order</ThFixed>
              <Th>Vendor</Th>
              <Th>Item Barang</Th>
              <ThFixed>Qty Purchase Order</ThFixed>
              <ThFixed>Qty Menunggu Persetujuan</ThFixed>
              <ThFixed>Qty Diterima</ThFixed>
              <ThFixed>Qty Sisa</ThFixed>
              <ThFixed>Aksi</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => {
              const qty =
                parseFloat(val.qty_menunggu ?? 0) +
                parseFloat(val.qty_diterima ?? 0);
              const qty_sisa = String(
                parseFloat(val.qty_order ?? 0) - parseFloat(qty)
              );

              return (
                <Tr key={val.id_purchase_order_detail}>
                  <TdFixed>
                    {PageNumber(dataFilter.page, dataFilter.per_page, index)}
                  </TdFixed>
                  <Td>
                    <div>
                      {DateConvert(new Date(val.tgl_purchase_order)).defaultDMY}
                    </div>
                    <div>{val.no_purchase_order}</div>
                  </Td>
                  <Td>{val.nama_vendor}</Td>
                  <Td>
                    {/* {val.dimensi === "3"
                      ? `${val.nama_item} (${val.pj} X ${val.lb} X ${val.tb})`
                      : val.dimensi === "0"
                        ? val.nama_item
                        : val.dimensi === "1"
                          ? `${val.nama_item} (${val.pj})`
                          : val.dimensi === "2"
                            ? `${val.nama_item} (${val.pj} X ${val.lb})`
                            : "-"} */}
                    {val.nama_item}
                  </Td>
                  <TdFixed style={{ width: "50px" }}>
                    <div className="text-right text-nowrap">
                      {val.qty_order
                        ? `${decimalConvert(parseFloat(val.qty_order))} ${
                            val.kode_satuan_beli ?? ""
                          }`
                        : "0,00"}
                    </div>
                  </TdFixed>
                  <TdFixed style={{ width: "50px" }}>
                    <div className="text-right text-nowrap">
                      {val.qty_menunggu
                        ? `${decimalConvert(parseFloat(val.qty_menunggu))} ${
                            val.kode_satuan_beli ?? ""
                          }`
                        : "0,00"}
                    </div>
                  </TdFixed>
                  <TdFixed style={{ width: "50px" }}>
                    <div className="text-right text-nowrap">
                      {val.qty_diterima
                        ? `${decimalConvert(parseFloat(val.qty_diterima))} ${
                            val.kode_satuan_beli ?? ""
                          }`
                        : "0,00"}
                    </div>
                  </TdFixed>
                  <TdFixed style={{ width: "50px" }}>
                    <div className="text-right text-nowrap">
                      {val.qty_sisa
                        ? `${decimalConvert(parseFloat(val.qty_sisa))} ${
                            val.kode_satuan_beli ?? ""
                          }`
                        : "0,00"}
                    </div>
                  </TdFixed>
                  <TdFixed>
                    <div className="d-flex justify-content-center">
                      <ReadButton
                        tooltipText="Detail Purchase Order"
                        onClick={() =>
                          setModalConfig({ show: true, data: val })
                        }
                      />
                      <ActionButton
                        tooltip
                        tooltipText="Buat Penerimaan Barang"
                        text={<IoAddOutline />}
                        variant="success"
                        size="sm"
                        className="my-1"
                        onClick={() => {
                          history.push(
                            `/inventory/transaksi/penerimaan-barang/tambah/${val.id_purchase_order_detail}`,
                            {
                              filter: { ...filter, po: dataFilter },
                              qty_sisa: qty_sisa,
                            }
                          );
                        }}
                      />
                    </div>
                  </TdFixed>
                </Tr>
              );
            })}
          </TBody>
        </CRUDLayout.Table>
        <Pagination
          dataLength={dataFilter.per_page}
          dataPage={
            totalData <= 10
              ? data.length
              : data.map((_res, index) => {
                  if (index === data.length - 1) {
                    return PageNumber(
                      dataFilter.page,
                      dataFilter.per_page,
                      index
                    );
                  } else {
                    return null;
                  }
                })
          }
          dataNumber={data.map((_res, index) => {
            if (index === 0) {
              return PageNumber(dataFilter.page, dataFilter.per_page, index);
            } else {
              return null;
            }
          })}
          dataCount={totalData}
          onDataLengthChange={(e) => {
            setDataFilter({
              ...dataFilter,
              per_page: e.target.value,
              page: 1,
            });
          }}
          currentPage={dataFilter.page}
          totalPage={totalPage}
          onPaginationChange={({ selected }) =>
            setDataFilter({
              ...dataFilter,
              page: selected + 1,
            })
          }
        />
        <ModalPurchaseOrder
          show={modalConfig?.show}
          onHide={() => setModalConfig({ ...modalConfig, show: false })}
          data={modalConfig?.data ?? {}}
        />
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              onChange={(e) => {
                setTimeout(() => {
                  setDataFilter({
                    ...dataFilter,
                    page: 1,
                    q: e.target.value,
                  });
                }, 1000);
              }}
              onSubmit={(e) => e.preventDefault()}
            />

            <FilterButton
              active={dataFilter?.active}
              onClick={() => setModalFilter(true)}
            />
          </div>
        </CRUDLayout.HeadSearchSection>

        <CRUDLayout.HeadButtonSection>
          <BackButton
            onClick={() =>
              history.push(`/inventory/transaksi/penerimaan-barang`, {
                filter: {
                  ...filter,
                  po: dataFilter,
                },
              })
            }
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* ALert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => {
          setShowAlert(false);
          // CLEAR HISTORY LOCATION
          // history.replace("/transaksi/analisa-barang-jadi", { registerAlertConfig: undefined })
        }}
      />

      {/* Table Section */}
      {isLoading === true ? (
        <DataStatus loading={true} text="Memuat data..." />
      ) : data ? (
        data.length > 0 ? (
          <Table />
        ) : (
          <DataStatus text="Tidak ada data" />
        )
      ) : (
        <DataStatus text="Server error" />
      )}

      {modalFilter && (
        <ModalFilter
          show={modalFilter}
          setShow={setModalFilter}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}
    </CRUDLayout>
  );
};

export default ListPurchaseOrder;
