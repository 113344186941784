import { useFormik } from "formik";
import { DatePicker, SelectSearch, FilterModal } from "components";
import { DateConvert } from "utilities";

const ModalFilterPenerimaanBarang = ({
  dropdown,
  modalConfig,
  setModalConfig,
  data,
  setData,
}) => {
  const filter = data?.filter;
  const dataStatusPenerimaan = [
    { label: "Belum Diterima", value: "pending" },
    { label: "Batal Diterima", value: "batal" },
    { label: "Sudah Diterima", value: "terima" },
  ];

  const formInitialValues = {
    tgl_surat_jalan_mulai: filter.tgl_surat_jalan_mulai,
    tgl_surat_jalan_selesai: filter.tgl_surat_jalan_selesai,
    tgl_delivery_order_mulai: filter.tgl_delivery_order_mulai,
    tgl_delivery_order_selesai: filter.tgl_delivery_order_selesai,
    tgl_sales_order_mulai: filter.tgl_sales_order_mulai,
    tgl_sales_order_selesai: filter.tgl_sales_order_selesai,
    id_supir: filter.id_supir,
    id_kendaraan: filter.id_kendaraan,
    status_surat_jalan: filter.status_surat_jalan,
  };

  const formSubmitHandler = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    if (checkActive) {
      setData((prev) => ({
        ...prev,
        filter: { ...values, active: true },
      }));
    } else {
      setData((prev) => ({ ...prev, filter: { ...values, active: false } }));
    }
    setModalConfig(false);
  };

  const formik = useFormik({
    initialValues: formInitialValues,
    onSubmit: formSubmitHandler,
  });

  const { values, setValues, setFieldValue, handleSubmit } = formik;

  const onTanggalPenerimaanChange = (dates) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      tgl_surat_jalan_mulai: startDate,
      tgl_surat_jalan_selesai: endDate,
    });
  };

  const onTanggalPurchaseOrderChange = (dates) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      tgl_delivery_order_mulai: startDate,
      tgl_delivery_order_selesai: endDate,
    });
  };

  const onTanggalSalesOrderChange = (dates) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      tgl_sales_order_mulai: startDate,
      tgl_sales_order_selesai: endDate,
    });
  };

  const onResetButtonClick = () => {
    setData((prev) => ({
      ...prev,
      filter: {
        tgl_surat_jalan_mulai: undefined,
        tgl_surat_jalan_selesai: undefined,
        tgl_delivery_order_mulai: undefined,
        tgl_delivery_order_selesai: undefined,
        tgl_sales_order_mulai: undefined,
        tgl_sales_order_selesai: undefined,
        id_supir: undefined,
        id_kendaraan: undefined,
        status_surat_jalan: undefined,
      },
    }));

    setModalConfig(false);
  };

  return (
    <FilterModal
      show={modalConfig?.show}
      setShow={setModalConfig}
      onResetButtonClick={() => onResetButtonClick()}
      onFilterButtonClick={handleSubmit}
    >
      <DatePicker
        selectsRange
        monthsShown={2}
        label="Tgl. Surat Jalan"
        placeholderText="Pilih tanggal surat jalan"
        dateFormat="dd/MM/yyyy"
        startDate={
          values?.tgl_surat_jalan_mulai
            ? new Date(values.tgl_surat_jalan_mulai)
            : null
        }
        endDate={
          values?.tgl_surat_jalan_selesai
            ? new Date(values.tgl_surat_jalan_selesai)
            : null
        }
        onChange={(selectedDate) => onTanggalPenerimaanChange(selectedDate)}
      />

      <DatePicker
        selectsRange
        monthsShown={2}
        label="Tgl. Delivery Order"
        placeholderText="Pilih tanggal delivery order"
        dateFormat="dd/MM/yyyy"
        startDate={
          values?.tgl_delivery_order_mulai
            ? new Date(values.tgl_delivery_order_mulai)
            : null
        }
        endDate={
          values?.tgl_delivery_order_selesai
            ? new Date(values.tgl_delivery_order_selesai)
            : null
        }
        onChange={(selectedDate) => onTanggalPurchaseOrderChange(selectedDate)}
      />

      <DatePicker
        selectsRange
        monthsShown={2}
        label="Tgl. Sales Order"
        placeholderText="Pilih tanggal sales order"
        dateFormat="dd/MM/yyyy"
        startDate={
          values?.tgl_sales_order_mulai
            ? new Date(values.tgl_sales_order_mulai)
            : null
        }
        endDate={
          values?.tgl_sales_order_selesai
            ? new Date(values.tgl_sales_order_selesai)
            : null
        }
        onChange={(selectedDate) => onTanggalSalesOrderChange(selectedDate)}
      />

      <SelectSearch
        label="Petugas Pengiriman"
        placeholder="Pilih petugas pengiriman"
        defaultValue={dropdown?.supir?.find(
          (val) => val.value === values.id_supir
        )}
        option={dropdown.supir ?? []}
        onChange={(val) => setFieldValue("id_supir", val.value)}
      />
      {console.log(dropdown)}

      <SelectSearch
        label="Armada Pengiriman"
        placeholder="Pilih armada pengiriman"
        defaultValue={dropdown?.kendaraan?.find(
          (val) => val.value === values.id_kendaraan
        )}
        option={dropdown.kendaraan ?? []}
        onChange={(val) => setFieldValue("id_kendaraan", val.value)}
      />
      <SelectSearch
        label="Status Penerimaan"
        placeholder="Pilih status penerimaan"
        defaultValue={dataStatusPenerimaan?.find(
          (val) => val.value === values.status_surat_jalan
        )}
        option={dataStatusPenerimaan ?? []}
        onChange={(val) => setFieldValue("status_surat_jalan", val.value)}
      />
    </FilterModal>
  );
};

export default ModalFilterPenerimaanBarang;
