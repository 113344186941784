import Services from "services";

class LaporanAktivitasHarianApi {
  getPage(params) {
    return Services.get("/laporan_aktivitas_transaksi_harian/page", { params });
  }

  export(params) {
    return Services.get("/laporan_aktivitas_transaksi_harian/export", {
      params,
    });
  }
}

export default new LaporanAktivitasHarianApi();
