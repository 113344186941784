import React, { memo, useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { FiShoppingCart, FiTruck } from "react-icons/fi";
import { useQuery } from "react-query";
import { range, orderBy, sortBy } from "lodash";
import { Formik } from "formik";
import {
  Select,
  Button,
  ChartDoughnut,
  ChartBarVertical,
  ChartBarHorizontal,
  ChartLine,
} from "components2";
import { dateConvert, numberConvert, rupiahConvert } from "utilities2";
import DashboardApi from "./__DashboardApi__";
import { DatePicker } from "components";
import { TabBarangDiminta, TabPersediaanBarang } from "./Comp";

const date = dateConvert();
const rupiah = rupiahConvert();
const yearNow = date.getYear(new Date());
const monthNow = date.getMonth(new Date());
const defaultEndDate = new Date(
  new Date().getFullYear(),
  new Date().getMonth() + 1,
  new Date().getDate()
);

const monthOptions = [
  { label: "Januari", value: "01" },
  { label: "Februari", value: "02" },
  { label: "Maret", value: "03" },
  { label: "April", value: "04" },
  { label: "Mei", value: "05" },
  { label: "Juni", value: "06" },
  { label: "Juli", value: "07" },
  { label: "Agustus", value: "08" },
  { label: "September", value: "09" },
  { label: "Oktober", value: "10" },
  { label: "November", value: "11" },
  { label: "Desember", value: "12" },
];

const yearOptions = range(2000, parseInt(yearNow + 1))
  .map((val) => ({
    label: val,
    value: val,
  }))
  .reverse();

const generateMaxDate = (values) => {
  const date = new Date(values.startMonth);
  date.setMonth(date.getMonth() + 12);
  return date;
};

const convertDate = (startDate, endDate) => {
  const dateFilter = [startDate, endDate];

  const monthName = dateFilter.map((val) => {
    const monthValue = val; // Angka bulan (contoh: 10 untuk Oktober)
    const currentYear = new Date().getFullYear(); // Mendapatkan tahun saat ini
    const dayOfMonth = date.getDay(new Date()); // Contoh hari 1, Anda bisa menggantinya sesuai kebutuhan

    const todayDate = date.getDetailMonth(
      new Date(currentYear, monthValue - 1, dayOfMonth)
    );

    return todayDate;
  });

  return monthName;
};

const InfoItem = memo(({ icon, title, value, loading, date }) => {
  return (
    <div
      className="d-flex justify-content-between align-items-center bg-white rounded border shadow-sm px-4"
      style={{ height: "100%" }}
    >
      <div style={{ fontSize: 70 }}>{icon}</div>
      <div className="d-flex flex-column justify-content-between align-items-end mt-2">
        <b style={{ fontSize: 15 }}>{title}</b>
        <b style={{ fontSize: 12 }}>{date}</b>
        {loading ? (
          <small>Memuat data . . .</small>
        ) : (
          <b style={{ fontSize: 40, fontWeight: 1200, lineHeight: 1.2 }}>
            {value}
          </b>
        )}
      </div>
    </div>
  );
});

const InfoDoughnutChart = memo(
  ({ color, datasets, title, value }) => (
    <div className="position-relative">
      <div
        style={{
          position: "absolute",
          top: 38,
          left: value?.toString()?.length > 2 ? 28 : 32,
          color: color,
        }}
      >
        <b style={{ fontSize: 14 }}>{value}%</b>
      </div>
      <ChartDoughnut
        height={90}
        width={90}
        data={{
          labels: [],
          datasets: datasets,
        }}
      />
      <div className="text-center font-weight-bold" style={{ color: color }}>
        {title}
      </div>
    </div>
  ),
  (prev, curr) => {
    return prev.value === curr.value;
  }
);

const Dashboard = ({ setNavbarTitle }) => {
  const defaultMonthName = convertDate(monthNow, date.getMonth(defaultEndDate));
  const [filter, setFilter] = useState({
    startMonth: monthNow,
    endMonth: date.getMonth(defaultEndDate),
    year: date.getYear(new Date()),
    convertMonth: {
      startDate: defaultMonthName[0],
      endDate: defaultMonthName[1],
    },
  });

  const dateSelected = `${filter.year}-${filter.month}-01`;

  const getPR = useQuery(
    ["dashboard", "pr", filter.startMonth, filter.endMonth, filter.year],
    () =>
      DashboardApi.getPR({
        tahun: filter.year,
        bulan_mulai: filter.startMonth,
        bulan_selesai: filter.endMonth,
      })
  );
  const getSJ = useQuery(
    ["dashboard", "sj", filter.startMonth, filter.endMonth, filter.year],
    () =>
      DashboardApi.getSJ({
        tahun: filter.year,
        bulan_mulai: filter.startMonth,
        bulan_selesai: filter.endMonth,
      })
  );
  const getKeperluan = useQuery(
    ["dashboard", "keperluan", filter.startMonth, filter.endMonth, filter.year],
    () =>
      DashboardApi.getKeperluan({
        tahun: filter.year,
        bulan_mulai: filter.startMonth,
        bulan_selesai: filter.endMonth,
      })
  );

  const getNilaiPersediaan = useQuery(
    ["dashboard", "nilai-persediaan", filter.year],
    () => DashboardApi.getNilaiPersediaan({ tahun_mutasi: filter.year })
  );

  useEffect(() => {
    setNavbarTitle("Dashboard");
  }, []);

  return (
    <>
      {/* Filter Section */}
      <Formik
        initialValues={{
          startMonth: new Date(),
          endMonth: defaultEndDate,
          year: filter.year,
        }}
        onSubmit={(val) => {
          const startMonth = date.getMonth(new Date(val.startMonth));
          const endMonth = date.getMonth(new Date(val.endMonth));
          const monthName = convertDate(startMonth, endMonth);

          setFilter({
            startMonth,
            endMonth,
            year: val.year,
            convertMonth: {
              startDate: monthName[0],
              endDate: monthName[1],
            },
          });
        }}
      >
        {({ values, setValues, handleSubmit }) => (
          <div className="d-flex justify-content-center justify-content-lg-end">
            <div className="d-flex flex-column flex-md-row">
              {/* Bulan */}
              <div className="d-flex align-items-center mr-2">
                <div style={{ width: 150 }}>
                  <DatePicker
                    selectsRange
                    dateFormat="MMMM"
                    showMonthYearPicker
                    selected={values.startMonth}
                    startDate={values.startMonth}
                    maxDate={values.startMonth ? generateMaxDate(values) : null}
                    endDate={values.endMonth}
                    onChange={([startMonth, endMonth]) =>
                      setValues({
                        ...values,
                        startMonth: startMonth,
                        endMonth: endMonth,
                      })
                    }
                  />
                </div>
              </div>

              {/* Tahun */}
              <div className="d-flex align-items-center mr-2 mb-2">
                <span className="mr-2">Tahun</span>
                <div style={{ width: 150 }}>
                  <Select
                    noMargin
                    options={yearOptions}
                    defaultValue={yearOptions.find(
                      (val) => val.value === values.year
                    )}
                    onChange={(val) =>
                      setValues((prev) => ({ ...prev, year: val.value }))
                    }
                  />
                </div>
              </div>

              {/* Button */}
              <div className="d-flex align-items-center mr-2 mb-2">
                <Button
                  disabled={!values.startMonth || !values.endMonth}
                  text="Filter"
                  className="px-4"
                  onClick={handleSubmit}
                />
              </div>
            </div>
          </div>
        )}
      </Formik>

      {/* Info Section */}
      <Row className="mt-3">
        {/* Info Purchase request */}
        <Col md={6} lg={6} xl={3} className="mb-3">
          <InfoItem
            loading={getPR.isFetching}
            title="Purchase Request"
            date={`${filter?.convertMonth?.startDate} - ${filter?.convertMonth?.endDate} ${filter.year}`}
            icon={<FiShoppingCart />}
            value={getPR?.data?.data?.total_purchase_request ?? "-"}
          />
        </Col>

        {/* Info Surat jalan */}
        <Col md={6} lg={6} xl={3} className="mb-3">
          <InfoItem
            loading={getSJ.isFetching}
            title="Surat Jalan"
            date={`${filter?.convertMonth?.startDate} - ${filter?.convertMonth?.endDate} ${filter.year}`}
            icon={<FiTruck />}
            value={getSJ.data?.data?.total_surat_jalan ?? "-"}
          />
        </Col>

        {/* Presentase PR */}
        <Col className="mb-3">
          <div
            className="d-flex justify-content-between bg-white rounded border shadow-sm px-4 py-2"
            style={{ height: "100%" }}
          >
            <div className="d-flex align-items-center">
              <b style={{ fontSize: 18, lineHeight: 1.4 }}>
                Presentase PR
                <br />
                Berdasarkan Keperluan
                <br />
                {`${filter?.convertMonth?.startDate} - ${filter?.convertMonth?.endDate} ${filter.year}`}
              </b>
            </div>
            {getKeperluan.isFetching ? (
              <div className="d-flex justify-content-end align-items-center">
                Memuat data . . .
              </div>
            ) : (
              <>
                <div className="d-flex">
                  <InfoDoughnutChart
                    title="Stok"
                    color="#0073b9"
                    datasets={[
                      {
                        data: [
                          parseInt(
                            getKeperluan?.data?.data?.persentase_produksi ?? 0
                          ),
                          100 -
                            parseInt(
                              getKeperluan?.data?.data?.persentase_produksi ?? 0
                            ),
                        ],
                        backgroundColor: ["#0073b9", "#00abda"],
                      },
                    ]}
                    value={parseInt(
                      getKeperluan?.data?.data?.persentase_produksi ?? "-"
                    )}
                  />
                  <InfoDoughnutChart
                    title="Produksi"
                    color="#ed6914"
                    datasets={[
                      {
                        data: [
                          parseInt(
                            getKeperluan?.data?.data?.persentase_stok ?? 0
                          ),
                          100 -
                            parseInt(
                              getKeperluan?.data?.data?.persentase_stok ?? 0
                            ),
                        ],
                        backgroundColor: ["#ed6914", "#f59e0b"],
                      },
                    ]}
                    value={parseInt(
                      getKeperluan?.data?.data?.persentase_stok ?? 0
                    )}
                  />
                </div>
              </>
            )}
          </div>
        </Col>
      </Row>

      {/* Chart Section */}
      <Row>
        <Col md={6} className="my-3">
          <div
            className="p-3 bg-white border rounded shadow-sm"
            style={{ height: "" }}
          >
            <div className="text-center my-2">
              <b>Nilai Persediaan Barang Per Bulan Tahun {filter.year}</b>
            </div>
            {getNilaiPersediaan.isFetching ? (
              <div className="d-flex align-items-center justify-content-center my-5">
                Memuat data . . .
              </div>
            ) : (
              <>
                <ChartLine
                  height={200}
                  showLegend={false}
                  data={{
                    labels: monthOptions.map((val) => val.label),
                    datasets: [
                      {
                        data: monthOptions.map((val) => {
                          const findMonth =
                            getNilaiPersediaan?.data?.data?.find(
                              (el) =>
                                el?.bulan_mutasi?.toString() ===
                                val.value.toString()
                            );

                          return findMonth?.total
                            ? parseFloat(findMonth.total)
                            : 0;
                        }),
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      tooltip: {
                        callbacks: {
                          label: (val) => rupiah.getWithComa(val.raw),
                        },
                      },
                    },
                    scales: {
                      y: {
                        ticks: {
                          callback: (val) => rupiah.getWithComa(val),
                        },
                      },
                    },
                  }}
                />
              </>
            )}
          </div>
        </Col>
        <Col md={6} className="my-3">
          <TabPersediaanBarang
            monthOptions={monthOptions}
            year={filter.year}
            startMonth={filter.startMonth}
            endMonth={filter.endMonth}
          />
        </Col>
        <Col md={6} className="my-3">
          <TabBarangDiminta
            monthOptions={monthOptions}
            year={filter.year}
            startMonth={filter.startMonth}
            endMonth={filter.endMonth}
          />
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
