// React
import { useRef, useMemo, useState, useCallback } from 'react'

// Components
import { Container, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { ActionButton, DeleteModal } from 'components'

// Icons
import { IoDownloadOutline, IoImageOutline, IoTrashOutline } from 'react-icons/io5'

// Files
import ImageViever from 'react-simple-image-viewer'
import FileSaver from 'file-saver'

// Form
import { useFormikContext } from 'formik'

// API
import { RegItemBahanApi } from 'api'

const TabGambar = ({ readOnly }) => {
  // Variables
  const types = ['jpg', 'png', 'gif', 'jpeg']
  const base_url = process.env.REACT_APP_FILE_BASE_URL

  // Hooks
  const inputFileRef = useRef()
  const formik = useFormikContext()

  // States
  const [modalDeleteConfig, setModalDeleteConfig] = useState({
    index: '',
    show: false,
    loading: false
  })
  const [viewImageConfig, setViewImageConfig] = useState({
    show: false,
    index: ''
  })
  const [submit, setSubmit] = useState(false)

  // Filter data gambar agar array hanya terisi link gambar
  const filterImageHandler = useMemo(() => {
    return formik.values.path_gambar.map(val => `${base_url}${val}`)

    // eslint-disable-next-line
  }, [formik])

  // Menampilkan ImageViewer
  const setIndexImageHandler = useCallback((index) => {
    setViewImageConfig({
      show: true,
      index: index
    })

    // eslint-disable-next-line
  }, [viewImageConfig])

  // Trigger input file agar terclick
  const onClickFilesHandler = () => !readOnly && inputFileRef.current.click()

  // Menangani saat file dipilih
  const onChangeFileHandler = (e) => {
    const file = e.target.files[0]

    // Check apakah ada file yang dipilih
    if (!file) return null

    // Ambil file type lalu split dengan '/' dan ambil index ke 1 (cth: image/png menjadi png)
    const getFileType = file.type.split('/')[1]

    // Check jika type file sudah benar
    if (types.includes(getFileType)) {
      setSubmit(true)

      const formData = new FormData()
      formData.append(`file_0`, file)

      RegItemBahanApi.upload(formData).then(res => {
        const path_gambar = formik.values.path_gambar
        path_gambar.push(res.data.data?.file_0)
  
        formik.setFieldValue('path_gambar', path_gambar)
      }).finally(() => setSubmit(false))
    } else {
      window.alert('File tidak valid! harap masukan gambar (.jpg, .png, .gif, .jpeg)')
    }
  }

  // Menangani hapus file
  const onDeleteHandler = useCallback(() => {
    setModalDeleteConfig({ ...modalDeleteConfig, loading: true })

    const path_gambar = formik.values.path_gambar
    path_gambar.splice(modalDeleteConfig.index, 1)

    formik.setFieldValue('path_gambar', path_gambar)
    setModalDeleteConfig({
      index: '',
      show: false,
      loading: false
    })

    // eslint-disable-next-line
  }, [modalDeleteConfig])

  // Menampilkan komponen foto
  const Image = ({ imageText, imageUrl, onView, onDownload, onDelete }) => {
    const [isHover, setIsHover] = useState(false)

    return (
      <OverlayTrigger
        placement="bottom"
        flip={true}
        overlay={<Tooltip style={{ zIndex: 1 }}>{imageText ?? 'Foto Barang Jadi'}</Tooltip>}
      >
        <Col
          sm={12}
          md={6}
          lg={4}
          xl={3}
          className="my-3"
          style={{ cursor: 'zoom-in', objectFit: 'cover' }}
          onClick={onView}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          {/* Download Button */}
          <ActionButton
            size="sm"
            variant="primary"
            style={{ position: 'absolute', zIndex: 1, right: 20 }}
            className="m-2 shadow"
            onClick={onDownload}
          >
            <IoDownloadOutline />
          </ActionButton>

          {/* Delete Button */}
          {!readOnly &&
            <ActionButton
              size="sm"
              variant="danger"
              style={{ position: 'absolute', zIndex: 1, top: 35, right: 20 }}
              className="m-2 shadow"
              onClick={onDelete}
            >
              <IoTrashOutline />
            </ActionButton>
          }
          <img
            className={`img-fluid rounded border ${isHover ? 'shadow' : 'shadow-sm'}`}
            style={{ height: 200, width: '100%', objectFit: 'cover', transform: isHover ? 'scale(1.03)' : 'none', transition: 'all 0.1s ease-in-out' }}
            src={imageUrl}
            alt="gambar"
          />
        </Col>
      </OverlayTrigger>
    )
  }

  return (
    <div>
      {/* Button Section */}
      <div className="mb-3 text-right">
        <input
          ref={inputFileRef}
          type="file"
          accept="image/png, image/gif, image/jpeg, image/jpg"
          style={{ display: 'none' }}
          onChange={onChangeFileHandler}
        />
        {!readOnly &&
          <ActionButton
            text="Tambah Gambar"
            onClick={onClickFilesHandler}
            loading={submit}
          />
        }
      </div>

      {/* Image Section */}
      <Container
        fluid
        className="bg-light rounded overflow-auto border"
        onClick={onClickFilesHandler}
        style={{ cursor: 'pointer', maxHeight: '60vh' }}
      >
        {formik.values.path_gambar?.filter(val => {
          const file = val.split('.')

          return types.includes(file[file.length - 1])
        }).length > 0 ? (
          <Row>
            {formik.values.path_gambar?.map((val, index) => {
              const file = val.split('/')
              const type = val.split('.')
              const nama = file[file.length - 1]
              const link = `${base_url}${val}`

              if (types.includes(type[type.length - 1])) {
                return (
                  <Image
                    key={index}
                    imageText={nama}
                    imageUrl={link}
                    onView={e => {
                      e.stopPropagation()
                      setIndexImageHandler(index)
                    }}
                    onDownload={e => {
                      e.stopPropagation()
                      FileSaver.saveAs(link, nama)
                    }}
                    onDelete={e => {
                      e.stopPropagation()
                      setModalDeleteConfig({
                        show: true,
                        loading: false,
                        index: index
                      })
                    }}
                  />
                )
              }

              return null
            })}
          </Row>
        ) : (
          <div className="d-flex flex-column justify-content-center align-items-center py-5">
            <IoImageOutline size={60} />
            <b className="mt-2">Tidak ada gambar</b>
          </div>
        )}
      </Container>

      {/* Modal */}
      <DeleteModal
        show={modalDeleteConfig.show}
        title="Gambar"
        loading={modalDeleteConfig.loading}
        onConfirm={onDeleteHandler}
        onHide={() => setModalDeleteConfig({
          show: false,
          index: ''
        })}
      />

      {/*Image Viewer  */}
      {viewImageConfig.show &&
        <div style={{ zIndex: 1000 }}>
          <ImageViever
            closeOnClickOutside
            disableScroll
            src={filterImageHandler}
            currentIndex={viewImageConfig.index}
            onClose={() => setViewImageConfig({ show: false, index: 0 })}
            backgroundStyle={{ zIndex: 1000 }}
          />
        </div>
      }
    </div>
  )
}

export default TabGambar
