import Services from "services";

class DashboardApi {
  async getPR(params) {
    const data = await Services.get("/dashboard/purchase_request", { params });
    return data.data;
  }

  async getSJ(params) {
    const data = await Services.get("/dashboard/surat_jalan", { params });
    return data.data;
  }

  async getKeperluan(params) {
    const data = await Services.get("/dashboard/keperluan", { params });
    return data.data;
  }

  async getNilaiKelompok(params) {
    const data = await Services.get("/dashboard/summary", { params });
    return data.data;
  }

  async getBarangSeringDiminta(params) {
    const data = await Services.get("/dashboard/barang", { params });
    return data.data;
  }

  async getNilaiPersediaan(params) {
    const data = await Services.get("/dashboard/nilai_persediaan", { params });
    return data.data;
  }
}

export default new DashboardApi();
