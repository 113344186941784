import { useState, useEffect } from "react";
import { Row, Dropdown, Spinner } from "react-bootstrap";
import { debounce } from "lodash";
import Axios from "axios";
import FileSaver from "file-saver";
import {
  CRUDLayout,
  Alert,
  DataStatus,
  FilterButton,
  Pagination,
  InputSearch,
  Table,
  Th,
  Td,
  ThFixed,
} from "components";
import { PageNumber, DateConvert, DecimalConvert } from "utilities";
import { LaporanAktivitasHarianApi } from "api";
import { ModalFilter } from "./components";

const LaporanAktivitasHarian = ({ setNavbarTitle }) => {
  const title = "Laporan Aktivitas Harian Inventori";
  const [isFetchingReport, setIsFetchingReport] = useState(false);
  const [data, setData] = useState([]);
  const [loadingExport, setLoadingExport] = useState(false);

  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: "detail",
    id: "",
  });
  const date = new Date();

  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: false,
      status_approval: "APP",
      tgl_transaksi_mulai: DateConvert(
        new Date(date.getFullYear(), date.getMonth(), 1)
      ).default,
      tgl_transaksi_selesai: DateConvert(
        new Date(date.getFullYear(), date.getMonth() + 1, 0)
      ).default,
      tgl_created_at: undefined,
    },
    pagination: {
      page: "1",
      dataLength: "10",
      totalPage: "1",
      dataCount: "0",
    },
    search: { key: "" },
  });

  // FETCH DATA REPORT SESUAI FILTER
  const getReportData = () => {
    setIsFetchingReport(true);

    LaporanAktivitasHarianApi.getPage({
      q: dataFilter?.search?.key,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      tgl_transaksi_mulai: dataFilter?.filter?.tgl_transaksi_mulai,
      tgl_transaksi_selesai: dataFilter?.filter?.tgl_transaksi_selesai,
      tgl_created_at: dataFilter?.filter?.tgl_created_at,
    })
      .then(({ data }) => {
        setData(data?.data ?? []);
        setDataFilter((prev) => ({
          ...prev,
          pagination: {
            ...prev.pagination,
            dataCount: data?.data_count,
            totalPage: data?.total_page,
          },
        }));
      })
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        })
      )
      .finally(() => setIsFetchingReport(false));
  };

  const exportData = (tipe) => {
    setLoadingExport(true);

    LaporanAktivitasHarianApi.export({
      tipe,
      ...dataFilter.filter,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      active: undefined,
    })
      .then((res) => {
        const data = res.data.data;
        const rawURL = data.split(":")[1];
        const urlFile =
          window.location.protocol === "http"
            ? `http:${rawURL}`
            : `https:${rawURL}`;

        FileSaver.saveAs(urlFile, data);
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Export Data gagal",
        });
      })
      .finally(() => setLoadingExport(false));
  };

  useEffect(() => {
    setNavbarTitle(title);

    return () => {
      setData([]);
    };
  }, [setNavbarTitle]);

  // fetch report on dataFilter change
  useEffect(() => {
    // if (dataFilter.filter.active) getReportData()
    getReportData();
  }, [
    dataFilter?.search?.key,
    dataFilter?.filter?.active,
    dataFilter?.filter?.tgl_transaksi_mulai,
    dataFilter?.filter?.tgl_transaksi_selesai,
    dataFilter?.filter?.tgl_created_at,
    dataFilter?.pagination?.page,
    dataFilter?.pagination?.dataLength,
  ]);

  const PageContent = () => {
    return (
      <>
        <div className="font-weight-bold mb-2">
          List Data Aktivitas Harian Inventori
        </div>
        {/* <TablePenerimaanBarang setModalConfig={setModalConfig} data={[{}]} /> */}
        <Table>
          <thead>
            <tr>
              <ThFixed>No.</ThFixed>
              <Th>Nama Transaksi</Th>
              <ThFixed>Informasi Transaksi</ThFixed>
              <Th>Item</Th>
              <Th>Qty</Th>
              <Th>Pengaju</Th>
              <Th>Jabatan</Th>
              <Th>Unit Produksi</Th>
              <ThFixed>Dibuat Pada Tanggal</ThFixed>
            </tr>
          </thead>
          <tbody>
            {data?.length > 0 ? (
              data?.map((val, index) => (
                <tr key={index}>
                  <Td className="align-middle text-nowrap text-center">
                    {PageNumber(
                      dataFilter?.pagination?.page,
                      dataFilter?.pagination?.dataLength,
                      index
                    )}
                  </Td>
                  <Td>{val?.nama_transaksi ?? "-"}</Td>
                  <Td>
                    <div>
                      {val.tgl_transaksi
                        ? DateConvert(new Date(val.tgl_transaksi)).defaultDMY
                        : "-"}
                    </div>
                    <div>{val.no_transaksi ?? "-"}</div>
                  </Td>
                  <Td>{val?.item ?? "-"}</Td>
                  <Td>
                    {DecimalConvert(parseFloat(val?.qty ?? 0)).getWithComa}{" "}
                    {val?.satuan ?? "-"}
                  </Td>
                  <Td>{val?.pengaju ?? "-"}</Td>
                  <Td>{val?.jabatan ?? "-"}</Td>
                  <Td>{val?.unit_produksi ?? "-"}</Td>
                  <Td className="text-center">
                    {new Date(val?.created_at).toLocaleString("en-GB", {
                      hour12: false,
                    }) ?? "-"}
                  </Td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={10}
                  className="align-middle text-nowrap text-center"
                >
                  Tidak ada data
                </td>
              </tr>
            )}
          </tbody>
        </Table>

        {/* Pagination */}
        {data?.length > 0 && (
          <Pagination
            dataLength={dataFilter?.pagination?.dataLength}
            dataNumber={
              dataFilter?.pagination?.page *
                dataFilter?.pagination?.dataLength -
              dataFilter?.pagination?.dataLength +
              1
            }
            dataPage={
              dataFilter?.pagination?.dataCount <
              dataFilter?.pagination?.dataLength
                ? dataFilter?.pagination?.dataCount
                : dataFilter?.pagination?.page *
                  dataFilter?.pagination?.dataLength
            }
            dataCount={dataFilter?.pagination?.dataCount}
            currentPage={dataFilter?.pagination?.page}
            totalPage={dataFilter?.pagination?.totalPage}
            onPaginationChange={({ selected }) =>
              setDataFilter((prev) => ({
                ...prev,
                pagination: { ...prev.pagination, page: selected + 1 },
              }))
            }
            onDataLengthChange={(e) =>
              setDataFilter((prev) => ({
                ...prev,
                pagination: {
                  ...prev.pagination,
                  page: 1,
                  dataLength: e.target.value,
                },
              }))
            }
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      <Row className="d-flex ml-1 mb-3">
        <div className="mr-2 mb-1">
          <InputSearch
            placeholder="Cari barang jadi"
            onChange={debounce(
              (e) =>
                setDataFilter((prev) => ({
                  ...prev,
                  pagination: {
                    ...prev.pagination,
                    page: 1,
                  },
                  search: { key: e.target.value },
                })),
              1500
            )}
            onSubmit={(e) => e.preventDefault()}
          />
        </div>

        <FilterButton
          active={dataFilter?.filter?.active}
          onClick={() => setModalConfig({ show: true, type: "filter" })}
          className="mr-2"
        />

        {/* Export Button */}
        <Dropdown>
          <Dropdown.Toggle
            disabled={loadingExport}
            className="ml-2 mb-2 px-3 btn btn-warning text-white"
          >
            {loadingExport ? (
              <Spinner animation="border" variant="white" size="sm" />
            ) : (
              "Export Data"
            )}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => exportData("excel")}>
              Excel (.xlsx)
            </Dropdown.Item>
            <Dropdown.Item onClick={() => exportData("pdf")}>
              PDF (.pdf)
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Row>

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => setAlertConfig({ ...alertConfig, show: false })}
      />

      {/* content */}
      {isFetchingReport ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <div style={{ minHeight: "45vh" }}>
          <PageContent />
        </div>
      )}

      {/* modal filter */}
      {modalConfig.show && modalConfig.type === "filter" && (
        <ModalFilter
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}
    </CRUDLayout>
  );
};

export default LaporanAktivitasHarian;
