import Services from "../../../services";

class SatuanApi {
  getPage(page, dataLength, key) {
    return Services.get(`/satuan/page/?page=${page}&per_page=${dataLength}&q=${key}`);
  }
  create(data) {
    return Services.post("/satuan", data);
  }
  update(data) {
    return Services.put("/satuan", data);
  }
  delete(id_satuan) {
    return Services.post("/satuan/delete", id_satuan);
  }
  search(key) {
    return Services.get(`satuan/page/?q=${key}`);
  }
  show(id_satuan) {
    return Services.put("/satuan/show", id_satuan);
  }
  hide(id_satuan) {
    return Services.put("/satuan/hide", id_satuan);
  }
}

export default new SatuanApi();
